import React, { Component } from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import ReportIcon from '@mui/icons-material/Report';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DatePicker from '@mui/lab/DatePicker';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import AdapterDateFns from '@date-io/date-fns';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import {fr} from 'date-fns/locale';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import SaveIcon from '@mui/icons-material/Save';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import InputBase from '@mui/material/InputBase';
import CheckIcon from '@mui/icons-material/Check';

import { yellow, red , green } from '@mui/material/colors';

import { listeUtilisateurs } from './index.js';
import { appelAction, DiagError } from './utils.js';

import "./PDC_Cert.css";
import "./utils.css";	
import './style_datepicker.css';


import Image1 from './Image1.png';
import Image2 from './Image2.png';
import Image3 from './Image3.png';


export const IconUp = (props) =>
	{
		return (
			<SvgIcon {...props} viewBox="10 8 8 8">
				<path d="m 7 14 l 5 -5 l 5 5 Z"  />
				</SvgIcon>
		);
	}
	
export const IconDown = (props) =>
	{
		return (
			<SvgIcon {...props} viewBox="10 10 8 8">
			<path d="m 7 10 l 5 5 l 5 -5 Z" />
			</SvgIcon>
		);
	}

class Row extends Component
{
	
	ProfilsWavestone = ["","CP","X","E","FRJ","FR","FRS"];
	
	updateData(data)
	{
		var tab = [];
		if (this.props.row.lignesStaffing)
			tab = this.props.row.lignesStaffing.slice();
		

		var date = new Date();
		
		this.aSupprimer = 0;
		this.setState({idLigne: this.props.row.id, visible:this.state.visible||false, validCom: false,
						codeMission: this.props.row.propale.id!==-1?this.props.row.propale.mission:this.props.row.mission.nomM, 
						desc: this.props.row.commentaires,
						nomClient: this.props.row.mission?this.props.row.mission.nomC:"",
						mission: this.props.row.mission, lignesStaffing : tab, changementsFaits: false, idCertiens:[], roles:[],
						selectionDatePopUp: false, dateMoisFin:(date.getMonth()+1), dateAnneeFin: date.getFullYear(),
						});
	}
	
	constructor(props)
	{
		super(props);
			
		this.ListeStaffable = listeUtilisateurs.filter(u => (u.profilBT&2)>0);
				
		this.state = {};
		this.aSupprimer = 0;
		this.bindedClickAjouterLigneStaffing = this.handleClickAjouterLigneStaffing.bind(this);
		this.bindedClickStaffer = this.handleClickStaffer.bind(this);
		this.bindedTextFieldRoleChange = this.handleTextFieldRoleChange.bind(this);
		this.bindedValiderDate = this.handleValiderDate.bind(this);
		this.bindedFermerStafferPopUp = this.handleFermerStafferPopUp.bind(this);
		this.bindedChangeDateFin = this.handleChangeDateFin.bind(this);
		this.bindDeletePDC = this.handleDeletePDC.bind(this);
		this.bindedComChange = this.handleComChange.bind(this);
		this.bindedComClick = this.handleComClick.bind(this);
	}
	
	componentDidMount()
	{
		this.updateData();
	}
	
	componentDidUpdate(prevProps) 
	{
		
		var doitRaffraichir = false;
		var props = this.props.row;
		Object.keys(this.props.row).forEach(
			function (key)
			{
				if (!doitRaffraichir && props[key] !== prevProps.row[key])
				{
					doitRaffraichir = true;
				}
			});	
		if (doitRaffraichir)
		{
			this.updateData();
		}
	}
	
	handleClickAjouterLigneStaffing()
	{
		this.props.leStartStopDeRefresh(false);
		var tab = this.state.lignesStaffing.slice();
		tab.push({id:-1, role:"", nouvelleLigne:true});
		this.setState({lignesStaffing: tab});
	}
	
	handleClickStaffer()
	{
				
		if (this.state.mission)
		{
			// mission existante, on a fait "+", on va rajouter les nouveaux auditeurs
			var urlPDC = "ctrl=ControleurPDCCert&methode=staffer";
			var requestPDC = {method: "POST", body:""};
			requestPDC.body="idPDC="+this.state.idLigne;
		
			var idC = "";
			var roles = "";
			for (var i=0; this.state.lignesStaffing&&i<this.state.lignesStaffing.length; i++)
			{
				if (! this.state.lignesStaffing[i].nouvelleLigne)
					continue;
				idC += this.state.lignesStaffing[i].id+"_";
				roles += this.state.lignesStaffing[i].role+"_";	
			}
			
			requestPDC.body+="&idC="+idC;
			requestPDC.body+="&roles="+roles;
			requestPDC.body+="&finMois="+this.state.dateMoisFin;
			requestPDC.body+="&finAnnee="+this.state.dateAnneeFin;
			
			this.setState({selectionDatePopUp: false, changementsFaits:false });	
			
			appelAction(urlPDC, requestPDC, this.props.callback);	
		}
		else
		{
			this.setState({selectionDatePopUp: true});	
		}
	}
	
	
	handleFermerStafferPopUp()
	{
		this.setState({selectionDatePopUp: false});
	}
	
	handleChangeDateFin(e)
	{
	  var fin = new Date(e);
	  this.setState({dateMoisFin: (fin.getMonth()+1), dateAnneeFin: fin.getFullYear()})
	}
	
	handleValiderDate()
	{
		var urlPDC = "ctrl=ControleurPDCCert&methode=creer";
		var requestPDC = {method: "POST", body:""};
		requestPDC.body="idPDC="+this.state.idLigne;
		
		var idC = "";
		var roles = "";
		for (var i=0; this.state.lignesStaffing&&i<this.state.lignesStaffing.length; i++)
		{
			idC += this.state.lignesStaffing[i].id+"_";
			roles += this.state.lignesStaffing[i].role+"_";	
		}
		
		requestPDC.body+="&idC="+idC;
		requestPDC.body+="&roles="+roles;
		requestPDC.body+="&finMois="+this.state.dateMoisFin;
		requestPDC.body+="&finAnnee="+this.state.dateAnneeFin;
		
		this.setState({selectionDatePopUp: false, changementsFaits:false });	
		
		appelAction(urlPDC, requestPDC, this.props.callback);	

	}
	
	handleTextFieldRoleChange(e,v)
	{
		var ee;
		if (e.target.id)
			ee = e.target.id;
		else // si bouton "clear"
		{
			ee = e.target.parentElement.parentElement.parentElement.parentElement.firstChild;
			ee = (ee.id)?ee.id:ee.firstChild.id;
		}		
		var indice = parseInt(ee.substring(ee.indexOf("_")+1, (ee.indexOf("-")>0)?ee.indexOf("-"):100 ));
		
		var lignes = this.state.lignesStaffing.slice();
		if (ee.indexOf("Role")>=0)
			lignes[indice].role=v?v:"";
		else
			lignes[indice].id=v?v.id:"";
		
		if (! lignes[indice].nouvelleLigne)
		{
			alert("en cours !!");
		}
		else
		{
			this.setState({lignesStaffing : lignes, changementsFaits: true});
		}
	}
	
	handleValideSuppression = () =>
	{
		this.aSupprimer=2;
		this.setState({});
		var urlRequestPDC = "ctrl=ControleurPDCCert&methode=supprimer";
		var requestPDC = {method: "POST", body:""};
		requestPDC.body="idPDC="+this.state.idLigne;
		appelAction(urlRequestPDC, requestPDC, this.props.callback);		
	}
	handleAnnuleSuppression = () =>
	{
		this.aSupprimer=0;
		this.props.leStartStopDeRefresh(true);
		this.setState({});
	}
	handleDeletePDC()
	{
		this.props.leStartStopDeRefresh(false);
		this.aSupprimer=1;
		this.setState({});
	}
	
	handleComChange(e)
	{
		// et on met en pause le raffraichissement sinon ça va tuer le travail en cours !
		
		this.props.leStartStopDeRefresh(false);
		this.setState({desc : e.target.value, validCom:true});
	}
	
	handleClickCallbackCom = (obj) =>
	{		
		if (obj.etat===0)
			this.setState({validCom:false});
		else
			this.props.callBack(obj)
		
		// et on relance le raffraichissement !
		this.props.leStartStopDeRefresh(true);
			
	}
	
	handleComClick(e)
	{
		var urlPDC = "ctrl=ControleurPDCCert&methode=modifier";
		var requestPDC = {method: "POST", body:""};
		requestPDC.body="idPDC="+this.state.idLigne+"&com="+this.state.desc.sansEtCom();
		
		appelAction(urlPDC, requestPDC, this.handleClickCallbackCom);
	}
	
	render ()
	{
		var indiceLigne=-1;
		
		if  (this.state.selectionDatePopUp)
			return <Dialog onClose={this.bindedFermerStafferPopUp}
					open={true}
					aria-describedby="choix du planning" >
					<DialogTitle>Selection de la fin prévisionnelle de la mission :</DialogTitle>
					<DialogContent>
					<DialogContentText>
						Date de fin :
					</DialogContentText>
			  
				<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
				<DesktopDatePicker
					id='dateFin'
					minDate={new Date().setFullYear(new Date().getFullYear()-2)}
					maxDate={new Date().setFullYear(new Date().getFullYear()+5)}
					views={['month','year']}
					label="Fin (MM/YYYY)"
					value = {this.state.dateMoisFin+"/01/"+this.state.dateAnneeFin}
					inputFormat = "MM/yyyy"
					onChange = {this.bindedChangeDateFin}
					renderInput={(params) => <TextField {...params} helperText={null} />}
					/>
				</LocalizationProvider>
			  
			  <DialogContentText>
				Spécificité :
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			  <Button onClick={this.bindedValiderDate}>Go !</Button>
			</DialogActions>
			</Dialog>
		
		if (this.aSupprimer===2)
			return;
		
		return (
            <React.Fragment>
                 
                 <Dialog
                    open={this.aSupprimer===1}
                    onClose={this.handleAnnuleSuppression}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                        <DialogTitle id="alert-dialog-title">
                            {"Validation de la suppression ?"}
                        </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                      La ligne {this.state.codeMission} sera supprimée de la vue PDC (la mission reste présente et elle peut être basculée à nouveau dans le PDC via la vue Missions/Trimestres ?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleAnnuleSuppression}>Non</Button>
                      <Button onClick={this.handleValideSuppression} autoFocus>Oui !</Button>
                    </DialogActions>
                </Dialog>
             
                <Accordion expanded={(this.state.visible||false)} onChange={() => {var a=(this.aSupprimer===0)?this.setState({visible:!this.state.visible}):"";}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <div class={"ss_ligne_typo_PDC_cert"}> 
                            <div className={"ss_ligne_titre_PDC_cert"}>
                            {(this.props.row.mission?this.props.row.mission.typeM:"NEW !" ) + " / " + this.state.nomClient + " " + this.state.codeMission}
                            </div>
                            <div className={"ss_ligne_del_PDC_cert"}> 
                            
                            <IconButton onClick={this.bindDeletePDC} size="large">
                                <RemoveShoppingCartIcon/>
                            </IconButton>
                            </div>
                        
                        </div>
                        </AccordionSummary>
                        <AccordionDetails classes={{ root: 'accordeon' }} >
                        <div className={"ss_ligne_PDC_Cert_accordeon"}>
                        
                        
                        <div className={"ss_ligne_desc_PDC_cert"}>
                         
                            <InputBase key={"text_"+this.state.id}
                              multiline={true}
                              rows={5}
                              className={"ss_ligne_desc_PDC_cert_txt"}
                            //  sx={{ ml: 1, width:'100%' }}
                              placeholder="Desc."
                              onChange={this.bindedComChange}
                              value={this.state.desc?this.state.desc:""}
                          />
                          {(this.state.validCom) && <IconButton
                              sx={{ p: '10px', right:"0", bottom:'0', position:'absolute'}}
                              aria-label="Valider"
                              onClick={this.bindedComClick}
                              size="large">
                            <CheckIcon />
                          </IconButton>}
                        </div>
                        
                        {this.state.lignesStaffing && this.state.lignesStaffing.map(
                        
                            ligneStaffing => {
                                indiceLigne++;
                                return <React.Fragment>
                                        <div className={"PDC_SSLigne_d_Cert"}>#{indiceLigne}</div>
                                       <div>
                                            <Autocomplete 
                                            id={"Role_"+indiceLigne}
                                            autoselect={1}
                                            sx={{width:150}}
                                            className={"PDC_SSLigne_Role_Cert"}
                                            ListboxProps={{ sx: { fontSize: ".8em" } }}
                                            options={this.ProfilsWavestone}
                                            onChange={this.bindedTextFieldRoleChange}
                                            getOptionLabel={ (option) => option }
                                            value={ligneStaffing.role}
                                            renderInput={(params) => <TextField {...params} fullWidth required id={"_role_"+indiceLigne} label="Role" /> }
                                        />
                                        </div>
                                        <div>
                                        <Autocomplete 
                                            id={"Certien_"+indiceLigne}
                                            autoselect={1}
                                            sx={{width:300}}
                                            className={"PDC_SSLigne_Certien"}
                                            ListboxProps={{ sx: { fontSize: ".8em"} }}
                                            options={this.ListeStaffable}
                                            onChange={this.bindedTextFieldRoleChange}
                                            getOptionLabel={ (option) => option.prenom + " " + option.nom + " ("+option.trigramme+")"}
                                            value={this.ListeStaffable.find(u => u.id === ligneStaffing.id) || null}
                                            renderInput={(params) => <TextField {...params} fullWidth required id={"_certiens_"+indiceLigne} label="Certien" /> }
                                        />	
                                       </div>
                                       </React.Fragment>;
                            }
                        )}
                        
                        <div className={"ss_ligne_plus_PDC_cert"}>
                            <IconButton onClick={this.bindedClickAjouterLigneStaffing} size="large">
                                <AddIcon  />
                            </IconButton>
                        </div>
                        
                        <div className={"ss_ligne_staff_PDC_cert"}>
                        <IconButton
                            disabled={!this.state.changementsFaits}
                            onClick={this.bindedClickStaffer}
                            size="large"> 
                            <SaveIcon />
                        </IconButton>
                        </div>
                        
                        
                        
                    </div>
                  </AccordionDetails>
                  </Accordion>
                  </React.Fragment>
        );
	}
}
	
	
	
// le composant principal : un tableau de dispo et un tableau avec des lignes

class PDC_Cert extends Component
{
	
	constructor(props)
	{
		super(props);

		this.IdBoucleData =  -1;
		this.state={loading:true, etat:0, hno:false, mess:"", orderBy:1,  lignesPDC: []};
		
		this.moveDown = this.actionMoveDown.bind(this);
		this.moveUp = this.actionMoveUp.bind(this);
		
		this.typeMission =
		{"CERT_AV":6, "CERT_KM":2, "CERT_FORMATION":4, "CERT_RD":5, "CERT_PROD":8, "CERT_INTERVENTION":10, "CERT_STAGE":1, "CERT_CTI":7
		};
	}
	

	actionMove(newU, oldU)
	{
		var urlChargerPDC = "ctrl=ControleurPDCCert&methode=changer";
		var urlChargerPDCOptions = {method: "POST", body:"new="+newU+"&old="+oldU};
		appelAction(urlChargerPDC, urlChargerPDCOptions, this.callbackEndLoading);	
	}
	
	actionMoveUp (e)
	{

		var u = parseInt(e.currentTarget.dataset.indice);
		this.actionMove(this.state.dispos[u].certien.id,this.state.dispos[u-1].certien.id);
	}	
	actionMoveDown (e)
	{
		var u = parseInt(e.currentTarget.dataset.indice);
		this.actionMove(this.state.dispos[u].certien.id,this.state.dispos[u+1].certien.id);
	}
	
	// qd on a fini de charger le PDC :
	callbackEndLoading = (obj) =>
	{
			
		if (obj.etat === 4) // erreur et on garde le truc ouvert
			this.setState({etat:obj.etat, mess:obj.mess, loading:false});
		else
		{
		// si pas de data, c'est juste un callback vide pour traiter l'erreur ci dessus
			if (obj.data)
			{
				// on enleve le 1er element qui est les semaines
				var tabUser = obj.data.dispos.slice(1);
				
				// on trie les lignes du PDC
				var tabLignes = obj.data.lignesPDC;
				tabLignes.sort((a,b) => 
				{
					if (! a.mission)
						return -1;
					if (! b.mission)
						return 1;
					return this.typeMission[b.mission.typeM] - this.typeMission[a.mission.typeM];
				});
				
			
				this.setState({loading:false, etat:obj.etat, mess:obj.mess, lignesPDC: tabLignes, dispos : obj.data.dispos });
			//	console.log(obj.data.dispos);
			//	console.log(obj.data.lignesPDC);
				this.leStartStopDeRefresh(true);
			}
		}
	}
	
	componentWillUnmount()
	{
		this.leStartStopDeRefresh(false);
	}
	
	leStartStopDeRefresh = (lancer) =>
	{
		
		//console.log("oh ! " + lancer);
		if (lancer)
		{	
			if (this.IdBoucleData>0)
			{
				console.log("on ne relance pas...");
				return;
			}
			this.IdBoucleData = setInterval(this.getData.bind(this),30000);
		}
		else
		{
			if (this.IdBoucleData<0)
			{
				return;
			}
			clearInterval(this.IdBoucleData);
			this.IdBoucleData=-1;
		}
	}
	
	getData = () => 
	{
		var urlChargerPDC = "ctrl=ControleurPDCCert&methode=voir";
		var urlChargerPDCOptions = {method: "GET", body:""};
		appelAction(urlChargerPDC, urlChargerPDCOptions, this.callbackEndLoading);			
		
	}	
	
	// appele par le composant errordialog pour ack le message => chgt d'etat
	callbackError = () => 
	{
		this.setState({etat:0});
	}
	
	componentDidMount()
	{
		this.getData();
	}
	
	
	render() 
	{
		var indice=-1;
		var restant=[];
		if (this.state.dispos)
		{ 
			restant[0] = []; 	restant[1] = []; 	restant[2] = [];
			restant[0][0] = this.state.dispos[0].semaines[0]*(this.state.dispos.length-1); // semaine 1 le fixe
			restant[1][0] = this.state.dispos[0].semaines[1]*(this.state.dispos.length-1); // semaine 2 le fixe
			restant[2][0] = this.state.dispos[0].semaines[2]*(this.state.dispos.length-1); // semaine 3 le fixe
			restant[0][1] = this.state.dispos[0].semaines[0]*(this.state.dispos.length-1); // semaine 1 le bougeable
			restant[1][1] = this.state.dispos[0].semaines[1]*(this.state.dispos.length-1);
			restant[2][1] = this.state.dispos[0].semaines[2]*(this.state.dispos.length-1);
		}
		var res = [];
		var JOURS=["Lu.","Ma.","Me.","Je.","Ve.","Sa.","Di."]
		res.push(<div key={"vide_1"}></div>);
		res.push(<div key={"vide_2"}></div>);
		for (var i=0; i<((this.state.hno)?21:15); i++)
		{
			res.push(<div className={(this.state.hno)?"PDC_Haut_Col_Jours_Semaine_hno":"PDC_Haut_Col_Jours_Semaine_ho"}>{JOURS[i%((this.state.hno)?7:5)]}</div>);
			if ((i+1)%((this.state.hno)?7:5)===0)
				res.push(<div></div>);
		}			
		res.push(<div key={"vide_3"}></div>);
		res.push(<div key={"vide_4"}></div>);
		for (var i=0; i<((this.state.hno)?21:15); i++)
		{
			res.push(<div className={"PDC_Haut_Col_Jours_Semaine_picto"}><img width={15} src={Image1}/></div>);
			res.push(<div className={"PDC_Haut_Col_Jours_Semaine_picto"}><img width={15} src={Image2}/></div>);
			if (this.state.hno)
				res.push(<div className={"PDC_Haut_Col_Jours_Semaine_picto"}><img width={15} src={Image3}/></div>);
			if ((i+1)%((this.state.hno)?7:5)===0)
				res.push(<div></div>);
		}
		
		
		return <div className="container_array_ligne_pdc_cert">
				<DiagError key={"diag"} etat={this.state.etat} mess={this.state.mess} callback={this.callbackError}></DiagError>
		
				<Backdrop className="backdrop" open={this.state.loading}>
					<CircularProgress color="inherit" />
				</Backdrop>

				
				<div key={"PDC_CERT"} className={(this.state.hno)?"PDC_Haut_Visu_CERT_hno":"PDC_Haut_Visu_CERT_ho"}>
					
					{this.state.dispos&&this.state.dispos.map((row) =>
						{
							
							indice++;
						
							if (indice==0)
								return <React.Fragment>
									<div></div>
									<div></div>
									<div className={(this.state.hno)?"PDC_Haut_Col_Semaine_hno":"PDC_Haut_Col_Semaine_ho"}>{row.labels[0]}</div>
									<div className={(this.state.hno)?"PDC_Haut_Col_Semaine_hno":"PDC_Haut_Col_Semaine_ho"}>{row.labels[1]}</div>
									<div className={(this.state.hno)?"PDC_Haut_Col_Semaine_hno":"PDC_Haut_Col_Semaine_ho"}>{row.labels[2]}</div>
									{res}
									</React.Fragment>
							
							res = [];
							var tab = row.occupation;
							var i;
							var classe = "PDC_Certien_"+(indice%2);
								
							res.push(<div className={"PDC_UpDown_picto"}>{(indice>1)?<IconUp onClick={this.moveUp} data-indice={indice} sx={{p:0, border:0, gap:0, m:0, height:8}}/>:""}{(indice<this.state.dispos.length-1)?<IconDown onClick={this.moveDown} data-indice={indice} sx={{p:0, border:0, gap:0, m:0, height:8}} />:""}</div>);
							res.push(<div className={classe}>{row.certien.prenom + " " + row.certien.nom + " ("+row.certien.trigramme+")"}</div>);
							for(i=0;i<21;i++)
							{
								// on passe le WE
								var jour = i;
								if (!this.state.hno && (i===5 || i===6 || i===12 || i===13 || i===19 || i===20) )
								{
									continue;
								}
								
								var semaine = Math.floor(jour/7);
								
								classe = "PDC_Case_Jours_picto_"+(indice%2);
								
								if ( row.occupation[jour].MF === 0 && row.occupation[jour].MB === 0 )
									res.push(<div className={classe}><LockOpenIcon sx={{ color: green[500], fontSize: 15 }}/></div>);
								else if ( row.occupation[jour].MF + row.occupation[jour].MB  > 1)
									res.push(<div className={classe}><ReportIcon sx={{ color: red[900], fontSize: 15 }}/></div>);
								else if ( row.occupation[jour].MF === 1)
								{	res.push(<div className={classe}><Tooltip title={row.occupation[jour].MissionM}><LockIcon sx={{ color: red[500], fontSize: 15 }}/></Tooltip></div>); restant[semaine][0]--; restant[semaine][1]--; } 
								else if ( row.occupation[jour].MB === 1)
								{ res.push(<div className={classe}><Tooltip title={row.occupation[jour].MissionM}><LockIcon sx={{ color: yellow[800], fontSize: 15 }}/></Tooltip></div>); restant[semaine][1]--;  }
								
								if ( row.occupation[jour].AF === 0 && row.occupation[jour].AB === 0 )
									res.push(<div className={classe}><LockOpenIcon sx={{ color: green[500], fontSize: 15 }}/></div>);
								else if ( row.occupation[jour].AF + row.occupation[jour].AB  > 1)
								{	res.push(<div className={classe}><ReportIcon sx={{ color: red[900], fontSize: 15 }}/></div>); }
								else if ( row.occupation[jour].AF === 1)
								{	res.push(<div className={classe}><Tooltip title={row.occupation[jour].MissionA}><LockIcon sx={{ color: red[500], fontSize: 15 }}/></Tooltip></div>); restant[semaine][0]--; restant[semaine][1]--; }
								else if ( row.occupation[jour].AB === 1)
								{	res.push(<div className={classe}><Tooltip title={row.occupation[jour].MissionA}><LockIcon sx={{ color: yellow[800], fontSize: 15 }}/></Tooltip></div>); restant[semaine][1]--; }
								
								if (!this.state.hno && (i+1===5 || i+1===12 || i+1===19 ) )
								{
									res.push(<div className={classe}>  </div>);
								}
								
								
							}
							
							return res;
											
						}
					
					)}
					{this.state.dispos&&<React.Fragment>
									<div></div>
									<div>Jours non fixes  :</div>
									<div className={(this.state.hno)?"PDC_Haut_Col_Semaine_hno":"PDC_Haut_Col_Semaine_ho"}>{restant[0][0]/2.}</div>
									<div className={(this.state.hno)?"PDC_Haut_Col_Semaine_hno":"PDC_Haut_Col_Semaine_ho"}>{restant[1][0]/2.}</div>
									<div className={(this.state.hno)?"PDC_Haut_Col_Semaine_hno":"PDC_Haut_Col_Semaine_ho"}>{restant[2][0]/2.}</div>
									
									<div></div>
									<div>Jours libres : </div>
									<div className={(this.state.hno)?"PDC_Haut_Col_Semaine_hno":"PDC_Haut_Col_Semaine_ho"}>{restant[0][1]/2.}</div>
									<div className={(this.state.hno)?"PDC_Haut_Col_Semaine_hno":"PDC_Haut_Col_Semaine_ho"}>{restant[1][1]/2.}</div>
									<div className={(this.state.hno)?"PDC_Haut_Col_Semaine_hno":"PDC_Haut_Col_Semaine_ho"}>{restant[2][1]/2.}</div>
									<br />
									</React.Fragment>}
					
					
				</div>

				<div className={"PDC_Bas_Lignes_CERT"}>
				<form className="" noValidate>
					{this.state.lignesPDC.map( 
			  
						(row) => { 
							return <Row row={row} 
										leStartStopDeRefresh={this.leStartStopDeRefresh} 
										callback={this.callbackEndLoading}>
									</Row> 
							})
					}
				</form>
				</div>
				
				
				</div>
		
	}
	
} 
export default PDC_Cert
