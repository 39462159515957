import React, { Component } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AdapterDateFns from '@date-io/date-fns';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import {fr} from 'date-fns/locale';
import Slider from '@mui/material/Slider';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import ApartmentIcon from '@mui/icons-material/Apartment';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PostAddIcon from '@mui/icons-material/PostAdd';
import IconButton from '@mui/material/IconButton';
import DescriptionIcon from '@mui/icons-material/Description';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import SettingsIcon from '@mui/icons-material/Settings';
import EditIcon from '@mui/icons-material/Edit';
import TodayIcon from '@mui/icons-material/Today';
import EuroIcon from '@mui/icons-material/Euro';
import CancelIcon from '@mui/icons-material/Cancel';
import Confetti from 'react-confetti'

import classNames from "classnames"

import { appelAction, DiagError } from './utils.js';
import { listeClients, listeUtilisateurs } from './index.js';

import "./LesAV_Audit.css";
 
class AvantVente_Audit extends Component {
	

  

	constructor(props) {
		super(props);

		var date = new Date();
		this.state = {etat:0, nombreConfettis:0, mess:"ok", listeAV:[], disableButtons: false, jours : [], tjs : [], roles: [], 
		accordeonCreation: false, accordeonModifier: false, accordeonListeAV: false, accordeonListeGagnees: false, todo :"" , maxVisu: 6,
		num:"", client: "", mission: "", desc: "", proba: 50, complexite:2,  langue:"FR", listePorteursPropale:[],
		dateRemise: (date.getMonth()+1) +"/"+ date.getDate() +"/"+ date.getFullYear()};
		
		// les fonctions
		
		this.bindedTextFieldChange = this.handleTextFieldChange.bind(this);
		this.bindedChangeDateRemise = this.handleChangeDateRemise.bind(this);
		this.bindedChangeProba = this.handleChangeProba.bind(this);
		this.bindedChangeLG = this.handleChangeLG.bind(this);
		this.bindedChangeComplexite = this.handleChangeComplexite.bind(this);
		this.bindedTextFieldTJMJoursChange = this.handleTextFieldTJMJoursChange.bind(this);
		this.binded = this.creationMission.bind(this);
		this.bindedCreationMission = this.creationMission.bind(this);
		this.bindedModificationMission = this.modificationMission.bind(this);
		this.bindedStafferPropale = this.stafferPropale.bind(this);
		this.bindedChiffrerPropale = this.chiffrerPropale.bind(this);
		this.bindedStaffPropaleChange = this.handleStaffPropaleChange.bind(this);
		this.bindedDeleteLigneRoleJTM = this.deleteLigneRoleJTM.bind(this);
		this.bindedChangeRole = this.handleChangeRole.bind(this);
	}
	
  	// appele par le composant errordialog pour ack le message => chgt d'etat
  callbackError = () => 
  {
		this.setState({etat:0});
  }
  
  ajouter6Mois()
  {
		var v = this.state.maxVisu+6;
		this.setState({maxVisu: v});
		var urlOptionsCreerPropale = "ctrl=ControleurPropale&methode=voir&max="+v;
		var requestOptionsCreerPropale = {method: "GET", body:""};
		appelAction(urlOptionsCreerPropale, requestOptionsCreerPropale, this.handleClickAfficherAV);  
  }
	
  handleChange(panel)
  {
	// on force la modif => mais c'est pas possible !!
	if (panel === "accordeonModifier" && ! this.state.accordeonModifier)
	{
		return;
	}
	
	// on charge la liste des AV
	if (panel === "accordeonListeAV" && ! this.state.accordeonListeAV)
	{
		this.setState({accordeonCreation: false, accordeonModifier: false, accordeonListeGagnees:false});
		var urlOptionsCreerPropale = "ctrl=ControleurPropale&methode=voir";
		var requestOptionsCreerPropale = {method: "GET", body:""};
		appelAction(urlOptionsCreerPropale, requestOptionsCreerPropale, this.handleClickAfficherAV);
	}
	if (panel === "accordeonListeGagnees" && ! this.state.accordeonListeGagnees)
	{
		this.setState({accordeonCreation: false, accordeonModifier: false, accordeonListeAV: false});
		var urlOptionsCreerPropale = "ctrl=ControleurPropale&methode=voir&max="+this.state.maxVisu;
		var requestOptionsCreerPropale = {method: "GET", body:""};
		appelAction(urlOptionsCreerPropale, requestOptionsCreerPropale, this.handleClickAfficherAV);
	}
	
	this.setState({[panel]: !this.state[panel], maxVisu:6});
	
  }
  
  
  handleStaffPropaleChange(e, v)
  {
	  var tab = [];
	  if (v.id)
	  {
		tab = this.state.listePorteursPropale.slice();
		tab = tab.find((u) => u.id !== v.id );

	  }
	  else if (e.target.id.startsWith('staffingPropale'))
	  {
		 tab=[];
		 for (var i = 0; i<v.length; i++)
			tab.push(v[i].id);	
	  }
	  
	 this.setState({listePorteursPropale : tab});
	  
  }
  
  handleTextFieldChange (e,v)
  {
	  var option = "";
	  if(e.target.id.startsWith('client'))
		option = "client"
	  else if(e.target.id.startsWith('salesforce'))
		option = "num"
	  else if(e.target.id.startsWith('desc'))
		option = "desc"
	  else if(e.target.id.startsWith('mission'))
		option = "mission"
	  else 
		return;
	  
	  var val="";
	  if (v == null || isNaN(e.target.value))
	  { val = e.target.value; }
	  else
	  { val = v.nom; }

	this.setState({[option]: val});
  }
  
  handleChangeDateRemise (e) 
  {
	  var date = new Date(e);
	  this.setState({ dateRemise : (date.getMonth()+1) +"/"+ date.getDate() +"/"+ date.getFullYear()});
  }
  
  handleChangeLG(e)
  {
	 this.setState({ langue : e.target.value});
  } 
  
  handleChangeProba(e,v)
  {
	 this.setState({ proba : v});
  }
    
  handleChangeComplexite(e,v)
  {
	 this.setState({ complexite : v});
  }
  
  handleTextFieldTJMJoursChange(e, v)
  {
	var indice = parseInt(e.target.id.substring(e.target.id.indexOf("_")+1));	 
	if (indice === -1)
		alert("Remplir le role svp");
	var tab1 = this.state.tjs.slice();
	var tab2 = this.state.jours.slice();
	var valeur = e.target.value;
	if(e.target.id.startsWith('jours'))
	{
		tab2[indice]=valeur;
	}
	if(e.target.id.startsWith('prix'))
	{
		tab1[indice]=valeur;
	}
	this.setState({ tjs: tab1, jours: tab2});	
  }
  
  handleChangeRole (e, v) 
  {
	var indice = parseInt(v.props.name.substring(2));
//	var focusTo = 0;
	if (isNaN(indice))
		indice=-1;
	var tab = this.state.roles.slice();
	var tab1 = this.state.tjs.slice();
	var tab2 = this.state.jours.slice();
	if (indice===-1)
	{
		tab.push(e.target.value);
		tab1.push("0.0");
		tab2.push("0.0");
		//focusTo = tab.length-1;
	}
	else
	{
		tab[indice] = e.target.value;
	//	focusTo = indice;
	}	
	this.setState({ roles: tab, tjs: tab1, jours: tab2});
  }
  
  deleteLigneRoleJTM (e)
  {
	  // 4 => del_
	var indice = parseInt(e.currentTarget.id.substring(4));
	var max = Math.max(this.state.roles.length, this.state.tjs.length, this.state.jours.length);
	var tab1 = []; 	var tab2 = []; var tab3 = [];

	if (indice === -1)
		indice=max;
	
	var i;	
	for(i = 0 ; i<indice; i++)
	{
		tab1.push(this.state.roles[i]); tab2.push(this.state.tjs[i]); tab3.push(this.state.jours[i]); 
	}
	for(i = indice+1 ; i<max; i++)
	{
		tab1.push(this.state.roles[i]); tab2.push(this.state.tjs[i]); tab3.push(this.state.jours[i]); 
	}
	
	this.setState({roles:tab1, tjs:tab2, jours:tab3});
	
	}

	calculTJM()
	{
		var res = 0.0;
		for(var i = 0 ; i<this.state.roles.length; i++)
			res += parseFloat(this.state.jours[i]);
		return this.calculPrix()/res;
	}
	
	calculPrix()
	{
		var res = 0.0;
		for(var i = 0 ; i<this.state.roles.length; i++)
			res += parseFloat(this.state.tjs[i])*parseFloat(this.state.jours[i]);
		return res;
	}

  /* creation d'une AV */
  handleClickCallback = (obj) =>
  {
	  if (obj.etat === 4) // erreur et on garde le truc ouvert
		this.setState({etat:obj.etat, mess:obj.mess});
	  else
	  {
		  var date = new Date();
		  this.setState({etat:obj.etat, mess:obj.mess, todo :"", jours : [], tjs : [], roles: [], 
				accordeonCreation: false, accordeonModifier: false, accordeonListeAV: false,  
				num:"", client: "", mission: "", desc: "", proba: 50, complexite:2,  langue:"FR", listePorteursPropale:[],
				dateRemise: (date.getMonth()+1)+"/"+date.getDate()+"/"+date.getFullYear() });
	  }
	  this.setState({disableButtons: false});
  }
  
  /* affichage des AV */
  handleClickAfficherAV = (obj) =>
  {
	
	if (obj.etat === 4) // erreur 
		this.setState({etat:obj.etat, mess:obj.mess});
	  else
	  {
		this.setState({etat:obj.etat, mess:obj.mess, listeAV:obj.data});
	}
  }
  
  
  creationMission () 
  {
	  
	this.setState({disableButtons: true});
	var urlOptionsCreerPropale = "ctrl=ControleurPropale&methode=creer";
	var requestOptionsCreerPropale = {method: "POST", body:""};

	requestOptionsCreerPropale.body += "num="+this.state.num;
	requestOptionsCreerPropale.body += "&client="+this.state.client.sansEtCom();
	requestOptionsCreerPropale.body += "&mission="+this.state.mission.sansEtCom();
	requestOptionsCreerPropale.body += "&desc="+this.state.desc.sansEtCom();
	requestOptionsCreerPropale.body += "&proba="+this.state.proba;
	requestOptionsCreerPropale.body += "&complexite="+this.state.complexite;
	requestOptionsCreerPropale.body += "&dateRemise="+this.state.dateRemise;
	requestOptionsCreerPropale.body += "&lg="+this.state.langue;
	
	appelAction(urlOptionsCreerPropale, requestOptionsCreerPropale, this.handleClickCallback);
  }
  
  modificationMission () 
  {
	this.setState({disableButtons: true});
	var urlOptionsCreerPropale = "ctrl=ControleurPropale&methode=modifier";
	var requestOptionsCreerPropale = {method: "POST", body:""};

	requestOptionsCreerPropale.body += "id="+this.state.id;
	requestOptionsCreerPropale.body += "&num="+this.state.num;
	requestOptionsCreerPropale.body += "&client="+this.state.client.sansEtCom();
	requestOptionsCreerPropale.body += "&mission="+this.state.mission.sansEtCom();
	requestOptionsCreerPropale.body += "&desc="+this.state.desc.sansEtCom();
	requestOptionsCreerPropale.body += "&proba="+this.state.proba;
	requestOptionsCreerPropale.body += "&complexite="+this.state.complexite;
	requestOptionsCreerPropale.body += "&dateRemise="+this.state.dateRemise;
	requestOptionsCreerPropale.body += "&lg="+this.state.langue;
	
	appelAction(urlOptionsCreerPropale, requestOptionsCreerPropale, this.handleClickCallback);
  }

  stafferPropale () 
  {
	this.setState({disableButtons: true});
  
	var urlOptionsCreerPropale = "ctrl=ControleurPropale&methode=staffer";
	var requestOptionsCreerPropale = {method: "POST", body:""};
	
	requestOptionsCreerPropale.body += "id="+this.state.id;
	requestOptionsCreerPropale.body += "&listePorteurs="+this.state.listePorteursPropale;
				
	appelAction(urlOptionsCreerPropale, requestOptionsCreerPropale, this.handleClickCallback);
	
  }
  
  
  chiffrerPropale () 
  {
	this.setState({disableButtons: true});
  
	var urlOptionsCreerPropale = "ctrl=ControleurPropale&methode=chiffrer";
	var requestOptionsCreerPropale = {method: "POST", body:""};
	
	requestOptionsCreerPropale.body += "id="+this.state.id;
	requestOptionsCreerPropale.body += "&roles="+this.state.roles;
	requestOptionsCreerPropale.body += "&tjs="+this.state.tjs;
	requestOptionsCreerPropale.body += "&jours="+this.state.jours;
	
	appelAction(urlOptionsCreerPropale, requestOptionsCreerPropale, this.handleClickCallback);
	
  }
  
	valuetextProba = (value) => 
	{
		return value+"%";
	}
	
	handleModificationAV = (obj) =>
	{
		
		if (obj.etat === 4) // erreur 
		{
			this.setState({etat:obj.etat, mess:obj.mess});
			return;
		}		
		
		this.setState({
				accordeonCreation: false, accordeonModifier:true, accordeonListeAV:false, jours: obj.data.jours, tjs: obj.data.tjs, roles: obj.data.roles, 
				num:obj.data.numSF, client: obj.data.client, mission: obj.data.mission, desc: obj.data.desc, listePorteursPropale: obj.data.listePorteurs,
				proba: obj.data.proba, complexite: obj.data.complexite, langue:obj.data.langue, dateRemise: obj.data.dateRemise,  
				id : obj.data.id
				});
	}
	
	
	chargementPropaleState(propale, etat)
	{
				
		if (etat === "perdre")
		{
			alert ("dommage");
			return;
		}
		if (etat === "gagner")
		{
			var urlOptionsGagnerPropale = "ctrl=ControleurPropale&methode=changer";
			var requestOptionsGagnerPropale = {method: "POST", body:""};
			requestOptionsGagnerPropale.body += "id="+propale.id;
			requestOptionsGagnerPropale.body += "&verdict=1";

			
			appelAction(urlOptionsGagnerPropale, requestOptionsGagnerPropale, this.handleClickCallback);			
		
			this.lancerLesConfettis()
			return;
		}
		
		this.setState({todo: etat});
		
		var urlOptionsCreerPropale = "ctrl=ControleurPropale&methode=voir&id="+propale.id;
		var requestOptionsCreerPropale = {method: "GET", body:""};
		appelAction(urlOptionsCreerPropale, requestOptionsCreerPropale, this.handleModificationAV);			
				
	}


  /* affiche l'interieur de l'accorder avec les trucs de base */
  /* à linteterieur de AV Grille */
  renderAccordeon()
  {
	  return (<React.Fragment>
	  <div className={"inputText_numSF"}>
				<TextField fullWidth id="salesforce" label="Num. Salesforce" value={this.state.num}
					onChange={this.bindedTextFieldChange}
						InputProps={{	
							startAdornment: <InputAdornment position="start"><FormatListNumberedIcon/></InputAdornment>,
					}} 
					
				/>
			</div>
			
			<div className={"inputText_AV_DateR"}>
				<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
				<DesktopDatePicker
					id='dateRemise'
					label="Date Remise (DD/MM/YYYY)"
					value = {this.state.dateRemise}
					minDate={new Date().setFullYear(new Date().getFullYear()-2)}
					maxDate={new Date().setFullYear(new Date().getFullYear()+5)}
					onChange = {this.bindedChangeDateRemise}
					renderInput={(params) => <TextField {...params} />}
					inputFormat="dd/MM/yyyy"
					 />
				</LocalizationProvider>
			</div>


			<div className={"inputText_AV_Proba"}>
			    <Typography component={'div'} id="discrete-slider" gutterBottom>
					<div className={"inputText_AV_Proba_legend"}>Proba : {this.state.proba}%</div>
			
				  </Typography>
				  <Slider
					value={this.state.proba}
					getAriaValueText={this.valuetextProba}
					aria-labelledby="discrete-slider"
					valueLabelDisplay="auto"
					id="proba"
					onChange={this.bindedChangeProba}
					step={25}
					marks
					min={0}
					max={100}
				  />
				  
			</div>
			
			<div className={"inputText_AV_nomClient"} >
				<Autocomplete
					id="client"
					options={listeClients}
					freeSolo
					autoSelect
					onChange={this.bindedTextFieldChange}
					getOptionLabel={ (option) => (typeof option == "string") ? option : option.nom  }
					value={this.state.client}
					renderInput={(params) => <TextField {...params} fullWidth required id="client_area" label="Nom du client" onChange={this.bindedTextFieldChange}
						InputProps={{	
							...params.InputProps,
							startAdornment: <InputAdornment position="start"><ApartmentIcon/></InputAdornment>,
				}} /> }
					
				/>
			</div>
			<div className={"inputText_AV_nomMission"} >
				<TextField  fullWidth  required id="mission" label="Nom de la mission" value={this.state.mission} 	
					onChange={this.bindedTextFieldChange}
				InputProps={{
					startAdornment: <InputAdornment position="start"><FolderOpenIcon/></InputAdornment>,
				}}
				/>
			</div>
			
			<div className={"inputText_AV_LG"} >
			
				<FormControl>
				<InputLabel className={"inputText_AV_LG_legend"} id="lg">Langue</InputLabel>
				<Select
				  labelId="Langue"
				  id="lg"
				  value={this.state.langue}
				  onChange={this.bindedChangeLG}
				>
				  <MenuItem value={"FR"}>FR</MenuItem>
				  <MenuItem value={"EN"}>EN</MenuItem>
				</Select>
			  </FormControl>
					
			</div>

			<div className={"inputText_AV_Desc"}>
				<TextField fullWidth id="desc" multiline={true} rows={4} label="Description" value={this.state.desc} 
					onChange={this.bindedTextFieldChange}
				InputProps={{
					startAdornment: <InputAdornment position="start"><DescriptionIcon/></InputAdornment>,
				}}
				/>
			</div>
			
			
			<div >
			
			<Grid item component='fieldset' className={"input_AV_Gears"}>
			<FormLabel component="legend" ><div className={"input_AV_Gears_legend"}>Compléxité</div></FormLabel>
						
			<RadioGroup
				aria-label="Compléxité"
				value={this.state.complexite}
				name="complex"
				id="complex"
				onChange={this.bindedChangeComplexite}
			>
			
				<FormControlLabel value={1} control={<Radio />} label={<div className={"input_AV_Gears_ss_legend"}><div className={"input_AV_Gear"}><SettingsIcon className={"input_AV_ss_Gear"}/></div><div className={"input_AV_Gear_txt"}>Devis, propale TI basiques, etc.</div></div>}/>
				<FormControlLabel value={2} control={<Radio />} label={<div className={"input_AV_Gears_ss_legend"}><div className={"input_AV_Gear"}><SettingsIcon className={"input_AV_ss_Gear"}/><SettingsIcon className={"input_AV_ss_Gear"}/></div><div className={"input_AV_Gear_txt"} >Standard (360°, TI interne, etc.)</div></div>}/>
				<FormControlLabel value={3} control={<Radio />} label={<div className={"input_AV_Gears_ss_legend"}><div className={"input_AV_Gear"}><SettingsIcon className={"input_AV_ss_Gear"}/><SettingsIcon className={"input_AV_ss_Gear"}/><SettingsIcon className={"input_AV_ss_Gear"}/></div><div className={"input_AV_Gear_txt"}>Marché cadre, etc.</div></div>}/>
			</RadioGroup>
			</Grid>
			</div>
			</React.Fragment>
			)
  }

  renderComplexite(nb)
  {
	  var res =[]
	  for(let i=0;i<nb;i++)
		res.push(<SettingsIcon key={"id_gear_"+this.state.id+"_"+i} />);
	  return res;
  }
  

  
  renderElementsRoleTJM(indice)
  {
	  return <React.Fragment>
	  <div style={{ 'gridColumnStart': '1'}}>
			<div style={{ 'display': 'grid', 'gridTemplateColumns': '1fr 6fr', 'alignItems': 'center'}}>
			<CancelIcon id={"del_"+indice} onClick={this.bindedDeleteLigneRoleJTM} />
			<Box>
				<FormControl fullWidth>
					<InputLabel id="role-label">Role</InputLabel>
					<Select labelId="role" id={"role_"+indice} value={indice!==-1&&indice<this.state.roles.length?this.state.roles[indice]:""} label="Role" onChange={this.bindedChangeRole} >
						  <MenuItem name={"X_"+indice} value={"X"}>X</MenuItem>
						  <MenuItem name={"C_"+indice} value={"CP"}>CP</MenuItem>
						  <MenuItem name={"E_"+indice} value={"E"}>Expert</MenuItem>
					</Select>
				</FormControl>
			</Box>
			</div>
		</div>
		<div>
		<TextField error={(indice>=0&&isNaN(this.state.jours[indice]))?true:false}  fullWidth id={"jours_"+indice} label="Jours" value={indice!==-1&&indice<this.state.jours.length?this.state.jours[indice]:"0.0"}
				onChange={this.bindedTextFieldTJMJoursChange}
					InputProps={{	
						startAdornment: <InputAdornment position="start"><TodayIcon /></InputAdornment>,
				}} 
			/>
		</div>
		<div>
			<TextField error={(indice>=0&&isNaN(this.state.jours[indice]))?true:false}  fullWidth id={"prix_"+indice} label="TJM" value={indice!==-1&&indice<this.state.tjs.length?this.state.tjs[indice]:"0.0"}
				onChange={this.bindedTextFieldTJMJoursChange}
					InputProps={{	
						startAdornment: <InputAdornment position="start"><EuroIcon /></InputAdornment>,
			}} 
			/>
		</div>  
	  </React.Fragment>
  }
  
  lancerLesConfettis()
  {
	this.setState({nombreConfettis : this.state.nombreConfettis+200});  
  }

	
  render() {
	  
	var porteursPropale = [];
	if (this.state.listePorteursPropale && this.state.listePorteursPropale.length>0)
	{
		porteursPropale = listeUtilisateurs.filter(u => this.state.listePorteursPropale.includes(u.id) ); 
	}	  
	var possiblesPorteurs = [];
		possiblesPorteurs = listeUtilisateurs.filter(u => u.actif === "true");
	  
	var ligne=0;
	var indice=-1;
	//var indice2=-1;
    return (
        <div className="LesAV">
        <Confetti className="LesAV_Confettis" recycle={false} numberOfPieces={this.state.nombreConfettis} /> 
          <h2>Les Avant Ventes</h2>
          <DiagError key={"diag"} etat={this.state.etat} mess={this.state.mess} callback={this.callbackError}></DiagError>
          
          <form className="" noValidate>
          
          {/* 1er block : ajout d'une AV */}
          
          <Accordion expanded={this.state.accordeonCreation} onChange={() => this.handleChange('accordeonCreation')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography>{(this.state.accordeonCreation)?'':'Ajouter une AV...'}</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: 'accordeon' }} >
            <div className={"AV_grille"} >
      
              {this.renderAccordeon()}

              <div className={"AV_derniereLigne"}>
                  <Button disabled={this.state.disableButtons} onClick={this.bindedCreationMission} variant="contained">Ajouter</Button>
              </div>
              
              </div>
          </AccordionDetails>
          </Accordion>

          {/* 2eme block : recherche et modif d'une AV */}
          
          <Accordion expanded={this.state.accordeonModifier} onChange={() => this.handleChange('accordeonModifier')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography>{(this.state.accordeonModifier)?'':'Modifier une AV ...'}</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: 'accordeon' }} >
      
              {(this.state.todo.startsWith("modifier")) &&			
                  <div className={"AV_grille"} >
                  {this.renderAccordeon()}
                  <div className={"AV_derniereLigne"}>
                      <Button disabled={this.state.disableButtons} onClick={this.bindedModificationMission} variant="contained">Modifier</Button>
                  </div>
                  </div>
              }			
              {(this.state.todo.startsWith("staffer")) &&			
                  <div className={"AV_grille"} >
                  {this.renderAccordeon()}
                  <div className={"AV_staffingPropale"}>
              
                      <Autocomplete
                          id="staffingPropale"
                          multiple
                          filterSelectedOptions 
                          options={possiblesPorteurs}
                          autoSelect
                          renderOption={(props, option) => {
                          return (
                                  <li {...props} key={option.id}>
                                      {option.prenom + " " + option.nom + " ("+option.trigramme+")"}
                                  </li>
                                  );
                              }}
                          onChange={this.bindedStaffPropaleChange}
                          getOptionLabel={ (utilisateur) => (utilisateur && utilisateur.nom && utilisateur.staffable==="true")?utilisateur.prenom + " " + utilisateur.nom:""  }
                          value={porteursPropale}
                          renderInput={(params) => <TextField {...params} fullWidth required id="staffing_area" label="Porteur(s)" /> }			
                          />
              
                  </div>
                  <div>
                      <Button disabled={this.state.disableButtons} onClick={this.bindedStafferPropale} variant="contained">Staffer</Button>
                  </div>
              </div>
              }
              {(this.state.todo.startsWith("chiffrer")) &&			
                  <div className={"AV_grille"} >
                  {this.renderAccordeon()}
                  
                  {   
                      this.state.roles.map( (i) => {
                          indice++;
                          return this.renderElementsRoleTJM(indice);
                      })
                  }
                  {this.renderElementsRoleTJM(-1)}
                  
                  <div>
                  TJM : {this.calculTJM()}
                  </div>
                  
                  <div>
                  Prix : {this.calculPrix()}
                  </div>
                  
                  <div>
                      <Button disabled={this.state.disableButtons} onClick={this.bindedChiffrerPropale} variant="contained">Chiffrer</Button>
                  </div>
              </div> 
              }	
          
          </AccordionDetails>
          </Accordion>
          
                  {/* 3eme block : Les AV en cours */}
          
          <Accordion expanded={this.state.accordeonListeAV} onChange={() => this.handleChange('accordeonListeAV')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography>{(this.state.accordeonListeAV)?'':'AV en cours ...'}</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: 'accordeon' }} >
              
          <div className="AV_listeAV_grille">
              <div className="AV_listeAV_grille_1ere_ligne">DateRemise</div>
              <div className="AV_listeAV_grille_1ere_ligne">Client</div>
              <div className="AV_listeAV_grille_1ere_ligne">Mission</div>
              <div className="AV_listeAV_grille_1ere_ligne">Langue</div>
              <div className="AV_listeAV_grille_1ere_ligne">Desc</div>
              <div className="AV_listeAV_grille_1ere_ligne">Proba</div>
              <div className="AV_listeAV_grille_1ere_ligne">Compléxité</div>
              <div className="AV_listeAV_grille_1ere_ligne">Action</div>


              { 

                  this.state.listeAV.map( (row) => {
                      ligne++;
                      let date = row.dateRemise.split("/");
                      var clLigne = classNames({
                              'AV_listeAV_grille_Neme_ligne': true,
                              'AV_listeAV_grille_Neme_ligne_impair': ligne%2===0
                              });
                      return (
                          <React.Fragment key={"AV_liste_"+ligne}>
                                  <div className={clLigne}>{date[1]+"/"+date[0]+"/"+date[2]}</div>
                                  <div className={clLigne}>{row.client}</div>
                                  <div className={clLigne}>{row.mission}</div>
                                  <div className={clLigne}>{row.langue}</div>
                                  <div className={clLigne}>{row.desc}</div>
                                  <div className={clLigne}>{row.proba}</div>
                                  <div className={clLigne}><div>{this.renderComplexite(row.complexite)}</div></div>
                                  <div className={clLigne}> 
                                      <div style={{display: "inherit"}}>
                                      { (row.status==="A_TRAITER" || row.status==="A_EMETTRE" || row.status==="ENVOYEE")  && 
                                      
                                          <React.Fragment>
                                          <Tooltip title="Modifier">
                                          <IconButton
                                              aria-label="on modif"
                                              component="span"
                                              onClick={() => this.chargementPropaleState(row,"modifier")}
                                              size="large">
                                              <EditIcon />
                                          </IconButton>
                                          </Tooltip>
                                          <Tooltip title="Staffer">
                                          <IconButton
                                              aria-label="on staf"
                                              component="span"
                                              onClick={() => this.chargementPropaleState(row,"staffer")}
                                              size="large">
                                              <PersonAddIcon />
                                          </IconButton>	
                                          </Tooltip>
                                          </React.Fragment>
                                      }
                                      { (row.status==="A_EMETTRE" || row.status==="ENVOYEE") &&
                                          <Tooltip title="Chiffrer">
                                          <IconButton
                                              aria-label="on va envoyer"
                                              component="span"
                                              onClick={() => this.chargementPropaleState(row,"chiffrer")}
                                              size="large">
                                              <PostAddIcon   />
                                          </IconButton>
                                          </Tooltip>
                                      }
                                      { (row.status==="ENVOYEE") &&
                                      
                                          <React.Fragment>
                                          <Tooltip title="C'est gagné">
                                          <IconButton
                                              style={{ color: 'green' }}
                                              aria-label="c'est WIN"
                                              onClick={() => this.chargementPropaleState(row,"gagner")}
                                              component="span"
                                              size="large">
                                              <ShoppingCartIcon />
                                          </IconButton>
                                          </Tooltip>
                                          <Tooltip title="C'est perdu">
                                          <IconButton
                                              style={{ color: 'red' }}
                                              aria-label="c'est DEAD"
                                              onClick={() => this.chargementPropaleState(row,"perdre")}
                                              component="span"
                                              size="large">
                                              <RemoveShoppingCartIcon  />
                                          </IconButton>
                                          </Tooltip>
                                          </React.Fragment>
                                          
                                      }
                                      </div> 
                                      
                                  </div>
                                  </React.Fragment>
                      );
                  })
              }
              
              </div>
          </AccordionDetails>
          </Accordion>
          
                  {/* 4eme block : Les AV gagnees */}
          
          <Accordion expanded={this.state.accordeonListeGagnees} onChange={() => this.handleChange('accordeonListeGagnees')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography>{(this.state.accordeonListeGagnees)?'':'AV gagnées ...'}</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: 'accordeon' }} >
              
          <div className="AV_listeAV_grille">
              <div className="AV_listeAV_grille_1ere_ligne">DateRemise</div>
              <div className="AV_listeAV_grille_1ere_ligne">Client</div>
              <div className="AV_listeAV_grille_1ere_ligne">Mission</div>
              <div className="AV_listeAV_grille_1ere_ligne">Langue</div>
              <div className="AV_listeAV_grille_1ere_ligne">Desc</div>
              <div className="AV_listeAV_grille_1ere_ligne">Proba</div>
              <div className="AV_listeAV_grille_1ere_ligne">Compléxité</div>
              <div className="AV_listeAV_grille_1ere_ligne">Action</div>


              { 

                  this.state.listeAV.map( (row) => {
                      ligne++;
                      let date = row.dateRemise.split("/");
                      var clLigne = classNames({
                              'AV_listeAV_grille_Neme_ligne': true,
                              'AV_listeAV_grille_Neme_ligne_impair': ligne%2===0
                              });
                      return (
                          <React.Fragment key={"AV_liste_"+ligne}>
                                  <div className={clLigne}>{date[1]+"/"+date[0]+"/"+date[2]}</div>
                                  <div className={clLigne}>{row.client}</div>
                                  <div className={clLigne}>{row.mission}</div>
                                  <div className={clLigne}>{row.langue}</div>
                                  <div className={clLigne}>{row.desc}</div>
                                  <div className={clLigne}>{row.proba}</div>
                                  <div className={clLigne}><div>{this.renderComplexite(row.complexite)}</div></div>
                                  <div className={clLigne}>
                                      <div style={{display: "inherit"}}>							
                                          <Tooltip title="Modifier">
                                          <IconButton
                                              aria-label="on modif"
                                              component="span"
                                              onClick={() => this.chargementPropaleState(row,"modifier")}
                                              size="large">
                                              <EditIcon />
                                          </IconButton>
                                          </Tooltip>
                                          <Tooltip title="Chiffrer">
                                          <IconButton
                                              aria-label="on va envoyer"
                                              component="span"
                                              onClick={() => this.chargementPropaleState(row,"chiffrer")}
                                              size="large">
                                              <PostAddIcon   />
                                          </IconButton>
                                          </Tooltip>
                                      
                                      </div>
                                  </div>
                                  </React.Fragment>
                      );
                  })
              }
              </div>
              <div style={{display: "grid", "justifyItems": "center"}} onClick={() => this.ajouter6Mois()}>({this.state.maxVisu}) + 6 mois</div>
              </AccordionDetails>
              </Accordion>
          
          </form>
        </div>
    );
  }
}
 
export default AvantVente_Audit;