import React from "react";
import ReactDOM from "react-dom/client";
import './index.css';
import Main from './Main';
import * as serviceWorker from './serviceWorker';

import { appelAction, affichageSemaine, getSemaineActuelle } from './utils.js';

export var listeClients = [];
export var listeUtilisateurs= [];

export var SemainesAnneesPourAffichage=[];
export var SemainesAnnees=[];

for(var i=0;i<5;i++)
{
	SemainesAnneesPourAffichage[i] = affichageSemaine(i);
	SemainesAnnees[i] = getSemaineActuelle(i);
}

export function setListeUtilisateurs(liste)
{
	listeUtilisateurs = liste;
}

export function handleAppelKeepAlive(obj) 
	{
	// on ne traite pas l'erreur... a voir
	if (obj.etat > 0 )
		return;
		
	listeClients = obj.data.clients.slice();
	listeUtilisateurs = obj.data.auditeurs.slice();
	// console.log("KEEEP");
	}	

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Main /> 
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
