import React, { Component } from "react";
import ButtonUnstyled from '@mui/base/ButtonUnstyled';


import "./BouttonsRoue.css";


class BouttonsRoue extends Component
{
	
	constructor(props) {
		super(props);
		this.state = {};
		this.bindedSelectionJoursAFaire = this.selectionJoursAFaire.bind(this);
		this.bindedMasquerRoue = this.masquerRoue.bind(this);
	}
	
	masquerRoue(e)
	{
		this.props.masquerRoue();
	}
	
	selectionJoursAFaire (e) 
	{
		var v = e.currentTarget.textContent;
		this.props.selectionJours(v); 
	}
	

render ()
	{
		var classe="BR_vide";
		if (this.props.visible)
			classe="BR_container" ;
					
		return <div className={classe} style={{top:this.props.roueVisibleY-85, left:this.props.roueVisibleX-85}} >
			<ButtonUnstyled onClick={this.bindedSelectionJoursAFaire} className={"BR_B BR_00"}>0</ButtonUnstyled>
			<ButtonUnstyled onClick={this.bindedSelectionJoursAFaire}  className={"BR_B BR_05"}>0,5</ButtonUnstyled>
			<ButtonUnstyled onClick={this.bindedSelectionJoursAFaire}  className={"BR_B BR_10"}>1</ButtonUnstyled>
			<ButtonUnstyled onClick={this.bindedSelectionJoursAFaire}  className={"BR_B BR_15"}>1,5</ButtonUnstyled>
			<ButtonUnstyled onClick={this.bindedSelectionJoursAFaire}  className={"BR_B BR_20"}>2</ButtonUnstyled>
			<ButtonUnstyled onClick={this.bindedSelectionJoursAFaire}  className={"BR_B BR_25"}>2,5</ButtonUnstyled>
			<ButtonUnstyled onClick={this.bindedSelectionJoursAFaire}  className={"BR_B BR_30"}>3</ButtonUnstyled>
			<ButtonUnstyled onClick={this.bindedSelectionJoursAFaire}  className={"BR_B BR_35"}>3,5</ButtonUnstyled>
			<ButtonUnstyled onClick={this.bindedSelectionJoursAFaire}  className={"BR_B BR_40"}>4</ButtonUnstyled>
			<ButtonUnstyled onClick={this.bindedSelectionJoursAFaire}  className={"BR_B BR_45"}>4,5</ButtonUnstyled>
			<ButtonUnstyled onClick={this.bindedSelectionJoursAFaire}  className={"BR_B BR_50"}>5</ButtonUnstyled>
			<div className={"BR_B BR_fermer"} onClick={this.bindedMasquerRoue}>x</div>
		</div>
	}

	
}


 
export default BouttonsRoue;
