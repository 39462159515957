import React, { Component } from "react";

import { appelAction, DiagError } from './utils.js';

import "./LeSMSI.css";


class LeSMSI2 extends Component
{
	
	constructor(props)
	{
		super(props);
		this.state = {etat:0, mess:"ok", missions:[], date:"jamais...", nbRepSharepoint:0};
	}
	
	handleCallbackView  = (obj) =>
	{
		if (obj.etat === 4) // erreur 
			this.setState({etat:obj.etat, mess:obj.mess});
		else
		{
			this.setState({missions:obj.data});
		}
	}
	
	handleCallbackChargement = (obj) =>
	{
		if (obj.etat === 4) // erreur 
			this.setState({etat:obj.etat, mess:obj.mess});
		else
		{
			this.setState({date:obj.data.update, nbRepSharepoint: obj.data.nbElem});
		}
	}
	
	callbackError = (obj) =>
	{
		this.setState({etat:0});
	}	
	
	componentDidMount()
	{
		var urlOptionsSMSI= "ctrl=ControleurCapitalisation&methode=chiffrer";
		var requestOptionsSMSI = {method: "GET", body:""};
	
		appelAction(urlOptionsSMSI, requestOptionsSMSI, this.handleCallbackView);
		
		var urlOptionsSMSI= "ctrl=ControleurCapitalisation&methode=changer&aLancer=false&estAudit=true";
		var requestOptionsSMSI = {method: "GET", body:""};
	
		appelAction(urlOptionsSMSI, requestOptionsSMSI, this.handleCallbackChargement);
	}
	
	
	render() 
	{
		return( <div className={"c_smsi_container"}>
					<DiagError key={"diag"} etat={this.state.etat} mess={this.state.mess} callback={this.callbackError}></DiagError>
					<div>{this.state.nbRepSharepoint} ({this.state.date})</div>		
					{this.state.missions.map(miss => 
						{return <div className={"c_smsi_ligne"}>
									<div className={"c_smsi_case_c"}>{miss.mission.nomC}</div>
									<div className={"c_smsi_case_m"}>{miss.mission.nomM}</div>
									<div className={"c_smsi_ss_ligne"}>
									{miss.uR.map(dossierRacine => 
										{return <React.Fragment>
												<div className={"c_smsi_case_u"}>{dossierRacine.user.nom +" "+dossierRacine.user.prenom}</div>
												<div className={"c_smsi_case_p"}>{dossierRacine.rights}</div>
												<div className={"c_smsi_case_e"}>{(dossierRacine.estDsLaPAP)?"OK":"KO"}</div>
												</React.Fragment>
										}
									)}
									</div>
						</div>}
					)}
					
				</div>
			);
	}
	
}

export default LeSMSI2;