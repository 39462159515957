import React, { Component } from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Route,
  NavLink,
  HashRouter,
  Redirect
} from "react-router-dom";

import Menu from "./Menu";
import BouttonMenu from "./BouttonMenu";
import PapPerso from "./PapPerso";
import MissionsPerso from "./MissionsPerso";
import PDC_Audit from "./PDC_Audit";
import PDC_Cert from "./PDC_Cert";
import MissionsTrimestre_Audit from "./MissionsTrimestre_Audit";
import LesMissions_Audit from "./LesMissions_Audit";
import LesMissions_Cert from "./LesMissions_Cert";
import AvantVente_Audit from "./LesAV_Audit";
import AvantVente_Cert from "./LesAV_Cert";
import LesPAP from "./LesPAP";
import DreamTeam from "./DreamTeam";
import LesStats from "./LesStats";
import DiagMenageLocalSMSI from "./DiagMenageLocalSMSI"
import LeSMSI from "./LeSMSI"
import LeSMSI2 from "./LeSMSI2"

import { appelAction, getAntiXSRF, getUser, DiagError } from './utils.js';
import { handleAppelKeepAlive } from './index.js';

import "./Main.css";



class Main extends Component
{

	
	constructor(props)
	{
		super(props);

		this.state = {iteration: 0, menuVisible: false, etat:-1, mess:"", antixsrfOK: false, finChargement:false, suppressionSMSI:[]};
		this.menuRef = React.createRef();
		this.idInterval = -1;

	}
		
	keepAlive = () =>  
	{
		
	// recuperation de la liste des clients 
		var urlOptionsClients = "ctrl=ControleurKeepAlive&methode=voir";
		var requestOptions = {method: "GET"};
		appelAction(urlOptionsClients, requestOptions, handleAppelKeepAlive );
	}


	componentDidMount()
	{
		// appel la 1ere fois pour charger le token
		getAntiXSRF(this.handleXSRFCallback);
	}
	
	
	changeMenuVisible = () =>
	{
		this.setState({menuVisible: !this.state.menuVisible});
		this.menuRef.current.plierMenus();
	}
	
	// appele par le composant errordialog pour ack le message => chgt d'etat
	callbackError = () => 
	{
		// this.setState({etat:0});
		// TODO : relance du truc car plantage 
		// getAntiXSRF(this.handleClickCallback);
	}
	
	handleGetUserCallback = (obj) =>
	{
		this.setState({finChargement:true, etat:obj.etat, mess:obj.mess, suppressionSMSI: ((obj.data)?obj.data.suppressionSMSI:null) });
	}
	
	handleXSRFCallback = (etat, mess) =>
	{
		if (etat === 0)
		{
			this.setState({antixsrfOK:true, etat:etat, mess:mess});
			getUser(this.handleGetUserCallback);
		}
		if (etat === 0 && this.idInterval<0)
		{
			this.keepAlive();
			this.idInterval = setInterval(this.keepAlive, 60000);
		}
	}		
	
  callBackMenageLocalSMSI = (obj) =>
  {
	  if (obj.etat !== 0)
	  {
		  this.setState({etat:obj.etat, mess:obj.mess});
	  }
	  else
	  {
		  this.setState({suppressionSMSI: obj.data.suppressionSMSI.slice()}); 
	  }
  }
  
	
  render() {
	
	if (! this.state.finChargement)
	{
			return 	<Backdrop className="backdrop" open={true}>
						<CircularProgress color="inherit" />
					</Backdrop>
	}
	if (this.state.etat > 0)
			return <DiagError etat={this.state.etat} mess={this.state.mess} callback={this.callbackError}></DiagError>
	if (this.state.suppressionSMSI.length > 0)
	{
		return <DiagMenageLocalSMSI id={this.state.suppressionSMSI.length} nomM={this.state.suppressionSMSI[0].nomMission} nomC={this.state.suppressionSMSI[0].nomClient} callback={this.callBackMenageLocalSMSI}/>
	}
	if (this.state.finChargement)
	{	
		return (
			<HashRouter>
			{(process.env.REACT_APP_ENV !== 'prod') && <div style={{display:"grid", justifyContent:"center", background:"rgb(238 68 121 / 37%)"}}><h2>HORS PROD</h2></div>}
			<div id="page">
				<DiagError etat={this.state.etat} mess={this.state.mess} callback={this.callbackError}></DiagError>
				<BouttonMenu menuVisibility={this.state.menuVisible} changeMenuVisible={this.changeMenuVisible} />
					<Menu ref={this.menuRef} menuVisibility={this.state.menuVisible} changeMenuVisible={this.changeMenuVisible} />
					<div id="content">
						<Route path="/PapPerso" component={PapPerso}/>
						<Route path="/MissionsPerso" component={MissionsPerso}/>
						<Route path="/LesAV_Audit" component={AvantVente_Audit}/>
						<Route path="/LesAV_CERT" component={AvantVente_Cert}/>
						<Route path="/LesMissionsTrimestre_Audit" component={MissionsTrimestre_Audit}/>	
						<Route path="/ToutesLesMissions_Audit" component={LesMissions_Audit}/>			
						<Route path="/ToutesLesMissions_Cert" component={LesMissions_Cert}/>
						<Route path="/PDC_Audit" component={PDC_Audit}/>
						<Route path="/PDC_Cert" component={PDC_Cert}/>
						<Route path="/LesPAP" component={LesPAP}/>
						<Route path="/LaDreamTeam" component={DreamTeam}/>
						<Route path="/LesStats" component={LesStats}/>
						<Route path="/SMSI" component={LeSMSI}/>
						<Route path="/SMSI_2" component={LeSMSI2}/>
					</div>
					{(getUser().staffable==="true")?<Redirect to="/PapPerso" />:<Redirect to="/" />}
			</div>
			</HashRouter>
		)
	}
  }
}
 
export default Main;