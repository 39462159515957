import React, { Component } from "react";

import { getWeek, getYear, previousMonday, isMonday, add } from 'date-fns';

import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
var rappelGetUser = null;
export var userEnSession = null;
var token_header_xsrf = null;


export const bureauxWavestone = [{nom:"PARIS",id:1}, {nom:"NANTES",id:2},{nom:"SUISSE",id:4},{nom:"MARSEILLE",id:8}];


////////////////////////////
// set "REACT_APP_ENV=dev" && npm ....
//////////////////////////////


var URL_PAP_DEV = "http://localhost:8080/pap/"
var URL_PAP_PREPROD = "https://poolailler.wavestone-app.com/pap/pp/"
var URL_PAP_PROD = "https://poolailler.wavestone-app.com/pap/"

if (process.env.REACT_APP_ENV === 'prod')
	var URL_PAP = URL_PAP_PROD;
if (process.env.REACT_APP_ENV === 'preprod')
	var URL_PAP = URL_PAP_PREPROD;
if (process.env.REACT_APP_ENV === 'dev')
	var URL_PAP = URL_PAP_DEV;
////////////////////////////


String.prototype.sansAccent = function(){
    var accent = [
        /[\300-\306]/g, /[\340-\346]/g, // A, a
        /[\310-\313]/g, /[\350-\353]/g, // E, e
        /[\314-\317]/g, /[\354-\357]/g, // I, i
        /[\322-\330]/g, /[\362-\370]/g, // O, o
        /[\331-\334]/g, /[\371-\374]/g, // U, u
        /[\321]/g, /[\361]/g, // N, n
        /[\307]/g, /[\347]/g, // C, c
    ];
    var noaccent = ['A','a','E','e','I','i','O','o','U','u','N','n','C','c'];
     
    var str = this;
    for(var i = 0; i < accent.length; i++){
        str = str.replaceAll(accent[i], noaccent[i]);
    }
     
    return str;
}

String.prototype.sansEtCom = function(){

    var str = this;
    str = str.replaceAll("&", "%26");   
    str = str.replaceAll("+", "%2B");       
    return str;
}


function handleGetUserCallback (obj) 
{
	if (obj && obj.etat===0)
	{
		userEnSession = obj.data.user;
		rappelGetUser(obj);
	}
	else
	{
		rappelGetUser(obj);
	}
	return userEnSession;
} 

export function getUser (callback)
{
	if (userEnSession)
		return userEnSession;
	
	var urlUser = "ctrl=ControleurSMSI&methode=chiffrer";
	var urlUserOptions = {method: "GET", body:""};
	rappelGetUser = callback;
	appelAction(urlUser, urlUserOptions, handleGetUserCallback);	
}	
 
export function getAntiXSRF (callback)
{
	
	if (token_header_xsrf!=null)
		return;
		
	const requestOptions = {
        method: "GET",
		credentials: "include"
    };
	
	fetch(URL_PAP+'js/xsrf', requestOptions)
      .then(response => {
        if (response.ok) return response.json();
		else throw new Error("Reponse ko");
      })
      .then(data => { 	 token_header_xsrf=data.token; callback(0,"ok"); })
      .catch(error => { callback(4,"Token XSRF non recuperable (" + error +")") });

} 

export function appelAction (param, requestOptionsAppel, callback)
{
	//	alert("Appel Action " + url + " : "  + token_header_xsrf);
	
		const requestOptions = {
        method: requestOptionsAppel.method,
		credentials: "include",
        headers: { 'Content-Type':'application/x-www-form-urlencoded;charset=utf-8', "TOKEN_XSRF": token_header_xsrf  },
    };
	
	if (requestOptionsAppel.method==="POST")
		requestOptions.body = requestOptionsAppel.body;
	

	fetch(URL_PAP+"action/?"+param, requestOptions)
	.then(response => {
		if (response.ok) return response.json();
		else throw new Error("Reponse ko");
      })
      .then(data => { callback(data); })
      .catch(error => { callback({etat:4,mess:"Requete en erreur " + error}) });
	return;
}

export const NOMS_DES_MOIS = ["Janv.", "Fév.","Mars","Avril","Mai","Juin","Juil.","Aout","Sept.","Oct.","Nov.","Déc."];

export function affichageSemaine(semaineEnPlus)
{
	
	var event = add(new Date(),{weeks:semaineEnPlus});
	if (! isMonday(event))
		event = previousMonday(event);
	var affichage="";
	//alert("=> " + event);
	affichage += event.getDate()+"/"+(event.getMonth()+1);
	event = add(event,{days:4});
	//alert("to : " + event);
	
	affichage += " - " + event.getDate()+"/"+(event.getMonth()+1);
	
	return affichage;
	
}

export function getSemaineActuelle(semaineEnPlus)
{
	
	var event = add(new Date(),{weeks:semaineEnPlus});
	return {s:getWeek(event, {weekStartsOn: 1, firstWeekContainsDate: 4}), a:getYear(event)};
	
}


export class DiagError extends Component {


  constructor(props)
  {
		super(props);
		this.state = {isOpen : false};
  }
  
  componentDidUpdate(prevProps)
  {
/*
	if ( !this.isOpen && this.props.etat != 0)
	{
		this.refresh=1;
		this.setState({isOpen : true, mess: this.props.mess});
	}
  */
  }

  handleClose = () => {
    this.setState({isOpen : false});
  };
	
	render() {
   	var critik = "info";
	switch(this.props.etat)
	{
		case 1:  critik = "info"; break;
		case 2:  critik = "success"; break;
		case 3:  critik = "warning"; break;
		case 4:  critik = "error"; break;
		default: break;
	}
	let alerte;
	if (this.props.etat!==0)
		alerte= <Alert variant="filled" severity={critik}>{this.props.mess}</Alert>
		
    return (
	  <Dialog open={(this.props.etat!==0)} onClose={this.props.callback}> 
		  {alerte}
      </Dialog>
	  );
	};
}