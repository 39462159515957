import React, { Component } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

import GrillePAPAudit from "./GrillePAPAudit";
import GrillePAPCert from "./GrillePAPCert";

import { listeUtilisateurs } from './index.js';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="2 2 20 20"><path fill="${encodeURIComponent(
          '#FFF',
        )}" d="M 19 3 H 5 c -1.1 0 -2 0.9 -2 2 v 14 c 0 1.1 0.9 2 2 2 h 14 c 1.1 0 2 -0.9 2 -2 V 5 c 0 -1.1 -0.9 -2 -2 -2 Z M 9 17 H 7 v -7 h 2 v 7 Z m 4 0 h -2 V 7 h 2 v 10 Z m 4 0 h -2 v -4 h 2 v 4 Z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="2 2 20 20"><path fill="${encodeURIComponent(
        '#FFF',
      )}" d="M 19.48 12.35 c -1.57 -4.08 -7.16 -4.3 -5.81 -10.23 c 0.1 -0.44 -0.37 -0.78 -0.75 -0.55 C 9.29 3.71 6.68 8 8.87 13.62 c 0.18 0.46 -0.36 0.89 -0.75 0.59 c -1.81 -1.37 -2 -3.34 -1.84 -4.75 c 0.06 -0.52 -0.62 -0.77 -0.91 -0.34 C 4.69 10.16 4 11.84 4 14.37 c 0.38 5.6 5.11 7.32 6.81 7.54 c 2.43 0.31 5.06 -0.14 6.95 -1.87 c 2.08 -1.93 2.84 -5.01 1.72 -7.69 Z m -9.28 5.03 c 1.44 -0.35 2.18 -1.39 2.38 -2.31 c 0.33 -1.43 -0.96 -2.83 -0.09 -5.09 c 0.33 1.87 3.27 3.04 3.27 5.08 c 0.08 2.53 -2.66 4.7 -5.56 2.32 Z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: .5,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

class LesPAP extends Component
{

	constructor(props)
	{
		super(props);
		this.state={user:null};
		this.bindedHandleChoixUser = this.handleChoixUser.bind(this);
		this.bindedCertAuditChange = this.handleCertAuditChange.bind(this);

	};
	
	handleChoixUser(e, v)
	{
			
		var swap = 1;
		if ( v && (v.profilBT&2)>0)
			swap = 2;

		this.setState({user: v, affichageGrille: true, swap: swap});
	
	}
	
	handleCertAuditChange(e, v)
	{
		if (v)
			this.setState({swap:1});
		else
			this.setState({swap:2});
	}
	

	
	render()
	{
	 
		return (
		<React.Fragment>
		<h2> Les PAP :</h2>
		<div style={{width:"300px", padding:"0 0 20px 20px"}}>
		<Autocomplete disableClearable 
			id="LesPAP_selection"
			//filterSelectedOptions 
			options={listeUtilisateurs.filter (u => u.staffable === "true")}
			autoSelect
			renderOption={(props, option) => {
						if (!option || !option.id)
							return <li></li>;
						if (option.profil.indexOf("STAGIAIRE") >= 0)
								return <li {...props} key={option.id}><i>{option.prenom + " " + option.nom + " ("+option.trigramme+")"}</i></li>
						
						return (
								<li {...props} key={option.id} >
									{option.prenom + " " + option.nom + " ("+option.trigramme+")"}
								</li>
								);
							}}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			onChange={this.bindedHandleChoixUser}
			getOptionLabel={ (utilisateur) => utilisateur?utilisateur.prenom + " " + utilisateur.nom:"" }
			value={this.state.user || null}
			renderInput={(params) => <TextField {...params} id="LesPAP_selection_option" label="PAP de ..." /> }			
			/>
		{this.state.user && ""+this.state.user.update}
		</div>
		
			{this.state.affichageGrille&&this.state.user.profilBT===3&&
			<FormControlLabel sx = {{ pl:5, pt:1}}
					control={<MaterialUISwitch sx={{ m: 1}} onChange={this.bindedCertAuditChange} />}
					label={(this.state.swap===1)?"Audit":"Cert"}
				/>}
	
			{this.state.affichageGrille&&(this.state.swap===1)&&<GrillePAPAudit idU={this.state.user.id} />}
			{this.state.affichageGrille&&(this.state.swap===2)&&<GrillePAPCert idU={this.state.user.id} />}
		
		
		</React.Fragment>
		);
	}
}
 
export default LesPAP;
