import React, { Component } from "react";
import moment from 'moment';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DatePicker from '@mui/lab/DatePicker';
import frLocale from "date-fns/locale/fr";
import DialogTitle from '@mui/material/DialogTitle';
import InputBase from '@mui/material/InputBase';
import CheckIcon from '@mui/icons-material/Check';
import Badge from '@mui/material/Badge';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import WarningIcon from '@mui/icons-material/Warning';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SyncIcon from '@mui/icons-material/Sync';
import VisibilityIcon from '@mui/icons-material/Visibility';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Backdrop from '@mui/material/Backdrop';
import Tooltip2 from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';

import CRaudit from './CRaudit.js';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import DeleteIcon from '@mui/icons-material/Delete';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import AdapterDateFns from '@date-io/date-fns';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import {fr} from 'date-fns/locale';
import dateFnsFormat from 'date-fns/format';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/fr';

import BouttonsRoue from './BouttonsRoue.js';
import { appelAction, DiagError, userEnSession, bureauxWavestone} from './utils.js';
import { listeUtilisateurs } from './index.js';
import { SemainesAnneesPourAffichage, SemainesAnnees } from './index.js';

import "./PDC_Audit.css";
import "./utils.css";
import './style_datepicker.css';


ChartJS.register(
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Legend,
  Tooltip
);


// debut de la classe d'une ligne de PDC


class Row extends Component
{
	
	ProfilsWavestone = ["", "CP", "X", "E"];
	ListeStaffable = listeUtilisateurs;
	
	updateData(data)
	{
		this.setState({idLigne: this.props.row.idLigne, debLancement:this.props.row.debLancement, finLancement:this.props.row.finLancement, 
					debRealisation:this.props.row.debRealisation, finRealisation:this.props.row.finRealisation, commentaires: this.props.row.commentaires, opened: this.props.visible, porteurPropale : this.props.row.porteurPropale,
					nomClient:this.props.row.nomClient, nomMission:this.props.row.nomMission, priorite:this.props.row.priorite,
					lignesStaffing: this.props.row.lignesStaffing, preStaffing: this.props.row.preStaffing, notifications: this.props.row.notifications, nbLignesOriginelles : this.props.row.lignesStaffing.length, joursAFaire:0, joursFait:0, ventilation:this.props.row.ventilation,
					mission: this.props.row.mission
		});
		
		this.titrePorteurs = this.props.row.porteurPropale && this.props.row.porteurPropale.length>0 
			&& this.props.row.porteurPropale.map(p => { 
										var v = listeUtilisateurs.find( u => u.id === p) || {prenom:"",nom:""}; 
										return v.prenom + " " + v.nom; } ).toString();
		this.modifieur = this.ListeStaffable.find(u => u.id === this.props.row.modifieur) || {prenom:"",nom:""};
	}
	
	

	constructor(props)
	{
		super(props);

		var date = new Date();
		
		this.state={opened:false, bureauxVisibles: userEnSession.bureau, 
					validCom : false, changementsFaitsRoleAuditeur: false, aDetruire: false, 
					ouvrirConfirmationChangementAuditeur: false, ouvrirConfirmationSuppressionLigne: false, ouvrirConfirmationSuppressionUser: false,
					roueVisible:false, roueVisibleX: 0, roueVisibleY: 0, roueSSLigne : 0, roueSemaine: 0,
					selectionDatePopUp: false, dateMoisFin:(date.getMonth()+1), dateAnneeFin: date.getFullYear(),
					};
		
		this.ListeStaffable = listeUtilisateurs.filter(u => u.staffable === "true" && (u.bureau&userEnSession.bureau)>0);
		this.oldAuditeur = {nom:"",prenom:"",id:-1};
		this.newAuditeur = {nom:"",prenom:"",id:-1};
		
		this.bindFromChange1 = this.handleFromChange1.bind(this);
		this.bindToChange1 = this.handleToChange1.bind(this);
		this.bindFromChange2 = this.handleFromChange2.bind(this);
		this.bindToChange2 = this.handleToChange2.bind(this);
		this.bindResetClick1 = this.handleResetClick1.bind(this);
		this.bindResetClick2 = this.handleResetClick2.bind(this);
		
		
		this.bindedComChange = this.handleComChange.bind(this);
		this.bindedComClick = this.handleComClick.bind(this);
		
		this.bindedPrioClick = this.handlePrioClick.bind(this);
		
		this.bindedNotificationClick = this.handleNotificationClick.bind(this);
		
		this.bindedTextFieldRoleChangeDejaStaffe = this.handleTextFieldRoleChangeDejaStaffe.bind(this);
		this.bindedTextFieldAuditeurChangeDejaStaffe = this.handleTextFieldAuditeurChangeDejaStaffe.bind(this);
		this.bindedStaffingChangeDejaStaffe = this.handleStaffingChangeDejaStaffe.bind(this);
		
		this.bindedTextFieldRoleChangePreStaff = this.handleTextFieldRoleChangePreStaff.bind(this);
		this.bindedTextFieldAuditeurChangePreStaff = this.handleTextFieldAuditeurChangePreStaff.bind(this);
		this.bindedStaffingChangePreStaff = this.handleStaffingChangePreStaff.bind(this);
		
		this.bindedClickAjouterLigneStaffing = this.handleAjouterLigneClick.bind(this);
		this.bindedClickStaffer = this.handleClickStaffer.bind(this);
		this.bindedClickValidePreStaff = this.handleClickValidePreStaff.bind(this);
		
		
		this.bindedClickStafferPopUp = this.handleClickStafferPopUp.bind(this);
		this.bindedFermerStafferPopUp = this.handleFermerStafferPopUp.bind(this);
		this.bindedChangeDateFin = this.handleChangeDateFin.bind(this);
		
		this.bindDeletePreStaffing = this.handleDeletePreStaffing.bind(this);
		
		this.bindDeletePDC = this.handleDeletePDC.bind(this);
		this.modifieur = {prenom:"",nom:""};
		
		this.leStartStopDeRefresh = this.props.leStartStopDeRefresh;
		
		
		
	}
	
	componentDidMount()
	{
		this.updateData();
	}
	
	componentDidUpdate(prevProps) 
	{
		
		var doitRaffraichir = false;
		var props = this.props.row;
		Object.keys(this.props.row).forEach(
			function (key)
			{
				if (!doitRaffraichir && props[key] !== prevProps.row[key])
				{
					doitRaffraichir = true;
				}
			});	
		if (doitRaffraichir)
		{
			this.updateData();
		}
	}
	
	
	handleChangeDateFin(e)
	{
	  var fin = new Date(e);
	  this.setState({dateMoisFin: (fin.getMonth()+1), dateAnneeFin: fin.getFullYear()})
	}
	
	handleFermerStafferPopUp()
	{
		this.setState({selectionDatePopUp:false});
	}
	
	
	handleDeletePDC(e)
	{
		this.leStartStopDeRefresh(false);
		this.setState({ ouvrirConfirmationSuppressionLigne: true});
	}
	
	handleNotificationClick(e)
	{
		var u = parseInt(e.currentTarget.dataset.user);
		var urlPDC = "ctrl=ControleurPDCAudit&methode=changer";
		var requestPDC = {method: "POST", body:""};
		requestPDC.body="idPDC="+this.state.idLigne+"&idUser2Notif="+u;
		
		var notifs=[];
		if (this.state.notifications.includes(u))
		{
			for (var k=0;k<this.state.notifications.length;k++)
				if (u!==this.state.notifications[k])
					notifs.push(this.state.notifications[k]);
		}
		else
		{
			notifs = this.state.notifications.slice();
			notifs.push(u);
		}
		
		this.setState({notifications:notifs});
		appelAction(urlPDC, requestPDC, this.props.callBack);	
	}
	
	
	handleClickStafferPopUp(e)
	{
		var urlPDC = "ctrl=ControleurPDCAudit&methode=creer";
		var requestPDC = {method: "POST", body:""};
		requestPDC.body="idPDC="+this.state.idLigne;
		
		var indice=0;
		var idAuditeurs="";
		var roles="";
		var previsions="";
		// on commence tjs par "_"
		for(indice=0;indice<(this.state.preStaffing.length);indice++)
		{
			idAuditeurs+="_"+this.state.preStaffing[indice].idU;
			roles+="_"+this.state.preStaffing[indice].role;
			previsions+="_"+this.state.preStaffing[indice].staffing[0].v+"_";
			previsions+=this.state.preStaffing[indice].staffing[1].v+"_";
			previsions+=this.state.preStaffing[indice].staffing[2].v+"_";
			previsions+=this.state.preStaffing[indice].staffing[3].v+"_";
			previsions+=this.state.preStaffing[indice].staffing[4].v+"_";
		}
		
		requestPDC.body+="&idA="+idAuditeurs;
		requestPDC.body+="&roles="+roles;
		requestPDC.body+="&prev="+previsions;
		requestPDC.body+="&finMois="+this.state.dateMoisFin + "&finAnnee="+this.state.dateAnneeFin;
		
		this.props.callbackWait();
		appelAction(urlPDC, requestPDC, this.props.callBack);	
		this.setState({changementsFaitsRoleAuditeur: false, selectionDatePopUp:false});
	}
	
	
	// changement de role d'une ligne deja staffee 
	handleTextFieldRoleChangeDejaStaffe (e, value)
	{
		// on met en pause le raffraichissement sinon ça va tuer le travail en cours !
		this.leStartStopDeRefresh(false);
	
		var ee;
		if (e.target.id)
			ee = e.target.id;
		else // si bouton "clear"
		{
			ee = e.target.parentElement.parentElement.parentElement.parentElement.firstChild;
			ee = (ee.id)?ee.id:ee.firstChild.id;
		}		
		var indice = parseInt(ee.substring(ee.indexOf("_")+1, (ee.indexOf("-")>0)?ee.indexOf("-"):100 ));
		
		var lignes = this.state.lignesStaffing.slice();
		var auditeur = lignes[indice].id;
		lignes[indice].role=value?value:"";
		this.setState({lignesStaffing : lignes, changementsFaitsRoleAuditeur: true});
		
		// si c'est une mission en cours, on regarde si nouvelle ligne ou pas 
		// si non => envoi le changement au serveur
		// si oui => on va attendre l'auditeur
	if (this.state.mission)
		{
			if (indice>this.state.nbLignesOriginelles-1)
			{	
				// alert("TODO : nouvelle ligne, on attend");
				// on ne fait donc rien ;-)
			}
			else
			{
				if (value===null)
				{
					this.oldAuditeur = listeUtilisateurs.find(u => u.id === auditeur);
					this.setState({ouvrirConfirmationSuppressionUser : true})
				}
				else {alert("TODO : on change le profil ?" + auditeur + " " + value);}
			}
		}
		
	}
	
	
	// changement de role d'une ligne pistee 
	handleTextFieldRoleChangePreStaff (e, value)
	{
		// on met en pause le raffraichissement sinon ça va tuer le travail en cours !
		this.leStartStopDeRefresh(false);
	
		var ee;
		if (e.target.id)
			ee = e.target.id;
		else // si bouton "clear"
		{
			ee = e.target.parentElement.parentElement.parentElement.parentElement.firstChild;
			ee = (ee.id)?ee.id:ee.firstChild.id;
		}		
		var indice = parseInt(ee.substring(ee.indexOf("_")+1, (ee.indexOf("-")>0)?ee.indexOf("-"):100 ));
		
		var lignes = this.state.preStaffing.slice();
		var auditeur = lignes[indice].idU;
		lignes[indice].role=value?value:"";
		lignes[indice].nouvelleLigne = true;
		this.setState({preStaffing : lignes, changementsFaitsRoleAuditeur: true});
	}
	
	
		
	// changement d auditeur d'une ligne pistee 
	handleTextFieldAuditeurChangePreStaff (e, value)
	{
		// on met en pause le raffraichissement sinon ça va tuer le travail en cours !
		this.leStartStopDeRefresh(false);
	
		var ee;
		if (e.target.id)
			ee = e.target.id;
		else // si bouton "clear"
			ee = e.target.parentElement.parentElement.parentElement.parentElement.firstChild.id;
		
		var indice = parseInt(ee.substring(ee.indexOf("_")+1, (ee.indexOf("-")>0)?ee.indexOf("-"):100 ));
		var lignes = this.state.preStaffing.slice();	
					
	    lignes[indice].idU=value?value.id:-1;
		lignes[indice].nouvelleLigne = true;
	    this.setState({preStaffing : lignes, changementsFaitsRoleAuditeur: true});
	}
	
	
	
	handleValideChangement = () => 
	{		
		var urlPDC = "ctrl=ControleurPDCAudit&methode=modifier";
		var requestPDC = {method: "POST", body:""};
		requestPDC.body="idPDC="+this.state.idLigne;				
		requestPDC.body+="&idA="+this.oldAuditeur.id;
		requestPDC.body+="&idN="+this.newAuditeur.id;
		
		this.props.callbackWait();		
		appelAction(urlPDC, requestPDC, this.props.callBack);	
		
		this.setState({ouvrirConfirmationChangementAuditeur : false})
	}
	
	handleAnnuleChangement = () => 
	{	
		this.newAuditeur = this.oldAuditeur;
		this.setState({ouvrirConfirmationChangementAuditeur : false})
		this.leStartStopDeRefresh(true);
	}
	
	handleAnnuleSuppressionUser = () => 
	{	
		this.setState({ouvrirConfirmationSuppressionUser : false});
		this.leStartStopDeRefresh(true);
	}
	
	handleValideSuppressionUser = () => 
	{		
		var urlPDC = "ctrl=ControleurPDCAudit&methode=supprimer";
		var requestPDC = {method: "POST", body:""};
		requestPDC.body="idPDC="+this.state.idLigne;
		requestPDC.body+="&idA="+this.oldAuditeur.id;
		this.props.callbackWait();
		appelAction(urlPDC, requestPDC, this.props.callBack);
		this.setState({ouvrirConfirmationSuppressionUser : false});
	}
	
	handleValideSuppression = () => 
	{		
		var urlPDC = "ctrl=ControleurPDCAudit&methode=supprimer";
		var requestPDC = {method: "POST", body:""};
		requestPDC.body="idPDC="+this.state.idLigne;
		this.props.callbackWait();
		appelAction(urlPDC, requestPDC, this.props.callBack);
		this.setState({ouvrirConfirmationSuppressionLigne : false, aDetruire : true});
	}
	
	handleAnnuleSuppression = () => 
	{	
		this.setState({ouvrirConfirmationSuppressionLigne : false});
		this.leStartStopDeRefresh(true);
	}
		
	
	// si changement d'auditeur sur une ligne deja staffee
	handleTextFieldAuditeurChangeDejaStaffe (e, value)
	{
		// on met en pause le raffraichissement sinon ça va tuer le travail en cours !
		this.leStartStopDeRefresh(false);
	
		var ee;
		if (e.target.id)
			ee = e.target.id;
		else // si bouton "clear"
			ee = e.target.parentElement.parentElement.parentElement.parentElement.firstChild.id;
		
		var indice = parseInt(ee.substring(ee.indexOf("_")+1, (ee.indexOf("-")>0)?ee.indexOf("-"):100 ));
	 
		var lignes = this.state.lignesStaffing.slice();	
		var exAuditeur = lignes[indice].id<0?this.oldAuditeur:lignes[indice];
		
		// si c'est une mission en cours, on envoi le changement au serveur
		if (this.state.mission)
		{
			if (indice>this.state.nbLignesOriginelles-1)
			{	
				// alert("TODO : nouvelle ligne, on attend tjs");
				// donc en fait on fait rien
			}
			else
			{
				// alert("TODO : on change l auditeur ? " + exAuditeur + " " + value.id);
				if (!value)
					return; // on a fait la x => user vide
				
				this.oldAuditeur = listeUtilisateurs.find(u => u.id === exAuditeur.id);
				this.newAuditeur = value;
				
				this.setState({ouvrirConfirmationChangementAuditeur : true})
				
				return; // pour eviter le changement => true 
			}
		}
		
	   lignes[indice].id=value?value.id:-1;
	   this.setState({lignesStaffing : lignes, changementsFaitsRoleAuditeur: true});
	
	
	}
	
	handleClickStaffer (e)
	{
		
		if (this.state.mission)
		{
			// mission existante, on a fait "+", on va rajouter les nouveaux auditeurs
			// on parcours les prestaffing 
			
			var urlPDC = "ctrl=ControleurPDCAudit&methode=staffer";
			var requestPDC = {method: "POST", body:""};
			requestPDC.body="idPDC="+this.state.idLigne;
		
			//var indice=this.state.nbLignesOriginelles;
			var indice=0;
			var idAuditeurs="";
			var roles="";
			var previsions="";
			// on commence tjs par "_"
			//for(indice;indice<this.state.lignesStaffing.length;indice++)
			for (indice=0;indice<(this.state.preStaffing.length);indice++)
			{
				/*
				idAudieurs+="_"+this.state.lignesStaffing[indice].id;
				roles+="_"+this.state.lignesStaffing[indice].role;
				previsions+="_"+this.state.lignesStaffing[indice].staffing[0].v+"_";
				previsions+=this.state.lignesStaffing[indice].staffing[1].v+"_";
				previsions+=this.state.lignesStaffing[indice].staffing[2].v+"_";
				previsions+=this.state.lignesStaffing[indice].staffing[3].v+"_";
				previsions+=this.state.lignesStaffing[indice].staffing[4].v+"_";
				*/
				idAuditeurs+="_"+this.state.preStaffing[indice].idU;
				roles+="_"+this.state.preStaffing[indice].role;
				previsions+="_"+this.state.preStaffing[indice].staffing[0].v+"_";
				previsions+=this.state.preStaffing[indice].staffing[1].v+"_";
				previsions+=this.state.preStaffing[indice].staffing[2].v+"_";
				previsions+=this.state.preStaffing[indice].staffing[3].v+"_";
				previsions+=this.state.preStaffing[indice].staffing[4].v+"_";
				
				
			}
		
			requestPDC.body+="&idA="+idAuditeurs;
			requestPDC.body+="&roles="+roles;
			requestPDC.body+="&prev="+previsions;
			
			
			this.props.callbackWait();
			appelAction(urlPDC, requestPDC, this.props.callBack);	
					
			this.setState({changementsFaitsRoleAuditeur: false});
		}
		else
		{
			this.setState({selectionDatePopUp: true});
		}
	}
	
	handleClickValidePreStaff (e)
	{
		var indice = parseInt(e.currentTarget.id.substring(e.currentTarget.id.indexOf("_")+1,100));

		var urlPDC = "ctrl=ControleurPDCAudit&methode=preStaffer";
		var requestPDC = {method: "POST", body:""};
		requestPDC.body="idPDC="+this.state.idLigne;
		
		//var indice=0;
		var idAuditeurs="";
		var roles="";
		var previsions="";
		// on commence tjs par "_"
		
		requestPDC.body+="&idA="+this.state.preStaffing[indice].idU;
		//alert(this.state.preStaffing[indice].idU);
		requestPDC.body+="&roles="+this.state.preStaffing[indice].role;
		requestPDC.body+="&prev="+this.state.preStaffing[indice].staffing[0].v+"_"+this.state.preStaffing[indice].staffing[1].v+"_"+this.state.preStaffing[indice].staffing[2].v+"_"+this.state.preStaffing[indice].staffing[3].v+"_"+this.state.preStaffing[indice].staffing[4].v;

		this.props.callbackWait();
		appelAction(urlPDC, requestPDC, this.props.callBack);	
		this.setState({changementsFaitsRoleAuditeur: false});
		
	 
	}
	
	handleDeletePreStaffing (e)
	{
		
		var indice = parseInt(e.currentTarget.id.substring(e.currentTarget.id.indexOf("_")+1,100));

		var urlPDC = "ctrl=ControleurPDCAudit&methode=preStaffer&action=del";
		var requestPDC = {method: "POST", body:""};
		requestPDC.body="idPDC="+this.state.idLigne;
		requestPDC.body+="&idA="+this.state.preStaffing[indice].idU;
		
		var onEnvoi=true;
		// pas besoin de lancer l'appel au serveur, c'est pas save
		if (this.state.preStaffing[indice].nouvelleLigne)
			onEnvoi=false;
		
		var preStaff = this.state.preStaffing.toSpliced(indice,1);
	
		this.setState({preStaffing : preStaff});
		if (!onEnvoi)
			return;
		
		//this.props.callbackWait();
		appelAction(urlPDC, requestPDC, this.props.callBack);
	}
	
	
	// on a clické sur "+" on rajoute une ligne de prestaffing
	handleAjouterLigneClick (e) 
	{
		var staff = []; 
	//	var lignes = this.state.lignesStaffing.slice();
		var lignes = this.state.preStaffing.slice();
		for(var j=0; j<5;j++)
		{ 
			staff.push({s:SemainesAnnees[j].s, a:SemainesAnnees[j].a, v:0});
		}			
		lignes.push({idU:-1, role:"", staffing:staff, nouvelleLigne:true});
	
		//this.setState({lignesStaffing : lignes});
		this.setState({preStaffing : lignes});
		
		// et on met en pause le raffraichissement sinon ça va tuer le travail en cours !
		this.leStartStopDeRefresh(false);
		
		/*
		if (this.state.mission)
		{
			this.setState({changementsFaitsRoleAuditeur: true});
		}
		*/
	}
	/*
	showFromMonth1()
	{
		var from = this.state.debLancement;
		var to = this.state.finLancement;
		if (!from) 
			return;
	
		if (moment(to).diff(moment(from), 'months') < 2)
		{
			this.to1.getDayPicker().showMonth1(from);
		}
	}
*/
	
	handleFromChange1(from) {
		var d = moment(from).format("YYYY/MM/DD")
		this.setState({ debLancement : d });
	}

	handleToChange1(to) {
		var f = moment(to).format("YYYY/MM/DD")
		this.setState({ finLancement : f });
		
		var urlPDC = "ctrl=ControleurPDCAudit&methode=changer";
		var requestPDC = {method: "POST", body:""};
		requestPDC.body="idPDC="+this.state.idLigne+"&debL="+(moment(this.state.debLancement,"YYYY/MM/DD").valueOf())+"&finL="+(moment(f,"YYYY/MM/DD").valueOf());
		appelAction(urlPDC, requestPDC, this.props.callBack);	
	}
	

	handleResetClick1()
	{
		this.setState({ debLancement: undefined, finLancement: undefined });
		var urlPDC = "ctrl=ControleurPDCAudit&methode=changer";
		var requestPDC = {method: "POST", body:""};
		requestPDC.body="idPDC="+this.state.idLigne+"&debL=false&finL=false";
		appelAction(urlPDC, requestPDC, this.props.callBack);	
	}
	
	/*
	showFromMonth2()
	{
		var from = this.state.debRealisation;
		var to = this.state.finRealisation;
		if (!from) 
			return;
	
		if (moment(to).diff(moment(from), 'months') < 2)
		{
			this.to2.getDayPicker().showMonth(from);
		}
	}
	*/

	handleFromChange2(from) {
    // Change the from date and focus the "to" input field
		var d = moment(from).format("YYYY/MM/DD")
		this.setState({ debRealisation : d });
	}
	
	
	handleToChange2(to) {
		var f = moment(to).format("YYYY/MM/DD")
		this.setState({ finRealisation : f } );		
		var urlPDC = "ctrl=ControleurPDCAudit&methode=changer";
		var requestPDC = {method: "POST", body:""};
		requestPDC.body="idPDC="+this.state.idLigne+"&debR="+(moment(this.state.debRealisation,"YYYY/MM/DD").valueOf())+"&finR="+(moment(f,"YYYY/MM/DD").valueOf());
		appelAction(urlPDC, requestPDC, this.props.callBack);	
	}


	handleResetClick2()
	{
		this.setState({ debRealisation: undefined, finRealisation: undefined });
		var urlPDC = "ctrl=ControleurPDCAudit&methode=changer";
		var requestPDC = {method: "POST", body:""};
		requestPDC.body="idPDC="+this.state.idLigne+"&debR=false&finR=false";
		appelAction(urlPDC, requestPDC, this.props.callBack);	
	}

	formatDate(date, format, locale)
	{
		return dateFnsFormat(date, format, "fr" );
	}
	
	// on affiche la roue pour les jours d'une ligne deja staffee
	handleStaffingChangeDejaStaffe(e)
	{
		if (! this.state.lignesStaffing[e.target.dataset.ssligne].staffing[e.target.dataset.semaine])
		{
			alert("nnooo!!!" + e.target.dataset.semaine);
			return;
		}
		this.setState({roueVisible:true, roueVisibleX: e.clientX, roueVisibleY: e.clientY, 
			roueSSLigne : e.target.dataset.ssligne, roueSemaine: e.target.dataset.semaine });
		
	}
	
		// on affiche la roue pour les jours d'une ligne pistee
	handleStaffingChangePreStaff(e)
	{
		
	}
	
	handleSelectionJoursAFaire = (val) =>
	{
		
		this.setState({roueVisible:false});
				
		var valeur = val.replace(",",".")*2;
		var newA = this.state.lignesStaffing.slice();
		newA[this.state.roueSSLigne].staffing[this.state.roueSemaine].v = valeur;
		this.setState({lignesStaffing: newA});
		
		// si c'est une mission en cours et pas une nouvelle ligne, on envoi le changement au serveur
		
		if (this.state.mission && ! this.state.lignesStaffing[this.state.roueSSLigne].nouvelleLigne)
		{
			var urlPDC = "ctrl=ControleurPDCAudit&methode=chiffrer";
			var requestPDC = {method: "POST", body:""};
			requestPDC.body="idPDC="+this.state.idLigne+"&idA="+this.state.lignesStaffing[this.state.roueSSLigne].id+"&s="+this.state.roueSemaine+"&prev="+valeur;
			appelAction(urlPDC, requestPDC, this.props.callBack);	
		}
		
	}
	
	handleMasqueRoue = (e) =>
	{
		this.setState({roueVisible:false});
	}
	
	handleComChange(e)
	{	
		// et on met en pause le raffraichissement sinon ça va tuer le travail en cours !
		this.leStartStopDeRefresh(false);
		this.setState({commentaires : e.target.value, validCom:true});
	}
	
	handleClickCallbackCom = (obj) =>
	{		
		if (obj.etat===0)
			this.setState({validCom:false});
		else
			this.props.callBack(obj)
		
		// et on relance le raffraichissement !
		this.leStartStopDeRefresh(true);
			
	}
	
	handleComClick()
	{
		// on appelle le PDC pour le changement
		
		var urlPDC = "ctrl=ControleurPDCAudit&methode=changer";
		var requestPDC = {method: "POST", body:""};
		requestPDC.body="idPDC="+this.state.idLigne+"&com="+this.state.commentaires.sansEtCom();
	
		appelAction(urlPDC, requestPDC, this.handleClickCallbackCom);
			
	}
	
	handlePrioClick()
	{
		var urlPDC = "ctrl=ControleurPDCAudit&methode=changer";
		var requestPDC = {method: "POST", body:""};
		requestPDC.body="idPDC="+this.state.idLigne+"&prio="+this.state.priorite;
		this.setState({priorite: ((this.state.priorite+1)%5)});
		appelAction(urlPDC, requestPDC, this.props.callBack);
	}
	

render ()
{
	if (this.state.aDetruire)
		return;
	/* date de lancement, deb et fin */
	const d1 = moment(this.state.debLancement,"YYYY/MM/DD");
	const from1 = d1.isValid() ? new Date(d1) : "";
	const f1 = moment(this.state.finLancement,"YYYY/MM/DD");
	const to1 = f1.isValid() ? new Date(f1) : "";
    const modifiers1 = { start: from1, end: to1 };
	
	/* date de realisation, deb et fin */
	const d2 = moment(this.state.debRealisation,"YYYY/MM/DD");
	const from2 = d2.isValid() ? new Date(d2) : "";
	const f2 = moment(this.state.finRealisation,"YYYY/MM/DD");
	const to2 = f2.isValid() ? new Date(f2) : "";
    const modifiers2 = { start: from2, end: to2 };

	var ssLigne = -1;
	var ssLignePreStaff = -1;
	var trucAStaffer = false;
	
	return (
        <React.Fragment>

		  <Dialog
				open={this.state.ouvrirConfirmationChangementAuditeur}
				onClose={this.handleAnnuleChangement}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
			<DialogTitle id="alert-dialog-title">
				{"Validation du changement ?"}
			</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
		  Valider le changement de { this.oldAuditeur.prenom + " " + this.oldAuditeur.nom} vers { this.newAuditeur.prenom + " " + this.newAuditeur.nom}  sur la mission {this.state.nomClient + " " + this.state.nomMission} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleAnnuleChangement}>Non</Button>
          <Button onClick={this.handleValideChangement} autoFocus>Oui !</Button>
        </DialogActions>
      </Dialog>
	  
	  <Dialog
				open={this.state.ouvrirConfirmationSuppressionLigne}
				onClose={this.handleAnnuleSuppression}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
			<DialogTitle id="alert-dialog-title">
				{"Validation de la suppression ?"}
			</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
		  La ligne {this.state.nomClient + " " + this.state.nomMission} sera supprimée de la vue PDC (la mission reste présente et elle peut être basculée à nouveau dans le PDC via la vue Missions/Trimestres ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleAnnuleSuppression}>Non</Button>
          <Button onClick={this.handleValideSuppression} autoFocus>Oui !</Button>
        </DialogActions>
      </Dialog>
	
		<Dialog
				open={this.state.ouvrirConfirmationSuppressionUser}
				onClose={this.handleAnnuleSuppressionUser}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
			<DialogTitle id="alert-dialog-title">
				{"Validation de la suppression ?"}
			</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
		  Valider la suppression de { this.oldAuditeur.prenom + " " + this.oldAuditeur.nom} sur la mission {this.state.nomClient + " " + this.state.nomMission} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleAnnuleSuppressionUser}>Non</Button>
          <Button onClick={this.handleValideSuppressionUser} autoFocus>Oui !</Button>
        </DialogActions>
      </Dialog>
		
		  <TableRow className="ligne_PDC">
			<TableCell className="case_ligne_PDC">
			
			  <IconButton aria-label="expand row" size="small" onClick={() => { this.setState({opened : !this.state.opened}); this.props.changementAffichage(this.state.idLigne); } }>
				{this.state.opened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
			  </IconButton>
			  
	  		  <BouttonsRoue roueVisibleX={this.state.roueVisibleX} roueVisibleY={this.state.roueVisibleY} visible={this.state.roueVisible} selectionJours={this.handleSelectionJoursAFaire} masquerRoue={this.handleMasqueRoue} />
			  
			</TableCell>
			<TableCell className="case_ligne_PDC_prio" align="center">
			<Badge badgeContent={this.state.notifications?this.state.notifications.length:0} color="primary">
				{this.state.priorite===0&&<ThumbUpAltIcon onClick={this.bindedPrioClick}/>}
				{this.state.priorite===1&&<HourglassBottomIcon onClick={this.bindedPrioClick}/>}
				{this.state.priorite===2&&<QuestionMarkIcon onClick={this.bindedPrioClick}/>}
				{this.state.priorite===3&&<ManageAccountsIcon onClick={this.bindedPrioClick}/>}		
				{this.state.priorite===4&&<PriorityHighIcon onClick={this.bindedPrioClick}/>}			
			</Badge>	
			</TableCell>
			<TableCell className="case_ligne_PDC" align="left">{this.state.nomClient}</TableCell>
			<TableCell className="case_ligne_PDC" align="left">
				{this.state.nomMission}&nbsp;&nbsp;&nbsp;
				<Tooltip2 title={"Dernière modif. : " + this.props.row.dateModif + " / " + this.modifieur.prenom + " " + this.modifieur.nom}>
						<SyncIcon sx={{ fontSize: 18 }} />
					</Tooltip2>	
					
				{this.state.preStaffing&&this.state.preStaffing.length>0&&
					<Tooltip2 title={"Non staffé : " + this.state.preStaffing.length}>
						<WarningIcon sx={{ ml:2, fontSize: 20 }} />
					</Tooltip2>
				}					
			</TableCell>
			<TableCell className="case_ligne_PDC" align="left">
			<div className="InputFromTo">
			<DayPickerInput
				value={from1}
				placeholder="De"
			    formatDate={this.formatDate}
				format="dd/MM/yyyy"		
				dayPickerProps={{
					selectedDays: [from1, { from : from1, to : to1 }],
					toMonth: to1,
					localeUtils:MomentLocaleUtils,
					locale:"fr",
					modifiers1,
					numberOfMonths: 1,
					onDayClick: () => setTimeout(() => this.to1.getInput().focus(),400),
				}}
				onDayPickerShow={() => this.leStartStopDeRefresh(false)}
				onDayPickerHide={() =>this.leStartStopDeRefresh(true)}
				onDayChange={this.bindFromChange1}
			/>
			—
			<span className="InputFromTo-to">
			<DayPickerInput
				ref={el => (this.to1 = el)}
				value={to1}
				placeholder="A"
				formatDate={this.formatDate}
				format="dd/MM/yyyy"
				dayPickerProps={{
				  selectedDays: [ from1, { from : from1, to : to1 }],
				  disabledDays: { before: from1 },
				  localeUtils:MomentLocaleUtils,
				  locale:"fr",
				  modifiers1,
				  month: from1,
				  fromMonth: from1,
				  numberOfMonths: 1,
				}}
				onDayPickerShow={() => this.leStartStopDeRefresh(false)}
				onDayPickerHide={() => this.leStartStopDeRefresh(true)}
				onDayChange={this.bindToChange1}
          />
			</span>
			<IconButton aria-label="delete" onClick={this.bindResetClick1} size="large">
				<DeleteIcon fontSize="small"  />
			</IconButton>
			</div>	
			</TableCell>
			<TableCell className="case_ligne_PDC" align="left">
			<div className="InputFromTo">
			<DayPickerInput
				value={from2}
				placeholder="De"
			    formatDate={this.formatDate}
				format="dd/MM/yyyy"		
				dayPickerProps={{
					selectedDays: [from2, { from : from2, to : to2 }],
					toMonth: to2,
					localeUtils:MomentLocaleUtils,
					locale:"fr",
					modifiers2,
					numberOfMonths: 1,
					onDayClick: () => setTimeout(() => this.to2.getInput().focus(),400),
				}}
				onDayPickerShow={() => this.leStartStopDeRefresh(false)}
				onDayPickerHide={() => this.leStartStopDeRefresh(true)}
				onDayChange={this.bindFromChange2}
			/>
			—
			<span className="InputFromTo-to">
			<DayPickerInput
            ref={el => (this.to2 = el)}
            value={to2}
            placeholder="A"
			formatDate={this.formatDate}
			format="dd/MM/yyyy"
            dayPickerProps={{
              selectedDays: [from2, { from : from2, to : to2 }],
              disabledDays: { before: from2 },
			  localeUtils:MomentLocaleUtils,
			  locale:"fr",
              modifiers2,
              month: from2,
              fromMonth: from2,
              numberOfMonths: 1,
            }}
			onDayPickerShow={() => this.leStartStopDeRefresh(false)}
			onDayPickerHide={() => this.leStartStopDeRefresh(true)}
            onDayChange={this.bindToChange2}
          />
			</span>
			<IconButton aria-label="delete" onClick={this.bindResetClick2} size="large">
				<DeleteIcon fontSize="small"  />
			</IconButton>
			</div>
			</TableCell>
			<TableCell className="case_ligne_PDC" align="left">{this.state.ventilation}</TableCell>
			<TableCell className="case_ligne_PDC" align="left">{this.state.JoursAFaire}</TableCell>
			<TableCell  className="case_ligne_PDC" align="right">
				<IconButton onClick={this.bindDeletePDC} size="large">
					<RemoveShoppingCartIcon/>
				</IconButton>
			</TableCell>
		  </TableRow>
		  <TableRow>
			<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
			  <Collapse in={this.state.opened} timeout="auto" unmountOnExit>

				  { /* box de staffing */}
				<Box margin={1}>
				  <div className={"pdc_ssligne_box"}>
				  <Box 
					  component="form"
					  className={"pdc_ssligne_box_com"} 
					  sx={{ position: 'relative', minWidth:'350px', border:'0' }}
					>
					  {this.titrePorteurs && <Chip label={"AV : " + this.titrePorteurs} />}
					  <InputBase
						  multiline={true}
						  minRows="3"
						  sx={{ ml: 1, width:'95%', fontSize:12 }}
						  placeholder="Commentaires"
						  onChange={this.bindedComChange}
						  value={this.state.commentaires?this.state.commentaires:""}
					  />
					  {(this.state.validCom) && <IconButton
                          sx={{ p: '10px', right:"0", bottom:'0', position:'absolute'}}
                          aria-label="Valider"
                          onClick={this.bindedComClick}
                          size="large">
						<CheckIcon />
					  </IconButton>}
					</Box>
				  <Table size="small" aria-label="staffing">
					<TableHead>
					  <TableRow>
						
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center">Notif</TableCell>
						<TableCell align="center" className={"PDC_SSLigne_Titre_Semaine"}>{SemainesAnneesPourAffichage[0]}</TableCell>
						<TableCell align="center" className={"PDC_SSLigne_Titre_Semaine"}>{SemainesAnneesPourAffichage[1]}</TableCell>
						<TableCell align="center" className={"PDC_SSLigne_Titre_Semaine"}>{SemainesAnneesPourAffichage[2]}</TableCell>
						<TableCell align="center" className={"PDC_SSLigne_Titre_Semaine"}>{SemainesAnneesPourAffichage[3]}</TableCell>
						<TableCell align="center" className={"PDC_SSLigne_Titre_Semaine"}>{SemainesAnneesPourAffichage[4]}</TableCell>
						<TableCell align="center"></TableCell>
					  </TableRow>
					</TableHead>
					<TableBody>
					  { /* pour les lignes deja staffées */  
						this.state.lignesStaffing && this.state.lignesStaffing.map( ligneStaffing => {
						var indiceSemaine=0;
						ssLigne++;
						if (ligneStaffing.id!==-1 && ! this.ListeStaffable.find(u => u.id === ligneStaffing.id) )
							return;
						return (
                            <TableRow key={"ssLigne_"+this.state.idLigne+"_"+ssLigne}>
                            
                            <TableCell key={"ssLigne_r_"+this.state.idLigne+"_"+ssLigne} align="center" className={"PDC_SSLigne_Case_Role"}>
                            <Autocomplete 
                                id={"Role_"+ssLigne}
                                autoselect={1}
                                sx={{width:110}}
                                className={"PDC_SSLigne_Role"}
                                ListboxProps={{ sx: { fontSize: ".8em" } }}
                                options={this.ProfilsWavestone}
                                onChange={this.bindedTextFieldRoleChangeDejaStaffe}
                                getOptionLabel={ (option) => option }
                                value={ligneStaffing.role || null}
                                renderInput={(params) => <TextField {...params} fullWidth required id={"role_"+ligneStaffing.id} label="Role" /> }
                        
                            />						
                            </TableCell>
                            <TableCell key={"ssLigne_a_"+this.state.idLigne+"_"+ssLigne} align="center" className={"PDC_SSLigne_Case_Auditeur"}>
                            <Autocomplete 
                                id={"Auditeur_"+ssLigne}
                                autoselect={1}
                                sx={{width:180}}
                                renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.id+"_"+ssLigne}>
                                        {option.prenom + " " + option.nom + " ("+option.trigramme+")"}
                                    </li>
                                    );
                                }}
                                className={"PDC_SSLigne_Auditeur"}
                                ListboxProps={{ sx: { fontSize: ".7em" } }}
                                options={this.ListeStaffable}
                                onChange={this.bindedTextFieldAuditeurChangeDejaStaffe}
                                getOptionLabel={ (option) => option.prenom + " " + option.nom + " ("+option.trigramme+")"}
                                value={this.ListeStaffable.find(u => u.id === ligneStaffing.id) || null}
                                renderInput={(params) => <TextField {...params} sx={{fontSize: ".7em" }} fullWidth required id={"auditeur_area_"+ligneStaffing.id} label="Auditeur"  /> }
                            />
                            
                            </TableCell>
                            <TableCell key={"ssLigne_n_"+this.state.idLigne+"_"+ssLigne} align="center" className={"PDC_SSLigne_Case_Auditeur"}>
                                <Checkbox disabled={ligneStaffing.nouvelleLigne} key={"ssLigne_ck_"+this.state.idLigne+"_"+ssLigne}  onChange={this.bindedNotificationClick} 
                                    inputProps={{"data-user":ligneStaffing.id}} checked={! ligneStaffing.nouvelleLigne && ! this.state.notifications.includes(ligneStaffing.id)} />
                            </TableCell>
                            
                            {[...Array(5)].map((e, i) => {
                            
                            var onAffiche=false;
                            if (ligneStaffing.staffing[indiceSemaine] && SemainesAnnees[i].a*100+SemainesAnnees[i].s===ligneStaffing.staffing[indiceSemaine].a*100+ligneStaffing.staffing[indiceSemaine].s)
                                onAffiche=true;
                            
                            var res = <TableCell key={"ssLigne_"+ssLigne+"_"+i} align="center">
                                    <TextField value={onAffiche?ligneStaffing.staffing[indiceSemaine].v/2.:"X"}
                                        className={"pdc_ssligne_ventil"} onClick={this.bindedStaffingChangeDejaStaffe}
                                        inputProps={{style:{textAlign:"center", fontSize:".8em", padding:0},
                                        "data-ssligne":ssLigne, "data-semaine":(onAffiche?indiceSemaine:-1) }}
                                         />
                            </TableCell>

                            if (onAffiche)
                                indiceSemaine++;
                            return res;

                            })}
                                                
                            <TableCell align="center">
                                <IconButton id={this.state.idLigne+"-mail_"+ssLigne} size="large">
                                    <Tooltip2 title={"Ligne déjà staffée, RAS"}>
                                    <HowToRegIcon  fontSize="small" />
                                    </Tooltip2>
                                </IconButton>
								
                            </TableCell>
                            </TableRow>
                        );
					  })}
					  
					  {
					  /* pour les lignes en cours de staffing */  
						this.state.preStaffing && this.state.preStaffing.map( preStaff => {
						var indiceSemaine=0;
						trucAStaffer=true;
						ssLignePreStaff++;
						if (preStaff.idU!==-1 && ! this.ListeStaffable.find(u => u.id === preStaff.idU) )
							return;
						return (
                            <TableRow key={"ssLignePreStaff_"+this.state.idLigne+"_"+ssLignePreStaff}>
                            
                            <TableCell key={"ssLignePreStaff_r_"+this.state.idLigne+"_"+ssLignePreStaff} align="center" className={"PDC_SSLigne_Case_Role"}>
                            <Autocomplete 
                                id={"Role_"+ssLignePreStaff}
                                autoselect={1}
                                sx={{width:110}}
                                className={"PDC_SSLigne_Role"}
                                ListboxProps={{ sx: { fontSize: ".8em" } }}
                                options={this.ProfilsWavestone}
                                onChange={this.bindedTextFieldRoleChangePreStaff}
                                getOptionLabel={ (option) => option }
                                value={preStaff.role || null}
                                renderInput={(params) => <TextField {...params} fullWidth required id={"role_"+preStaff.idU} label="Role" /> }
                        
                            />						
                            </TableCell>
                            <TableCell key={"ssLignePreStaff_a_"+this.state.idLigne+"_"+ssLignePreStaff} align="center" className={"PDC_SSLigne_Case_Auditeur"}>
                            <Autocomplete 
                                id={"Auditeur_"+ssLignePreStaff}
                                autoselect={1}
                                sx={{width:180}}
                                renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.id+"_"+ssLignePreStaff}>
                                        {option.prenom + " " + option.nom + " ("+option.trigramme+")"}
                                    </li>
                                    );
                                }}
                                className={"PDC_SSLigne_Auditeur"}
                                ListboxProps={{ sx: { fontSize: ".7em" } }}
                                options={this.ListeStaffable}
                                onChange={this.bindedTextFieldAuditeurChangePreStaff}
                                getOptionLabel={ (option) => option.prenom + " " + option.nom + " ("+option.trigramme+")"}
                                value={this.ListeStaffable.find(u => u.id === preStaff.idU) || null}
                                renderInput={(params) => <TextField {...params} sx={{fontSize: ".7em" }} fullWidth required id={"auditeur_area_"+preStaff.idU} label="Auditeur"  /> }
                            />
                            
                            </TableCell>
                            
                            <TableCell key={"ssLignePreStaff_n_"+this.state.idLigne+"_"+ssLignePreStaff}>
								<IconButton 
										id={this.state.idLigne+"-deletePrestaff_"+ssLignePreStaff}
										onClick={this.bindDeletePreStaffing} size="large">
									<DeleteForeverIcon/>
								</IconButton>
							</TableCell>
							
                            
                            {[...Array(5)].map((e, i) => {
                            
                            var onAffiche=false;
                            if (preStaff.staffing[indiceSemaine] && SemainesAnnees[i].a*100+SemainesAnnees[i].s===preStaff.staffing[indiceSemaine].a*100+preStaff.staffing[indiceSemaine].s)
                                onAffiche=true;
                            
                            var res = <TableCell key={"ssLignePreStaff_"+ssLignePreStaff+"_"+i} align="center">
                                    <TextField value={onAffiche?preStaff.staffing[indiceSemaine].v/2.:"X"}
                                        className={"pdc_ssligne_ventil"} onClick={this.bindedStaffingChangePreStaff}
                                        inputProps={{style:{textAlign:"center", fontSize:".8em", padding:0},
                                        "data-ssligne":ssLignePreStaff, "data-semaine":(onAffiche?indiceSemaine:-1) }}
                                         />
                            </TableCell>

                            if (onAffiche)
                                indiceSemaine++;
                            return res;

                            })}
                            
                            <TableCell align="center">
                                <IconButton
                                    id={this.state.idLigne+"-prestaff_"+ssLignePreStaff}
                                    onClick={this.bindedClickValidePreStaff}
                                    size="large">
                                    {preStaff.nouvelleLigne && <Tooltip2 title={"Piste non sauvegardée"}> 
                                                                <PsychologyAltIcon fontSize="small" color="error" />
                                                                </Tooltip2>}
                                    {!preStaff.nouvelleLigne && <Tooltip2 title={"Ligne déjà pistée"}> 
                                                                <PsychologyAltIcon fontSize="small" color="success" />
                                                                </Tooltip2>}						
                                </IconButton>
                            </TableCell>
                                                
                            
                            </TableRow>
                        );
					  })}
						
						
						
						</TableBody>
				  </Table>
				 
				 <div style={{position: "relative"}}>
					{<Badge badgeContent={trucAStaffer?this.state.preStaffing.length:0} color="primary">
						<IconButton
                            style={{position: "absolute", top:"20"}}
                            disabled={(!this.state.preStaffing||this.state.preStaffing.length<=0)}
                            onClick={this.bindedClickStaffer}
                            size="large"> 
							<AssignmentIndIcon />
						</IconButton>
					</Badge>}
					<IconButton
                        style={{position: "absolute", bottom:"0"}}
                        onClick={this.bindedClickAjouterLigneStaffing}
                        size="large">
						<AddIcon  />
					</IconButton>
				 </div>
				 </div>
				</Box>
				{ /* fin de la box */ }
			  </Collapse>
			</TableCell>

		  </TableRow>
		  
		  
		  { /* popup selection date et PASSI / PRIS */ }
		  <Dialog onClose={this.bindedFermerStafferPopUp}
			open={this.state.selectionDatePopUp}
			aria-describedby="choix du planning"
		  >
			<DialogTitle>Selection de la fin prévisionnelle de la mission :</DialogTitle>
			<DialogContent>
			<DialogContentText>
				Date de fin :
			  </DialogContentText>
			  
				<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
				<DesktopDatePicker
					id='dateFin'
					minDate={new Date().setFullYear(new Date().getFullYear()-2)}
					maxDate={new Date().setFullYear(new Date().getFullYear()+5)}
					views={['month','year']}
					label="Fin (MM/YYYY)"
					value = {this.state.dateMoisFin+"/01/"+this.state.dateAnneeFin}
					inputFormat = "MM/yyyy"
					onChange = {this.bindedChangeDateFin}
					renderInput={(params) => <TextField {...params} helperText={null} />}
					/>
				</LocalizationProvider>
			  
			  <DialogContentText>
				Spécificité :
			  </DialogContentText>
			</DialogContent>
			<DialogActions>
			  <Button onClick={this.bindedClickStafferPopUp}>Go !</Button>
			</DialogActions>
		  </Dialog>
		  
		  
		  
		</React.Fragment>
    );
}
}

// fin de la classe d'une ligne de PDC



// le composant principal : un graph et un tableau avec des lignes

class PDC_Audit extends Component
{

	constructor(props)
	{
		super(props);
	
		this.chartRef = React.createRef();
	
		this.IdBoucleData =  -1;
		
		var typologiesAffichees = {};
		typologiesAffichees.IMPROD_BT = true;
		typologiesAffichees.PROD_BT = true;
		typologiesAffichees.HORS_BT_PROD = true;
		typologiesAffichees.HORS_BT_IMPROD = true;
		typologiesAffichees.AUDIT_KM = true;
		typologiesAffichees.AUDIT_IMPROD = true;
		typologiesAffichees.AUDIT_FORMATION = true;
		typologiesAffichees.AUDIT_STAGE = true;
		typologiesAffichees.AUDIT_RD = true;
		typologiesAffichees.AUDIT_AV = true;
		typologiesAffichees.CERT = true;
		
		this.usersTribu=[];
		for (var k = 0; k<listeUtilisateurs.length; k++)
			this.usersTribu.push(listeUtilisateurs[k].id);
		
		this.visibiliteLignes = new Map();
		
		this.state={loading:true, etat:0, mess:"", orderBy:1,  lignesPDC: [], dispos: [], bureauxVisibles: userEnSession.bureau, 
				typologiesAffichees: typologiesAffichees, tribuAffichee : -1,  
				optionsLegende_StagiairesVisibles: false, optionsLegende_CertVisible: false};
		
		this.bindedClickAffichage = this.handleClickAffichage.bind(this);
		this.bindedClickAffichageTypo = this.handleClickAffichageTypo.bind(this);
		this.bindedClickAffichageTribu = this.handleClickAffichageTribu.bind(this);
		this.bindedClickAffichageBureau = this.handleClickAffichageBureau.bind(this);
		
		/* pour le graphe */
		
		this.options=
			{
				scales:
					{ y: 
						{ stacked: true, min:0} 
					},
			    interaction:
					{
						mode: 'index',
						intersect: false,
					//	mode: 'point',
					},
				plugins:
					{
					legend: 
						{ display: false },
					tooltip: 
						{
							enabled: false,
							position: 'nearest',
							external: this.toolTipsHTML
						},
					}
			};
			
			
		this.plugins = [{
			afterUpdate: function(chart, args, options) {
				
				const legendContainer = document.getElementById("PDC_Legende");
				
				while (legendContainer.firstChild)
				{
					legendContainer.firstChild.remove();
				}
				
				const legend = document.createElement('div');
				legend.classList.add("PDC_Legende_Labels");
				chart.data.datasets.slice().reverse().map( dataset =>
							{
								
							const sousDiv = document.createElement('div');
							sousDiv.classList.add("PDC_Legende_Label");
							sousDiv.onclick = () => 
								{
									dataset.hidden = !dataset.hidden;
									chart.update();
								};
							
							//legend += "<div class='PDC_Legende_Label' onclick = 'console.log(chart); this.chartRef.current.setDatasetVisibility(dataset.datasetIndex, !this.chartRef.current.isDatasetVisible(dataset.datasetIndex)); this.chartRef.current.update(); '>";

							var color = "light-grey";
							if (!dataset.hidden)
								color = dataset.borderColor;
								
							const puce = document.createElement('div');
							puce.classList.add("PDC_Legende_Puce");
							puce.style.background = color;
						
							var style = "none";
							if (dataset.hidden)
								style = "line-through";
								
							const txt = document.createElement('div');
							txt.classList.add('PDC_Legende_Txt');
							txt.style.textDecoration = style;
							txt.innerText=dataset.label;
						
							sousDiv.appendChild(puce)
							sousDiv.appendChild(txt);
							legend.appendChild(sousDiv);
							
							return null;
							});
	
				legendContainer.appendChild(legend);
      
		}}];
		
	}


	toolTipsHTML = (context) =>
	{
	let {chart, tooltip} = context;
	let tooltipEl  = this.chartRef.current.canvas.parentNode.querySelector('div');
	//let tooltipEl = document.getElementById('chartjs-tooltip');	
	//tooltipEl.classList.add('no-transform');

	  if (!tooltipEl )
	  {
		tooltipEl  = document.createElement('div');
		tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
		tooltipEl.style.borderRadius = '5px';
		tooltipEl.style.color = 'white';
		tooltipEl.style.zIndex = 999;
		tooltipEl.style.opacity = 1;
		tooltipEl.style.overflow = 'auto';
		tooltipEl.style.pointerEvents = 'none';
		tooltipEl.style.position = 'absolute';
		tooltipEl.style.transform = 'translate(-50%, 0)';
		tooltipEl.style.transition = 'all .1s ease';

		//tooltipEl.classList.add("PDC_ToolTip");
		
		const table = document.createElement('table');
		table.style.margin = '0px';

		tooltipEl.appendChild(table);
		this.chartRef.current.canvas.parentNode.appendChild(tooltipEl);
	  }
		 
		if (tooltip.opacity === 0)
		{
			tooltipEl.style.opacity = 0;
			return;
		}

	 // Set Text
		if (tooltip.body)
		{
			const titleLines = tooltip.title || [];
			const bodyLines = tooltip.body.map(b => b.lines);

			const tableHead = document.createElement('thead');

			titleLines.forEach(title => 
			{
				const tr = document.createElement('tr');
				tr.style.borderWidth = 0;

				const th = document.createElement('th');
			//	th.style.borderWidth = 2;
				th.style.textAlign = "center";
				th.colSpan = 2;
				const text = document.createTextNode(title);
				  
				  
				th.appendChild(text);
				tr.appendChild(th);
				tableHead.appendChild(tr);
			});

			const tableBody = document.createElement('tbody');
			var tr;
			var indice = 0;
			this.getDataFromChartRef().datasets.slice().reverse().forEach((body, i) => 
			{
				const colors = tooltip.labelColors[i];

				if (body.hidden)
					return;
		
				if (body.data[context.tooltip.dataPoints[0].dataIndex] === 0)
					return;
	  
				const span = document.createElement('span');
			//  span.style.color = colors.backgroundColor;
			 // span.style.borderColor = colors.borderColor;
			//  span.style.borderWidth = '2px';
				if (indice%2===0)
					span.style.marginRight = '30px';
			// span.style.height = '10px';
			  //span.style.width = '100px';
				span.style.fontSize = '.8em';
				span.style.display = 'inline-block';

				if (indice%2===0)
				{
					tr = document.createElement('tr');
					tr.style.backgroundColor = 'inherit';
					tr.style.borderWidth = 0;
				}
				const td = document.createElement('td');
				td.style.borderWidth = 0;

				const text = document.createTextNode(body.label + " : " + body.data[context.tooltip.dataPoints[0].dataIndex] + "j");

				span.appendChild(text);
				td.appendChild(span);
				tr.appendChild(td);
				tableBody.appendChild(tr);
				indice++;
			});

		const tableRoot = tooltipEl.querySelector('table');

		// Remove old children
		while (tableRoot.firstChild)
		{
			tableRoot.firstChild.remove();
		}

		// Add new children
		tableRoot.appendChild(tableHead);
		tableRoot.appendChild(tableBody);
	}

	const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

	  // Display, position, and set styles for font
	tooltipEl.style.opacity = 1;
	tooltipEl.style.left = positionX + tooltip.caretX + 'px';
	tooltipEl.style.top = positionY + tooltip.caretY + 'px';
	tooltipEl.style.font = tooltip.options.bodyFont.string;
	tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
		
	}
	

    // gestion de l'ordre dans la liste

	createSortHandler = (data, indice) =>
	{
		var dir = 0;
		if (!data && indice===Math.abs(this.state.orderBy))
			dir = -this.state.orderBy;
		else
			dir = indice;
				
		// on fait une copie de la donnée
		if (data)
			var newA = data.slice();
		else
			var newA = this.state.lignesPDC.slice();
		
		// on trie 
		
		 newA.sort((a, b) => 
		 {
			var res = 0;
			switch (Math.abs(indice))
			{
				
				case 1 :
					res = (a.prio - b.prio)*dir; 
					break;
				case 2 :
					var nomA = a.mission ? a.mission.nomC : a.propale.client;
					var nomB = b.mission ? b.mission.nomC : b.propale.client;
					res = (nomA.localeCompare(nomB))*dir;
					break;
				case 3 :
					var nomA = a.mission ? a.mission.nomM : a.propale.mission;
					var nomB = b.mission ? b.mission.nomM : b.propale.mission;
					res = (nomA.localeCompare(nomB))*dir;
					break;
				case 4 :
					if (! b.debLancement)
						res = dir;
					else 
						res = (b.debLancement.localeCompare(a.debLancement))*dir;
					break;
				case 5 :
					if (! b.debRealisation)
						res = dir;
					else
						res = (b.debRealisation.localeCompare(a.debRealisation))*dir;
					break;
				case 6 :
					res = (a.prio - b.prio)*dir;
					if (res === 0)
						if (! b.debRealisation)
							res = dir;
						else
							res = (b.debRealisation.localeCompare(a.debRealisation))*dir;
					break;
			}
			
			if (res===0)
				return b.id - a.id;
			return res;
			
		 });
		 
		this.setState({lignesPDC:newA, orderBy:dir});
	}
	
	
	// appele par le composant errordialog pour ack le message => chgt d'etat
	callbackError = () => 
	{
		this.setState({etat:0});
	}
	
	
	getDataFromChartRef()
	{
		if (!this.chartRef.current)
			return {datasets:[]};
		return this.chartRef.current.data;
	}
	
	
	// gestion de l'affichage des tribus
	handleClickAffichageTribu (e)
	{
		var t = parseInt(e.currentTarget.dataset.tribu);
		if (t===this.state.tribuAffichee)
			t=-1;
		
		this.usersTribu=[];
		for (var k = 0; k<listeUtilisateurs.length; k++)
			if (t===-1 || listeUtilisateurs[k].tribu === t)
				this.usersTribu.push(listeUtilisateurs[k].id);
			
		this.setState({tribuAffichee:t});
	}
	
	handleClickAffichageBureau(e)
	{
		var b = parseInt(e.currentTarget.dataset.bureau);
		this.setState({bureauxVisibles:this.state.bureauxVisibles^b});
		// on force comme ça le rafraichissement du graphe 
		this.getDataFromChartRef().nbUsers = -1;
		this.getData();
	}
	
	callbackWait = () =>
	{
		this.setState({loading:true});
	}
	
	// qd on a fini de charger le PDC :
	callbackEndLoading = (obj) =>
	{
			
		if (obj.etat === 4) // erreur et on garde le truc ouvert
			this.setState({etat:obj.etat, mess:obj.mess, loading:false});
		else
		{
	// si pas de data, c'est juste un callback vide pour traiter l'erreur ci dessus
		if (obj.data)
		{
			this.setState({etat:obj.etat, mess:obj.mess, notifPDC: obj.data.notifPDC, preStaffing: obj.data.preStaff, dispos : obj.data.dispos }, this.setState({loading:false}) );
			this.createSortHandler(obj.data.lignesPDC, this.state.orderBy);
			if (!this.getDataFromChartRef().nbUsers || this.getDataFromChartRef().nbUsers !== obj.data.dispos.length-1) // -1 car 0 => semaine
				this.creationGraphe1erfois(obj.data.dispos)
			this.updateGraphe(obj.data.dispos);
			this.leStartStopDeRefresh(true);
		}
		}
	}	
	
	getRandomColor()
	{
		var letters = '0123456789ABCDEF'.split('');
		var color = '#';
		for (var i = 0; i < 6; i++ ) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}
	
	updateGraphe(tab)
	{
		// on part de 1 car 0 => semaines
		for(var a=1;a<tab.length;a++)
		{					
			var res = this.getDataFromChartRef().datasets.find(d => d.idUtilisateur === tab[a].auditeur.id);
			if (res)
				res.data = this.calculDonneesAAfficher(tab, a);
		}
		this.chartRef.current.update();
		
	}
	
	utilisateurAmasquer(user)
	{
		if (user.visible==="false")
			return true;
		if (user.profil.indexOf("STAGIAIRE")>=0
		 && !this.state.optionsLegende_StagiairesVisibles)
			return true;
		if (user.profilBT===2 
			&& !this.state.optionsLegende_CertVisible)
			return true;
		return false;
	}
	
	masquerSiBesoin(a)
	{
		if (!this.getDataFromChartRef().datasets[a])
			return;			
		var user = listeUtilisateurs.find(u => u.id === this.getDataFromChartRef().datasets[a].idUtilisateur);
		if  (user==null)
			return;
		this.getDataFromChartRef().datasets[a].hidden = this.utilisateurAmasquer(user);
	}
	
	handleClickAffichage (e,v)
	{
		if (e.currentTarget.dataset.type === "stagiaire")
		{
			this.setState({optionsLegende_StagiairesVisibles : !this.state.optionsLegende_StagiairesVisibles}, () => 
		
			{
				for(var a=0;a<this.getDataFromChartRef().nbUsers;a++)
				{
					this.masquerSiBesoin(a) ;
				}
				
				
				this.updateGraphe(this.state.dispos);
			});
			return;
		}
		if (e.currentTarget.dataset.type === "cert")
		{
			this.setState({optionsLegende_CertVisible : !this.state.optionsLegende_CertVisible}, () => 
		
			{
				for(var a=0;a<this.getDataFromChartRef().nbUsers;a++)
				{
					this.masquerSiBesoin(a) ;
				}
				
				
				this.updateGraphe(this.state.dispos);
			});
		}	
	}
	
	handleClickAffichageTypo (e,v)
	{
		var typo = this.state.typologiesAffichees;
		typo[e.currentTarget.dataset.type] = !typo[e.currentTarget.dataset.type];
		
		this.setState({typologiesAffichees: typo});
		this.updateGraphe(this.state.dispos);
	}


	creationGraphe1erfois(o)
	{
		//console.log("creation du graph" );
	
		var obj = o.slice(1).sort((a, b) => a.auditeur.date.localeCompare(b.auditeur.date));
		
		this.getDataFromChartRef().datasets = [];
		this.getDataFromChartRef().labels = o[0].labels;
	
		for(var a=0;a<obj.length;a++)
		{
			
			if ((obj[a].auditeur.bureau & this.state.bureauxVisibles) === 0)
				continue;
		
			this.getDataFromChartRef().datasets.push( {
					data: this.calculDonneesAAfficher(o, a+1), // +1 car on passe la 0 qui est la semaien
					idUtilisateur: obj[a].auditeur.id,
					hidden: this.utilisateurAmasquer(obj[a].auditeur),
					fill: false,
					borderColor: this.getRandomColor(),
					label: obj[a].auditeur.prenom + " " + obj[a].auditeur.nom.charAt(0).toUpperCase() + ". ("+obj[a].auditeur.trigramme+")",
				});
		}
		
		this.getDataFromChartRef().nbUsers = obj.length;
	
	}
	
	calculDonneesAAfficher(dispos, user)
	{
	
		var tab = [];
		for(var i=0; i< dispos[0].semaines.length; i++)
		{
			
			tab.push(dispos[0].semaines[i]);
			tab[i] -= dispos[user].occupation.WAVESTONE[i];
			tab[i] -= dispos[user].occupation.CONGES[i];
			tab[i] -= dispos[user].occupation.AUTRE[i];
			tab[i] -= dispos[user].occupation.BT_4_ALL[i];
			
			if (this.state.typologiesAffichees.HORS_BT_PROD)
				tab[i] -= dispos[user].occupation.HORS_BT_PROD[i];
			if (this.state.typologiesAffichees.HORS_BT_IMPROD)
				tab[i] -= dispos[user].occupation.HORS_BT_IMPROD[i];
			if (this.state.typologiesAffichees.IMPROD_BT)
				tab[i] -= dispos[user].occupation.IMPROD_BT[i];
			if (this.state.typologiesAffichees.PROD_BT)
				tab[i] -= dispos[user].occupation.PROD_BT[i];

			tab[i] -= dispos[user].occupation.AUDIT_PROD[i];
			tab[i] -= dispos[user].occupation.AUDIT_GRATOS[i];
		
			if (this.state.typologiesAffichees.AUDIT_AV)
				tab[i] -= dispos[user].occupation.AUDIT_AV[i];
			if (this.state.typologiesAffichees.AUDIT_KM)
				tab[i] -= dispos[user].occupation.AUDIT_KM[i];
			if (this.state.typologiesAffichees.AUDIT_IMPROD)
				tab[i] -= dispos[user].occupation.AUDIT_IMPROD[i];
			if (this.state.typologiesAffichees.AUDIT_STAGE)
				tab[i] -= dispos[user].occupation.AUDIT_STAGE[i];
			if (this.state.typologiesAffichees.AUDIT_RD)
				tab[i] -= dispos[user].occupation.AUDIT_RD[i];
			if (this.state.typologiesAffichees.AUDIT_FORMATION)
				tab[i] -= dispos[user].occupation.AUDIT_FORMATION[i];

			if (this.state.typologiesAffichees.CERT)
			{
			tab[i] -= dispos[user].occupation.CERT_AV[i];
			tab[i] -= dispos[user].occupation.CERT_KM[i];
			tab[i] -= dispos[user].occupation.CERT_FORMATION[i];
			tab[i] -= dispos[user].occupation.CERT_RD[i];
			tab[i] -= dispos[user].occupation.CERT_PROD[i];
			tab[i] -= dispos[user].occupation.CERT_INTERVENTION[i];
			tab[i] -= dispos[user].occupation.CERT_STAGE[i];
			tab[i] -= dispos[user].occupation.CERT_CTI[i];
			}

			// on divise tout par 2 
			tab[i] = (tab[i] / 2.);
			
			if (tab[i]<0)
				tab[i]=0;
			
		}
		return tab;
	}
	
	
	
	// intrégration du graphe
	
	getData = () => 
	{
		var urlChargerPDC = "ctrl=ControleurPDCAudit&methode=voir";
		var urlChargerPDCOptions = {method: "GET", body:""};
		appelAction(urlChargerPDC, urlChargerPDCOptions, this.callbackEndLoading);			
		
	}	

	
	componentDidMount()
	{
		this.getData();
	}


	componentWillUnmount()
	{
		this.leStartStopDeRefresh(false);
	}
	
	leStartStopDeRefresh = (lancer) =>
	{
		
	//	console.log(lancer?"GO":"STOP");
			
		if (lancer)
		{	
			if (this.IdBoucleData>0)
			{
		//		console.log("on ne relance pas...");
				return;
			}
			this.IdBoucleData = setInterval(this.getData.bind(this),30000);
		}
		else
		{
			if (this.IdBoucleData<0)
			{
				return;
			}
			clearInterval(this.IdBoucleData);
			this.IdBoucleData=-1;
		}
	}
	

	
	convert (ligne)
	{
		/*
		debLancement:this.props.debLancement, finLancement:this.props.finLancement, 
					debRealisation:this.props.debRealisation, finRealisation:this.props.finRealisation, 
					nomClient:this.props.nomClient, nomMission:this.props.nomMission, priorite:this.props.priorite,
		*/
		
	
			var i;
			var ventilation="";
			var preStaff=[];
			
			for (i=0; i <ligne.propale.roles.length; i++)
			{
				ventilation+=ligne.propale.roles[i]+": "+ligne.propale.jours[i]+"j ";
			}				
			
			/* si aucune ligne, on met la totale */
			if (! ligne.lignesStaffing)
			{
				ligne.lignesStaffing=[];
		/*
				var staff=[];
				for(i=0; i<5;i++)
				{ 
				  staff.push({s:SemainesAnnees[i].s, a:SemainesAnnees[i].a, v:0});
				}
				preStaff = [{id:-1, role:"", staffing:staff, nouvelleLigne: true}];
				*/
			}
			
			var notif =  [];
			var k=0;
			while (k<this.state.notifPDC.length)
			{
				if (!this.state.notifPDC[k])
					continue;
				var n = parseInt(Object.keys(this.state.notifPDC[k])[0]);
				if (n === ligne.id)
				{
					break;
				}
				k++;
			}
			if (k<this.state.notifPDC.length && this.state.notifPDC[k] )
			{
				var n = parseInt(Object.keys(this.state.notifPDC[k])[0]);
				notif = this.state.notifPDC[k][n].slice();
			}
			
			k=0;
			while (k<this.state.preStaffing.length)
			{
				if (!this.state.preStaffing[k])
					continue;
				var n = parseInt(Object.keys(this.state.preStaffing[k])[0]);
				if (n === ligne.id)
				{
					break;
				}
				k++;
			}
			if (k<this.state.preStaffing.length && this.state.preStaffing[k] )
			{
				var n = parseInt(Object.keys(this.state.preStaffing[k])[0]);
				preStaff = this.state.preStaffing[k][n].slice();
			}
			
			var debL, finL = undefined;
			var debR, finR = undefined;
			if (ligne.debLancement && ligne.finLancement)
			{
				debL = moment(Number(ligne.debLancement)).format("YYYY/MM/DD");
				finL = moment(Number(ligne.finLancement)).format("YYYY/MM/DD");
			}
			if (ligne.debRealisation && ligne.finRealisation)
			{
				debR = moment(Number(ligne.debRealisation)).format("YYYY/MM/DD");
				finR = moment(Number(ligne.finRealisation)).format("YYYY/MM/DD");
			}
			return {idLigne: ligne.id, 
					debLancement:debL, finLancement:finL, 
					debRealisation:debR, finRealisation:finR, 
					lignesStaffing: ligne.lignesStaffing, notifications: notif, preStaffing: preStaff, commentaires: ligne.commentaires, porteurPropale : ligne.porteurs, nomClient:(ligne.mission)?ligne.mission.nomC:ligne.propale.client, nomMission:(ligne.mission)?ligne.mission.nomM:ligne.propale.mission, priorite:ligne.prio, ventilation: ligne.mission&&ligne.mission.idMissParent>0?"cf. parent":ventilation,
					mission:ligne.mission, dateModif: ligne.dateModif, modifieur: ligne.modifieur};
		
	}
	



	render ()
	{
	
	
	const data = { labels: [], datasets: [{ label: '', data: [], hidden:true }] };
		
	var indice=-1;
	return <div className="container_array_ligne_pdc">
		
		<CRaudit></CRaudit>
		
		<DiagError key={"diag"} etat={this.state.etat} mess={this.state.mess} callback={this.callbackError}></DiagError>
		<Backdrop className="backdrop" open={this.state.loading}>
			<CircularProgress color="inherit" />
		</Backdrop>
		<div className={"PDC_Haut_Graph"}>
			<div className="container_graph">
                <Line id="myChart" className="pdc_graph" type="Line" data={this.chartRef.current?this.getDataFromChartRef():data} plugins={this.plugins} options={this.options} ref={this.chartRef} />
			</div>
			<div className={"PDC_Container_Legende"}>
				<div id={"PDC_Legende"} className={"PDC_Legende"}></div>
				<div className={"PDC_Legende_Bouttons"}>
					<div className={"PDC_Legende_Boutton"} data-type="stagiaire" onClick={this.bindedClickAffichage}>{this.state.optionsLegende_StagiairesVisibles?<VisibilityIcon sx={{fontSize: 12}}/>:<VisibilityOffIcon sx={{fontSize: 12}}/>} Stagiaires</div>
					<div className={"PDC_Legende_Boutton"} data-type="cert" onClick={this.bindedClickAffichage}>{this.state.optionsLegende_CertVisible?<VisibilityIcon sx={{fontSize: 12}}/>:<VisibilityOffIcon sx={{fontSize: 12}}/>} Cert</div>
				</div>
				<div id={"PDC_Legende_Typo"} className={"PDC_Legende_Typo"}>
					<div className={"PDC_Legende_Typo_List"} data-type="HORS_BT_PROD" onClick={this.bindedClickAffichageTypo}>
						{this.state.typologiesAffichees.HORS_BT_PROD?<VisibilityIcon sx={{fontSize: 12}}/>:<VisibilityOffIcon sx={{fontSize: 12}}/>}
						Prod. hors BT</div>				
					<div className={"PDC_Legende_Typo_List"} data-type="HORS_BT_IMPROD" onClick={this.bindedClickAffichageTypo}>
						{this.state.typologiesAffichees.HORS_BT_IMPROD?<VisibilityIcon sx={{fontSize: 12}}/>:<VisibilityOffIcon sx={{fontSize: 12}}/>}
						Improd. hors BT</div>
					<div className={"PDC_Legende_Typo_List"} data-type="AUDIT_AV" onClick={this.bindedClickAffichageTypo}>
						{this.state.typologiesAffichees.AUDIT_AV?<VisibilityIcon sx={{fontSize: 12}}/>:<VisibilityOffIcon sx={{fontSize: 12}}/>}
						Av. audit</div>
					<div className={"PDC_Legende_Typo_List"} data-type="AUDIT_KM" onClick={this.bindedClickAffichageTypo}>
						{this.state.typologiesAffichees.AUDIT_KM?<VisibilityIcon sx={{fontSize: 12}}/>:<VisibilityOffIcon sx={{fontSize: 12}}/>}
						KM audit</div>
					<div className={"PDC_Legende_Typo_List"} data-type="AUDIT_STAGE" onClick={this.bindedClickAffichageTypo}>
						{this.state.typologiesAffichees.AUDIT_STAGE?<VisibilityIcon sx={{fontSize: 12}}/>:<VisibilityOffIcon sx={{fontSize: 12}}/>}
						Stage audit</div>
					<div className={"PDC_Legende_Typo_List"} data-type="AUDIT_RD" onClick={this.bindedClickAffichageTypo}>
						{this.state.typologiesAffichees.AUDIT_RD?<VisibilityIcon sx={{fontSize: 12}}/>:<VisibilityOffIcon sx={{fontSize: 12}}/>}
						R&D Audit</div>
					<div className={"PDC_Legende_Typo_List"} data-type="AUDIT_IMPROD" onClick={this.bindedClickAffichageTypo}>
						{this.state.typologiesAffichees.AUDIT_IMPROD?<VisibilityIcon sx={{fontSize: 12}}/>:<VisibilityOffIcon sx={{fontSize: 12}}/>}
						Improd audit</div>
					<div className={"PDC_Legende_Typo_List"} data-type="AUDIT_FORMATION" onClick={this.bindedClickAffichageTypo}>
						{this.state.typologiesAffichees.AUDIT_FORMATION?<VisibilityIcon sx={{fontSize: 12}}/>:<VisibilityOffIcon sx={{fontSize: 12}}/>}
						Formation audit</div>
					<div className={"PDC_Legende_Typo_List"} data-type="CERT" onClick={this.bindedClickAffichageTypo}>
						{this.state.typologiesAffichees.CERT?<VisibilityIcon sx={{fontSize: 12}}/>:<VisibilityOffIcon sx={{fontSize: 12}}/>}
						Cert (All)</div>

				</div>
				<div id={"PDC_Legende_Tribu"} className={"PDC_Legende_Tribu"}>
				{[...Array(4)].map((e, i) => {
					var t=i+1;
					return <div key={"cle_Legende_Tribu_"+t} className={"PDC_Legende_Tribu_List"} data-tribu={t} onClick={this.bindedClickAffichageTribu}>
						{this.state.tribuAffichee===-1||this.state.tribuAffichee===t?<VisibilityIcon sx={{fontSize: 12}}/>:<VisibilityOffIcon sx={{fontSize: 12}}/>}
						Tribu {t}</div>
				} ) }
				</div>
				<div id={"PDC_Legende_Tribu"} className={"PDC_Legende_Bureaux"}>
				{bureauxWavestone.map( (b, i) => {
					var t=i+1;
					return <div key={"cle_Legende_Bureau_"+t} className={"PDC_Legende_Bureaux_List"} data-bureau={b.id} onClick={this.bindedClickAffichageBureau}>
						{(this.state.bureauxVisibles&b.id)===b.id?<VisibilityIcon sx={{fontSize: 12}}/>:<VisibilityOffIcon sx={{fontSize: 12}}/>}
							{b.nom}</div>
				} ) }
				</div>
			</div>
        </div>
		<TableContainer className="array_pdc" >
		<Table className="array_lignes_pdc" stickyHeader aria-label="Missions PDC">
			<TableHead >
			  <TableRow>
				<TableCell />
				<TableCell className={"pdc_titre_lignes"} align="left" 
					key={1}
					sortDirection={Math.abs(this.state.orderBy) === 1 ? (this.state.orderBy > 0 ? 'asc' : 'desc') : 'asc'}
				>
				<TableSortLabel
					active={Math.abs(this.state.orderBy) === 1}
					direction={Math.abs(this.state.orderBy) === 1 ? (this.state.orderBy > 0 ? 'asc' : 'desc') : 'asc'}
					onClick={() => this.createSortHandler(null,1)}
				>
				<p>P.</p>
				</TableSortLabel>
				</TableCell>
				<TableCell className={"pdc_titre_lignes"} align="left" 
					key={2}
					sortDirection={Math.abs(this.state.orderBy) === 2 ? (this.state.orderBy > 0 ? 'asc' : 'desc') : 'asc'}
				>
				<TableSortLabel
					active={Math.abs(this.state.orderBy) === 2}
					direction={Math.abs(this.state.orderBy) === 2 ? (this.state.orderBy > 0 ? 'asc' : 'desc') : 'asc'}
					onClick={() => this.createSortHandler(null,2)}
				>
				<p>Nom du client</p>
				</TableSortLabel>
				</TableCell>
				<TableCell className={"pdc_titre_lignes"} align="left" 
					key={3}
					sortDirection={Math.abs(this.state.orderBy) === 3 ? (this.state.orderBy > 0 ? 'asc' : 'desc') : 'asc'}
				>
				<TableSortLabel
					active={Math.abs(this.state.orderBy) === 3}
					direction={Math.abs(this.state.orderBy) === 3 ? (this.state.orderBy > 0 ? 'asc' : 'desc') : 'asc'}
					onClick={() => this.createSortHandler(null,3)}
				>
				<p>Nom de la mission</p>
				</TableSortLabel>
				</TableCell>
				<TableCell className={"pdc_titre_lignes"} align="left" 
					key={4}
					sortDirection={Math.abs(this.state.orderBy) === 4 ? (this.state.orderBy > 0 ? 'asc' : 'desc') : 'asc'}
				>
				<TableSortLabel
					active={Math.abs(this.state.orderBy) === 4}
					direction={Math.abs(this.state.orderBy) === 4 ? (this.state.orderBy > 0 ? 'asc' : 'desc') : 'asc'}
					onClick={() => this.createSortHandler(null,4)}
				>
				<p>Date lancement</p>
				</TableSortLabel>
				</TableCell>
				<TableCell className={"pdc_titre_lignes"} align="left" 
					key={5}
					sortDirection={Math.abs(this.state.orderBy) === 5 ? (this.state.orderBy > 0 ? 'asc' : 'desc') : 'asc'}
				>
					<TableSortLabel
						active={Math.abs(this.state.orderBy) === 5}
						direction={Math.abs(this.state.orderBy) === 5 ? (this.state.orderBy > 0 ? 'asc' : 'desc') : 'asc'}
						onClick={() => this.createSortHandler(null,5)}
					>
					<p>Date réalisation</p>
					</TableSortLabel>
				</TableCell>
				<TableCell className={"pdc_titre_lignes"} align="left" ><p>Jours à faire</p></TableCell> 
				<TableCell className={"pdc_titre_lignes"} align="left" ><p>Jours fait</p></TableCell>  
				<TableCell className={"pdc_titre_lignes"} align="right"
					key={6}		
					sortDirection={Math.abs(this.state.orderBy) === 6 ? (this.state.orderBy > 0 ? 'asc' : 'desc') : 'asc'}		
					>
					<TableSortLabel
						active={Math.abs(this.state.orderBy) === 6}
						direction={Math.abs(this.state.orderBy) === 6 ? (this.state.orderBy > 0 ? 'asc' : 'desc') : 'asc'}
						onClick={() => this.createSortHandler(null,6)}
					>
					<p>Suppr.</p>
					</TableSortLabel>
				</TableCell>  
			  </TableRow>
			</TableHead>
			<TableBody className="array_body_pdc">
			  {this.state.lignesPDC.filter(ligne => (ligne.bureau&this.state.bureauxVisibles)>0).map( 
			  
				(row) => { 
					var newOne = this.convert(row);
					
					if (! this.visibiliteLignes.get(row.id) )
						this.visibiliteLignes.set(row.id, false);
						
					return ((this.state.tribuAffichee===-1)||(row.lignesStaffing&&row.lignesStaffing.find(u => this.usersTribu.includes(u.id))))&&<Row key={"ligne_"+(++indice)} 
						row={newOne} 
						leStartStopDeRefresh={this.leStartStopDeRefresh}
						callBack={this.callbackEndLoading}
						callbackWait={this.callbackWait}
						visible={(this.state.tribuAffichee>0&&newOne.notifications&&newOne.notifications.length>0)||this.visibiliteLignes.get(row.id)} 
						changementAffichage={(id) => {this.visibiliteLignes.set(id, !this.visibiliteLignes.get(id))} } /> } ) 
			  }
			</TableBody>
		  </Table>
		</TableContainer>
		</div>
	}
}
export default PDC_Audit