import React, { Component } from "react";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';

import { appelAction, DiagError } from './utils.js';
import { listeClients, listeUtilisateurs } from './index.js';

import "./LesMissions_Audit.css";
 
class LesMissions_Cert extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {etat:0, mess:"ok", loading:false, nomMission:["","","","","","",""], nomClient:["","","","","","",""], cp:["","","","","","",""], disableButtons:false};
		
		this.listeStaffable = listeUtilisateurs.filter(u => u.actif==="true" && (u.profilBT & 2)>0);
		this.listeStagiaires = listeUtilisateurs.filter(u => u.actif==="true" && (u.profil.indexOf("STAGIAIRE")>=0) && (u.profilBT & 2)>0 );
		this.bindedTextFieldChange = this.handleTextFieldChange.bind(this);
		this.bindedCreationMission = this.handleCreationMission.bind(this);
		this.bindedTextFieldAuditeurChange = this.handleTextFieldAuditeurChange.bind(this);
	}
	
	handleTextFieldAuditeurChange(e, v)
	{
		var i = parseInt(e.currentTarget.dataset.indice);
		var tab = this.state.cp.slice();
		tab[i] = v;
		this.setState({cp:tab});
	}
	
	handleTextFieldChange(e)
	{
		var i = parseInt(e.currentTarget.dataset.indice);
		if (e.currentTarget.id.startsWith("mission"))
		{
			var tab = this.state.nomMission.slice();
			tab[i] = e.currentTarget.value;
			this.setState({nomMission : tab});
		}
		if (e.currentTarget.id.startsWith("client"))
		{
			var tab = this.state.nomClient.slice();
			tab[i] = e.currentTarget.value;
			this.setState({nomClient : tab});
		}
	}
	
	handleCreationMission(e)
	{
		this.setState({disableButtons:true});
		var type=e.currentTarget.dataset.type;
		var indice=e.currentTarget.dataset.indice;
		
		var urlOptionsListeMission = "ctrl=ControleurMissionsCertAutre&methode=creer";
		var requestOptionsListeMission = {method: "POST", body:"type="+type+"&cp="+this.state.cp[indice].id+"&miss="+this.state.nomMission[indice].sansEtCom()+"&client="+this.state.nomClient[indice].sansEtCom()};

		appelAction(urlOptionsListeMission, requestOptionsListeMission, this.handleCallback);
	}


  	// appele par le composant errordialog pour ack le message => chgt d'etat
	callbackError = () => 
	{
		this.setState({etat:0});
	}
	
	handleCallback = (obj) =>
	{
	  if (obj.etat === 4) // erreur et on garde le truc ouvert
		this.setState({etat:obj.etat, mess:obj.mess, disableButtons:false});
	  else
	  {
		this.setState({etat:obj.etat, mess:obj.mess,  nomMission:["","","","","","",""], nomClient:["","","","","","",""], cp:["","","","","","",""], disableButtons:false});
	  }
	
	}
	
	componentDidMount()
	{
		// on a audit ici... mais c'est la meme chose puisque liste des users
		var urlOptionsListeMission = "ctrl=ControleurMissionsAuditAutre&methode=voir";
		var requestOptionsListeMission = {method: "GET"};
	
		appelAction(urlOptionsListeMission, requestOptionsListeMission, this.handleCallback);
	}


	
	render()
	{
		/***
		
		 CERT_PROD, CERT_CTI
		
		****/
		
		
		return <div className="">

				<DiagError key={"diag"} etat={this.state.etat} mess={this.state.mess} callback={this.callbackError}></DiagError>
				<Backdrop className="backdrop" open={this.state.loading}>
					<CircularProgress color="inherit" />
				</Backdrop>
				<h2>Les autres missions du CERT :</h2>
				<h3> </h3>
				<table>
					<tbody>
					{ 
					/* */
					/* */
					/* */
					/* */
					/* */
					}
					<tr>
						<td>
							<b>KM CERT :</b>
						</td>
					</tr>
					<tr>
						<td>
							Client :
						</td>
						<td>
							<TextField id="client_1" disabled={true} required onChange={this.bindedTextFieldChange} inputProps={{"data-indice":0}} value={"Wavestone"} />
						</td>
						<td>
							Nom de la mission :
						</td>
						<td>
							<TextField id="mission_1" required onChange={this.bindedTextFieldChange} inputProps={{"data-indice":0}} value={this.state.nomMission[0]} />
						</td>
						<td>
							<Autocomplete 
								id={"Auditeur"}
								autoselect={this.state.cp[0]}
								sx={{width:180}}
								renderOption={(props, option) => {
								return (
									<li {...props} data-indice={0} key={option.id}>
										{option.prenom + " " + option.nom + " ("+option.trigramme+")"}
									</li>
									);
								}}
							className={"MissionsAuditAutre_Auditeur"}
							ListboxProps={{ sx: { fontSize: ".7em" } }}
							options={this.listeStaffable}
							onChange={this.bindedTextFieldAuditeurChange}
							getOptionLabel={ (option) => option.prenom + " " + option.nom + " ("+option.trigramme+")"}
							value={this.listeStaffable.find(u => u.id === this.state.cp)}
							renderInput={(params) => <TextField {...params} sx={{fontSize: ".7em" }} fullWidth required id={"auditeur_area_1"} label="CP"  /> }
							/>
						</td>
						<td>
							<Button disabled={this.state.disableButtons} data-indice={0} data-type="km" onClick={this.bindedCreationMission} variant="contained">Ajouter</Button>
						</td>
					</tr>
					{ 
					/* */
					/* */
					/* */
					/* */
					/* */
					}
					<tr>
						<td>
							<b>Formation CERT :</b>
						</td>
					</tr>
					<tr>
						<td>
							Client :
						</td>
						<td>
							<TextField id="client_2" disabled={true} value={"Wavestone"}  />
						</td>
						<td>
							Nom du sujet :
						</td>
						<td>
							<TextField id="mission_2" required onChange={this.bindedTextFieldChange} inputProps={{"data-indice":1}} value={this.state.nomMission[1]} />
						</td>
						<td>
							<Autocomplete 
								id={"Auditeur"}
								autoselect={this.state.cp[1]}
								sx={{width:180}}
								renderOption={(props, option) => {
								return (
									<li {...props} data-indice={1} key={option.id}>
										{option.prenom + " " + option.nom + " ("+option.trigramme+")"}
									</li>
									);
								}}
							className={"MissionsAuditAutre_Auditeur"}
							ListboxProps={{ sx: { fontSize: ".7em" } }}
							options={this.listeStaffable}
							onChange={this.bindedTextFieldAuditeurChange}
							getOptionLabel={ (option) => option.prenom + " " + option.nom + " ("+option.trigramme+")"}
							value={this.listeStaffable.find(u => u.id === this.state.cp)}
							renderInput={(params) => <TextField {...params} sx={{fontSize: ".7em" }} fullWidth required id={"auditeur_area_2"} label="CP"  /> }
							/>
						</td>
						<td>
							<Button disabled={this.state.disableButtons} data-indice={1} data-type="formation" onClick={this.bindedCreationMission} variant="contained">Ajouter</Button>
						</td>
					</tr><tr>
						<td>
							<b>Stage CERT :</b>
						</td>
					</tr>
					<tr>
						<td>	
							Client :
						</td>
						<td>
							<TextField id="client_3" disabled={true} value={"Wavestone"}  />
						</td>
						<td>
							Sujet de stage :
						</td>
						<td>
							<TextField id="mission_3" required onChange={this.bindedTextFieldChange} inputProps={{"data-indice":2}} value={this.state.nomMission[2]} />
						</td>
						<td>
							<Autocomplete 
								id={"Auditeur"}
								autoselect={this.state.cp[2]}
								sx={{width:180}}
								renderOption={(props, option) => {
								return (
									<li {...props} data-indice={2} key={option.id}>
										{option.prenom + " " + option.nom + " ("+option.trigramme+")"}
									</li>
									);
								}}
							className={"MissionsAuditAutre_Auditeur"}
							ListboxProps={{ sx: { fontSize: ".7em" } }}
							options={this.listeStagiaires}
							onChange={this.bindedTextFieldAuditeurChange}
							getOptionLabel={ (option) => option.prenom + " " + option.nom + " ("+option.trigramme+")"}
							value={this.listeStaffable.find(u => u.id === this.state.cp)}
							renderInput={(params) => <TextField {...params} sx={{fontSize: ".7em" }} fullWidth required id={"auditeur_area_3"} label="Stagiaire"  /> }
							/>
						</td>
						<td>
							<Button disabled={this.state.disableButtons} data-indice={2} data-type="stage" onClick={this.bindedCreationMission} variant="contained">Ajouter</Button>
						</td>
						</tr>
						
						{ 
						/* */
						/* */
						/* */
						/* */
						/* */
						}
					<tr>
						<td>
							<b>R&D CERT :</b>
						</td>
						</tr>
						<tr>
						<td>
							Client :
						</td>
						<td>
							<TextField id="client_4" disabled={true} value={"Wavestone"}  />
						</td>
						<td>
							Nom du sujet :
						</td>
						<td>
							<TextField id="mission_4" required onChange={this.bindedTextFieldChange} inputProps={{"data-indice":3}} value={this.state.nomMission[3]} />
						</td>
						<td>
							<Autocomplete 
								id={"Auditeur"}
								autoselect={this.state.cp[3]}
								sx={{width:180}}
								renderOption={(props, option) => {
								return (
									<li {...props} data-indice={3} key={option.id}>
										{option.prenom + " " + option.nom + " ("+option.trigramme+")"}
									</li>
									);
								}}
							className={"MissionsAuditAutre_Auditeur"}
							ListboxProps={{ sx: { fontSize: ".7em" } }}
							options={this.listeStaffable}
							onChange={this.bindedTextFieldAuditeurChange}
							getOptionLabel={ (option) => option.prenom + " " + option.nom + " ("+option.trigramme+")"}
							value={this.listeStaffable.find(u => u.id === this.state.cp)}
							renderInput={(params) => <TextField {...params} sx={{fontSize: ".7em" }} fullWidth required id={"auditeur_area_4"} label="CP"  /> }
							/>
						</td>
						<td>
							<Button disabled={this.state.disableButtons} data-indice={3} data-type="rd" onClick={this.bindedCreationMission} variant="contained">Ajouter</Button>
						</td>
					</tr>
					
					{ 
					/* */
					/* */
					/* */
					/* */
					/* */
					}
					
					<tr>
						<td><br />####################<br /></td>
					</tr>
					
					<tr>
						<td>
							<b>Prod CERT :</b>
						</td>
					</tr>
					<tr>
						<td>
							Client :
						</td>
						<td>
							<TextField id="client_5"  required onChange={this.bindedTextFieldChange} inputProps={{"data-indice":4}} value={this.state.nomClient[4]}  />
						</td>
						<td>
							Nom de la mission :
						</td>
						<td>
							<TextField id="mission_5" required onChange={this.bindedTextFieldChange} inputProps={{"data-indice":4}} value={this.state.nomMission[4]} />
						</td>
						<td>
							<Autocomplete 
								id={"Auditeur"}
								autoselect={this.state.cp[4]}
								sx={{width:180}}
								renderOption={(props, option) => {
								return (
									<li {...props} data-indice={4} key={option.id}>
										{option.prenom + " " + option.nom + " ("+option.trigramme+")"}
									</li>
									);
								}}
							className={"MissionsAuditAutre_Auditeur"}
							ListboxProps={{ sx: { fontSize: ".7em" } }}
							options={this.listeStaffable}
							onChange={this.bindedTextFieldAuditeurChange}
							getOptionLabel={ (option) => option.prenom + " " + option.nom + " ("+option.trigramme+")"}
							value={this.listeStaffable.find(u => u.id === this.state.cp)}
							renderInput={(params) => <TextField {...params} sx={{fontSize: ".7em" }} fullWidth required id={"auditeur_area_5"} label="CP"  /> }
							/>
						</td>
						<td>
							<Button disabled={this.state.disableButtons} data-indice={4} data-type="prod" onClick={this.bindedCreationMission} variant="contained">Ajouter</Button>
						</td>
					</tr>
					</tbody>
				</table>
				
			   </div>
		
	}
	
} export default LesMissions_Cert;


	
