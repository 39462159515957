import React, { Component } from "react";
import {Route, NavLink, HashRouter} from "react-router-dom";
 
import "./Menu.css";

 
class Menu extends Component {
	
	constructor(props) {
		super(props);
		this.state = {m1: "plie", m2: "plie", m3: "plie", m4: "plie"};
	}
	
	plierMenus()
	{
		this.setState({m1: "plie", m2: "plie", m3: "plie", m4: "plie"});
	}
	
	handleClick(d) 
	{
		this.plierMenus();
		this.setState({[d]: "deplie"});
	}
	
  render() {
    var visibility = "hide";
 
    if (this.props.menuVisibility)
	{
      visibility = "show";
    }

 
    return (
      <div id="flyoutMenu" className={visibility}>
          <ul>
            <li>
				<div className="arrow_block">
					<div className={"arrow arrow_" + this.state.m1}></div>
				</div>
				<a onClick={() => this.handleClick("m1")} to="/">Ma PAP</a>
			</li>
			<NavLink className={"navLink navLink_" + this.state.m1} to="/PapPerso">Ma PAP</NavLink>
			<NavLink className={"navLink navLink_" + this.state.m1} to="/MissionsPerso">Mes missions</NavLink>

			<li>
				<div className="arrow_block">
					<div className={"arrow arrow_" + this.state.m2}></div>
				</div>
				<a onClick={() => this.handleClick("m2")} to="/">Audit</a>
			</li>
			<NavLink className={"navLink navLink_" + this.state.m2} to="/LesAV_Audit">Avant-Vente</NavLink>
			<NavLink className={"navLink navLink_" + this.state.m2} to="/LesMissionsTrimestre_Audit">Missions / Trimestre</NavLink>
			<NavLink className={"navLink navLink_" + this.state.m2} to="/ToutesLesMissions_Audit">Toutes les missions</NavLink>
			<NavLink className={"navLink navLink_" + this.state.m2} to="/PDC_Audit">Le PDC</NavLink>
			
			<li>
				<div className="arrow_block">
					<div className={"arrow arrow_" + this.state.m3}></div>
				</div>
				<a onClick={() => this.handleClick("m3")} to="/">CERT</a>
			</li>
			<NavLink className={"navLink navLink_" + this.state.m3} to="/LesAV_CERT">Devis / Avant-Vente</NavLink>
			<NavLink className={"navLink navLink_" + this.state.m3} to="/ToutesLesMissions_Cert">Toutes les missions</NavLink>
			<NavLink className={"navLink navLink_" + this.state.m3} to="/PDC_CERT">Le PDC</NavLink>
			
			
			<li>
				<div className="arrow_block">
					<div className={"arrow arrow_" + this.state.m4}></div>
				</div>
				<a onClick={() => this.handleClick("m4")} to="/">BT AIR</a>
			</li>
			<NavLink className={"navLink navLink_" + this.state.m4} to="/LesPAP">Les PAP</NavLink>
			<NavLink className={"navLink navLink_" + this.state.m4} to="/LaDreamTeam">La Dream Team</NavLink>
			<NavLink className={"navLink navLink_" + this.state.m4} to="/LesStats">Les stats</NavLink>
			<NavLink className={"navLink navLink_" + this.state.m4} to="/SMSI">Le SMSI</NavLink>
			<NavLink className={"navLink navLink_" + this.state.m4} to="/SMSI_2">Le SMSI 2</NavLink>
			
			</ul>
      </div>
    );
  }
}
 
export default Menu;