import React, { Component } from "react";

import { appelAction, DiagError } from './utils.js';

import "./LeSMSI.css";


class LeSMSI extends Component
{
	
	constructor(props)
	{
		super(props);
		this.state = {etat:0, mess:"ok", missions:[]};
	}
	
	handleCallback  = (obj) =>
	{
		if (obj.etat === 4) // erreur 
			this.setState({etat:obj.etat, mess:obj.mess});
		else
		{
			this.setState({missions:obj.data.missions});
		}
	}
	
	callbackError = (obj) =>
	{
		this.setState({etat:0});
	}	
	
	componentDidMount()
	{
		var urlOptionsSMSI= "ctrl=ControleurSMSI&methode=voir";
		var requestOptionsSMSI = {method: "GET", body:""};
	
		appelAction(urlOptionsSMSI, requestOptionsSMSI, this.handleCallback);
	}
	
	
	render() 
	{
		return( <div className={"c_smsi_container"}>
					<DiagError key={"diag"} etat={this.state.etat} mess={this.state.mess} callback={this.callbackError}></DiagError>
					{this.state.missions.map(miss => 
						{return <div className={"c_smsi_ligne"}>
									<div className={"c_smsi_case_c"}>{miss.mission.nomC}</div>
									<div className={"c_smsi_case_m"}>{miss.mission.nomM}</div>
									<div className={"c_smsi_case_d"}>{miss.date}</div>
									<div className={"c_smsi_ss_ligne"}>
									{miss.menageX.map(menageX => 
										{return <React.Fragment>
												<div className={"c_smsi_case_u"}>{menageX.user.nom +" "+menageX.user.prenom}</div>
												<div className={"c_smsi_case_p"}>{menageX.update}</div>
												<div className={"c_smsi_case_e"}>{menageX.etat}</div>
												</React.Fragment>
										}
									)}
									</div>
						</div>}
					)}
					
				</div>
			);
	}
	
}

export default LeSMSI;