
import React, { Component } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/icons-material/Apartment';
import Switch from '@mui/material/Switch';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AdapterDateFns from '@date-io/date-fns';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import {fr} from 'date-fns/locale';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ApartmentIcon from '@mui/icons-material/Apartment';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import DescriptionIcon from '@mui/icons-material/Description';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

import { setWeek, setYear, getMonth } from 'date-fns';

import { appelAction, DiagError, NOMS_DES_MOIS } from './utils.js';
import { listeClients } from './index.js';

import "./MissionsTrimestre_Audit.css";
 
class MissionsTrimestre_Audit extends Component {
	

  

	constructor(props)
	{
		super(props);
		var dateNow = new Date();
		
		var trimestres = [];
		// 202200 => T1 2022 13  202205 => T2 2022 26  202211 => T4 2022 52
		// 13 + (mois/3)*13
		var indice=dateNow.getFullYear()*100+Math.floor(dateNow.getMonth()/3)*13+13;
		var premierTour=true;
		for (indice; indice>202100; indice-=13)
		{	
			
			if (indice%100===0)
			{
				indice=(Math.floor(indice/100-1)*100)+52;
			}
			var txt = "T"+Math.floor((indice%100)/13)+" "+Math.floor(indice/100);
			if (premierTour)
			{
				txt += " et au dela...";
				premierTour=false;
			}
			trimestres.push({visible:false, ihm: txt,
					annee:Math.floor(indice/100),semaine:indice%100-13})
		
		}
		this.state = {etat:0, mess:"ok", disableButtons: false, nomMissionFille:"", descMissionFille:"",
				trimestres:trimestres, loading:false, selectionDatePopUp:false, creationFillePopUp:false};
		
		this.bindedChangeAccordeon = this.handleChangeAccordeon.bind(this);
		this.bindedClickAction = this.handleClickAction.bind(this);
		this.bindedClickChangerDatePopUp = this.handleClickChangerDatePopUp.bind(this);
		this.bindedChangeDateFin = this.handleChangeDateFin.bind(this);
		this.bindedFermerChangerDatePopUp = this.handleFermerChangeDateFin.bind(this);
		this.bindedFermerCreationFille =  this.handleFermerCreationFille.bind(this);
		this.bindedClickCreationMissionFille =  this.handleClickCreationMissionFille.bind(this);
		this.bindedTextFieldChange = this.handleTextFieldChange.bind(this);
		this.bindedChangeVisibleMissionFille = this.handleChangeVisibleMissionFille.bind(this);
	}

  	// appele par le composant errordialog pour ack le message => chgt d'etat
	callbackError = () => 
	{
		this.setState({etat:0});
	}
	
	handleCallback = (obj) =>
	{
	  if (obj.etat === 4) // erreur et on garde le truc ouvert
		this.setState({etat:obj.etat, mess:obj.mess});
	  else
	  {
		  if (obj.data && !isNaN(obj.data.id))
		  {
				var trim = this.state.trimestres.slice();
				for (var i=0;i<obj.data.data.length;i++)
				{
					var v = trim[obj.data.id].data[i].missionFillesVisibles;
					trim[obj.data.id].data[i] = obj.data.data[i];
					trim[obj.data.id].data[i].missionFillesVisibles = v;
				}
				this.setState({trimestres: trim, loading:false});			
		  }
	  }
	  
	}
	
	handleCallbackVoir = (obj) =>
	{
	  if (obj.etat === 4) // erreur et on garde le truc ouvert
		this.setState({etat:obj.etat, mess:obj.mess, loading:false});
	  else
	  {
		var trim = this.state.trimestres.slice();
		trim[obj.data.id].visible = true;
		trim[obj.data.id].data = obj.data.data;
		for (var i=0;i<trim[obj.data.id].data.length;i++)
		{
			trim[obj.data.id].data[i].missionFillesVisibles = false;
		}
		this.setState({trimestres: trim, loading:false});
	  }
	}
	
	/**
	/**
    
	fonctions pour le changement des accordéons

    **
    **
	**/

	handleChangeVisibleMissionFille(e)
	{
		var trim = this.state.trimestres.slice();
		trim[e.currentTarget.dataset.tab].data[e.currentTarget.dataset.ligne].missionFillesVisibles = ! trim[e.currentTarget.dataset.tab].data[e.currentTarget.dataset.ligne].missionFillesVisibles;
		this.setState({trimestres: trim});
	}

	listeMissions (id) 
	{
		var urlOptionsListeMission = "ctrl=ControleurMissionsAuditTrimestre&methode=voir";
		urlOptionsListeMission += "&annee="+this.state.trimestres[id].annee;
		urlOptionsListeMission += "&semaine="+this.state.trimestres[id].semaine;
		urlOptionsListeMission += "&id="+id;
		
		var requestOptionsListeMission = {method: "GET", body:""};
		
		this.setState({loading:true});
		appelAction(urlOptionsListeMission, requestOptionsListeMission, this.handleCallbackVoir);
	}
	
	
	handleChangeAccordeon(e, v)
	{
		var id = e.currentTarget.id.substr(e.currentTarget.id.indexOf("_")+1);
		if (v)
			this.listeMissions(id);
		else
		{
			var trim = this.state.trimestres.slice();
			trim[id].visible = v;
			this.setState({trimestres: trim});
		}
	}

	/**
	/**
    
	fonctions pour le click sur les boutons à droite

    **
    **
	**/
	
	handleClickAction (e, v)
	{
		var reste = e.currentTarget.id;
		var action = reste.substr(0, reste.indexOf("_"));
		reste = reste.substr(reste.indexOf("_")+1);
		var id = reste.substr(0, reste.indexOf("_"));
		reste = reste.substr(reste.indexOf("_")+1);
		var idmiss = reste.substr(0, reste.indexOf("_"));
		var miss = reste.substr(reste.indexOf("_")+1);

//		alert(action + " " + id + " " + idmiss + " " + miss);

		if (action==="pdc")
		{ 
			var urlOptionsListeMission = "ctrl=ControleurMissionsAuditTrimestre&methode=staffer";
			var requestOptionsListeMission = {method: "POST", body:"id="+id+"&miss="+miss};
	
			appelAction(urlOptionsListeMission, requestOptionsListeMission, this.handleCallback);
			return;
		}
		if (action==="fille")
		{
			this.setState({trimestreEnCours : id, cleMissEnCours : miss, creationFillePopUp:true});
			return;
		}
		if (action==="date")
		{
			var date = new Date();
			date = setWeek(date, this.state.trimestres[id].data[idmiss].dateSFin);
			date = setYear(date, this.state.trimestres[id].data[idmiss].dateAFin);

			this.setState({  dateMoisFin: (date.getMonth()+1) , dateAnneeFin : this.state.trimestres[id].data[idmiss].dateAFin,
							 trimestreEnCours : id, cleMissEnCours : miss,
							 selectionDatePopUp:true});
			return;
		}

	}

 	/**
	/**
    
	fonctions pour la craetion d'une mission fille

    **
    **
	**/

	handleTextFieldChange(e, v)
	{
	
		if (e.currentTarget.id.startsWith("mission"))
		{
			this.setState({nomMissionFille: e.currentTarget.value});
		}
		else if (e.currentTarget.id.startsWith("desc"))
		{
			this.setState({descMissionFille: e.currentTarget.value});
		}
	}
	
	
	handleFermerCreationFille()
	{
		this.setState({creationFillePopUp:false});
	}

	handleClickCreationMissionFille()
	{
		this.setState({creationFillePopUp:false});

		var urlOptionsListeMission = "ctrl=ControleurMissionsAuditTrimestre&methode=creer";
		var requestOptionsListeMission = {method: "POST", body:"id="+this.state.trimestreEnCours+"&miss="+this.state.cleMissEnCours
											+"&nomMissionFille="+this.state.nomMissionFille.sansEtCom()+"&descMissionFille="+this.state.descMissionFille.sansEtCom()};
	
		appelAction(urlOptionsListeMission, requestOptionsListeMission, this.handleCallback);
	}

	/**
	/**
    
	fonctions pour le changement de date

    **
    **
	**/

	handleFermerChangeDateFin()
	{
		this.setState({selectionDatePopUp:false});
	}

	handleClickChangerDatePopUp()
	{
		this.setState({selectionDatePopUp:false});

		var urlOptionsListeMission = "ctrl=ControleurMissionsAuditTrimestre&methode=changer";
		var requestOptionsListeMission = {method: "POST", body:"id="+this.state.trimestreEnCours+"&miss="+this.state.cleMissEnCours+"&mois="+this.state.dateMoisFin+"&annee="+this.state.dateAnneeFin};
	
		appelAction(urlOptionsListeMission, requestOptionsListeMission, this.handleCallback);
	}

	handleChangeDateFin(e)
	{
		var fin = new Date(e);
		this.setState({dateMoisFin: (fin.getMonth()+1), dateAnneeFin: fin.getFullYear()})

		return;
	}


	/**
	/**
    
	fonctions pour le rendu du composant
    **
    **
	**/


	render()
	{
	
	var indice=-1;
    return (
        <div className="">
          <h2>Les missions d&apos;audit par trimestre :</h2>
          <DiagError key={"diag"} etat={this.state.etat} mess={this.state.mess} callback={this.callbackError}></DiagError>
          <Backdrop className="backdrop" open={this.state.loading}>
              <CircularProgress color="inherit" />
          </Backdrop>

            <Dialog onClose={this.bindedFermerChangerDatePopUp}
              open={this.state.selectionDatePopUp}
              aria-describedby="choix du planning"
            >
              <DialogTitle>Selection de la fin de la mission :</DialogTitle>
              <DialogContent>
              <DialogContentText sx={{pb:2}}>
                  Date de fin :
                </DialogContentText>
                
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                  <DesktopDatePicker
                      id='dateFin'
                      minDate={new Date().setFullYear(new Date().getFullYear()-2)}
                      maxDate={new Date().setFullYear(new Date().getFullYear()+5)}
                      views={['month','year']}
                      label="Fin (MM/YYYY)"
                      value = {this.state.dateMoisFin+"/01/"+this.state.dateAnneeFin}
                      inputFormat = "MM/yyyy"
                      onChange = {this.bindedChangeDateFin}
                      renderInput={(params) => <TextField {...params} helperText={null} />}
                      />
                  </LocalizationProvider>
                
              </DialogContent>
              <DialogActions>
                <Button onClick={this.bindedClickChangerDatePopUp}>Go !</Button>
              </DialogActions>
            </Dialog>

          <Dialog onClose={this.bindedFermerCreationFille}
              open={this.state.creationFillePopUp}
              aria-describedby="choix du planning"
            >
              <DialogTitle>Création d'une mission fille :</DialogTitle>
              <DialogContent>
              
                  <TextField sx={{mt:2, pb: 2}} fullWidth  required id="mission" label="Nom de la mission" value={this.state.nomMissionFille} 	
                      onChange={this.bindedTextFieldChange}
                  InputProps={{
                      startAdornment: <InputAdornment position="start"><FolderOpenIcon/></InputAdornment>
                      
                  }} />
                  
                  <TextField fullWidth id="description" multiline={true} rows={4} label="Description" value={this.state.descMissionFille} 
                      onChange={this.bindedTextFieldChange}
                  InputProps={{
                      startAdornment: <InputAdornment position="start"><DescriptionIcon/></InputAdornment>,
                  }}
                  />
                
              </DialogContent>
              <DialogActions>
                <Button onClick={this.bindedClickCreationMissionFille}>Go !</Button>
              </DialogActions>
            </Dialog>

          
            
          <form className="" noValidate>
          
          {/* block par trimestre */}
          {!this.state.creationFillePopUp&&this.state.trimestres.map( trim => {
              indice++;
              var indice_mission = -1;
              
              return (
                  <Accordion key={"MT_acc_key_"+trim.ihm} expanded={trim.visible} onChange={this.bindedChangeAccordeon}>
                      <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id={"Acc_"+indice} >
                      <Typography>{trim.ihm}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                          {trim.data&&trim.data.map( miss => 
                          {
                              indice_mission++;
                              var date = new Date();
                              date = setWeek(date, miss.dateSFin);
                              date = setYear(date, miss.dateAFin);
                              var indice_fille = -1;
                              var indice_user = -1;
                              var res=[];
                              res.push(<div key={"k_"+indice+"_"+indice_mission} className={"MT_ligne_mission"}>
                                          {(miss.filles.length == 0)&&<div key={"plusmoins_"+indice+"_"+indice_mission}></div>}
                                          {(miss.filles.length > 0)&&<div key={"plusmoins_"+indice+"_"+indice_mission} data-tab={indice} data-ligne={indice_mission} onClick={this.bindedChangeVisibleMissionFille}>{(miss.missionFillesVisibles?"-":"+")}</div>}
                                          <div className={"MT_ligne_mission_case_type"} >{miss.mission.typeM.replace("AUDIT_","")}</div>
                                          <div>{miss.mission.nomC}</div>
                                          <div>{miss.mission.nomM}</div>
                                          <div>{NOMS_DES_MOIS[date.getMonth()] + " " + miss.dateAFin}</div>
                                          <div>{miss.propale.roles + " " + miss.propale.jours + " " + miss.propale.tjs}</div>
                                          <div className={"MT_case_users"}>
                                              {miss.users.map( u => 
                                              {
                                                  indice_user++;
                                                  return <div key={indice_mission+"_"+u.trigramme+"_"+indice_user}>{u.prenom + " " + u.nom.charAt(0) + ". ("+u.trigramme+")"}</div>
                                              })}											
                                          </div>
                                          <div className={"MT_case_boutons"}>
                                              <div>
                                              <Tooltip title="Passer dans le PDC"><IconButton
                                                  aria-label="PDC"
                                                  id={"pdc_"+indice+"_"+indice_mission+"_"+miss.mission.idMiss}
                                                  onClick={this.bindedClickAction}
                                                  size="large"><ShoppingCartCheckoutIcon /></IconButton></Tooltip>
                                              </div>
                                              <div>
                                              <Tooltip title="Changer la date de fin"><IconButton
                                                  aria-label="Fin"
                                                  id={"date_"+indice+"_"+indice_mission+"_"+miss.mission.idMiss}
                                                  onClick={this.bindedClickAction}
                                                  size="large"> <DateRangeIcon /> </IconButton></Tooltip>
                                              </div>
                                              <div>
                                              <Tooltip title="Créer une mission fille"><span><IconButton
                                                  disabled={!(miss.mission.typeM==="AUDIT_PROD"||miss.mission.typeM==="AUDIT_IMPROD")}
                                                  aria-label="fille"
                                                  id={"fille_"+indice+"_"+indice_mission+"_"+miss.mission.idMiss}
                                                  onClick={this.bindedClickAction}
                                                  size="large"> <EscalatorWarningIcon /> </IconButton></span></Tooltip>
                                              </div>
                                          </div>
                                      </div>);
                                      (miss.filles.length > 0 && miss.missionFillesVisibles && miss.filles.map( fille =>
                                       {
                                          indice_fille++;
                                          res.push(
                                              <div key={indice_mission+"_"+indice_fille} className={"MT_ligne_fille"}>
                                                  <div><DoubleArrowIcon /></div>
                                                  <div>{fille.fille.nomM} {(fille.fille.typeM.indexOf("KM")>0)&&"(KM)"}</div>
                                                  <div className={"MT_case_users"}>
                                                  {fille.users.map( u => 
                                                  {
                                                      return <div key={indice_mission+"_"+indice_fille+"_"+u.trigramme}>{u.prenom + " " + u.nom.charAt(0) + ". ("+u.trigramme+")"}</div>
                                                  })}
                                                  </div>	
                                                  {(fille.fille.typeM.indexOf("KM")<0)&&<div><Tooltip title="Passer dans le PDC"><IconButton
                                                      aria-label="PDC"
                                                      id={"pdc_"+indice+"_"+indice_mission+"-"+indice_fille+"_"+fille.fille.idMiss}
                                                      onClick={this.bindedClickAction}
                                                      size="large"><ShoppingCartCheckoutIcon /></IconButton></Tooltip></div>}
                                                  {(fille.fille.typeM.indexOf("KM")>0)&&<div></div>}
                                              </div>										
                                          );
                                       })) 
                                  return <div key={"div_mt_"+indice_mission} className={(indice_mission%2==0)?"MT_ligne_pair":"MT_ligne_impair"}>{res}</div>;
                          })}
                      </AccordionDetails>
                      </Accordion>
              );
          })}
          </form>
      </div>
    );
	}
	
} 
export default MissionsTrimestre_Audit;