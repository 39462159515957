import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from "react";
import "./Window.css";
const nextZIndex = () => {
    return 1000;
};
const Window = (props) => {
    let properties = Object.assign({
        id: props.id && props.id.length ? props.id : Date.now().toString(),
        children: null,
        height: 0,
        width: 0,
        top: 0,
        left: 0,
		minimized: true,
        resizable: true,
		contentDisplay: false,
        titleBar: Object.assign({
            icon: " ",
            title: "Untitled window",
            buttons: Object.assign({
                minimize: true,
                maximize: true,
                close: true,
            }, (props.titleBar && props.titleBar.buttons) || {}),
        }, props.titleBar),
        style: {},
    }, props);
    if (!properties.id) {
        properties.id = Date.now().toString();
    }
    Object.freeze(properties);
    const [height, setHeight] = React.useState(properties.height);
    const [width, setWidth] = React.useState(properties.width);
    const [top, setTop] = React.useState(properties.top || 20);
    const [left, setLeft] = React.useState(document.getElementById(properties.id) ? document.getElementById(properties.id).parentElement.clientWidth - 200 : 1000);
    const [xOffset, setXOffset] = React.useState(0);
    const [yOffset, setYOffset] = React.useState(0);
    const [resizable, setResizable] = React.useState(properties.resizable || false);
    const [minimized, setMinimized] = React.useState(true);
    const [maximized, setMaximized] = React.useState(false);
    const [minimizeIcon, setMinimizeIcon] = React.useState("▁");
    const [maximizeIcon, setMaximizeIcon] = React.useState("□");
    const [contentDisplay, setContentDisplay] = React.useState(false);
    const [windowTransition, setWindowTransition] = React.useState("");
    const [level, setLevel] = React.useState(nextZIndex());
    const [visibility, setWindowVisibility] = React.useState(1.0);
    const container = React.useRef(null);
    const windowTitle = React.useRef(null);
    const effectiveHeight = useRef(32);
    const effectiveWidth = useRef(200);
    const animationDuration = 500;
    const handleDragStart = (e) => {
        setYOffset(e.clientY - top);
        setXOffset(e.clientX - left);
        setLevel(nextZIndex());
        setWindowVisibility(0.5);
    };
    const handleDrag = (e) => {
        setLeft((e.clientX || e.screenX || left + xOffset) - xOffset);
        setTop((e.clientY || e.screenY || top + yOffset) - yOffset);
    };
    const handleDragEnd = (e) => {
        setLeft((e.clientX || e.screenX) - xOffset);
        setTop((e.clientY || e.screenY) - yOffset);
        setWindowVisibility(1.0);
    };
    const minimize = () => {
	    setWindowTransition(`${animationDuration}ms ease-in-out`);
        const parent = document.getElementById(properties.id).parentElement;	
		var widthMini = properties.width;
		
		if (minimized) {
            setContentDisplay(true);
            effectiveHeight.current = height;
		    setLeft(100);
            setMinimized(false);
			setResizable(true);
        }
        else {
			widthMini = 300;
			setContentDisplay(false);
            effectiveHeight.current = 32;
            setLeft(parent.clientWidth - 300);
            setMinimized(true);
			setResizable(false);
        }
		
		setWidth(widthMini);
		effectiveWidth.current = widthMini;
        setMinimizeIcon("_");
        setMaximized(false);
		setTop(20);
        setLevel(nextZIndex());
        setTimeout(setWindowTransition, animationDuration, "");
    };
    const maximize = () => {
        var _a;
        setWindowTransition(`${animationDuration}ms ease-in-out`);
        const parent = (_a = document.getElementById(properties.id)) === null || _a === void 0 ? void 0 : _a.parentElement;
        if (maximized) {
			console.log("youhou 2");
            setContentDisplay(true);
            effectiveHeight.current = height;
            effectiveWidth.current = width;
            setTop((parent === null || parent === void 0 ? void 0 : parent.offsetTop) || 0);
            setLeft((parent === null || parent === void 0 ? void 0 : parent.offsetLeft) || 0);
            setMaximized(false);
            setMaximizeIcon("□");
            setMinimized(false);
            setMinimizeIcon("_");
			setWidth(1000);			
			setResizable(true);
        }
        else {
			console.log("youhou");
            setContentDisplay(true);
            effectiveHeight.current = (parent === null || parent === void 0 ? void 0 : parent.clientHeight) || window.innerHeight;
            effectiveWidth.current = (parent === null || parent === void 0 ? void 0 : parent.clientWidth) || window.innerWidth;
            setTop((parent === null || parent === void 0 ? void 0 : parent.offsetTop) || 0);
            setLeft((parent === null || parent === void 0 ? void 0 : parent.offsetLeft) || 0);
            setMaximized(true);
            setMaximizeIcon("❐");
            setMinimized(false);
            setMinimizeIcon("_");	
			setWidth(1000);			
			setResizable(true);
			
        }
        setLevel(nextZIndex());
        setTimeout(setWindowTransition, animationDuration + 1, "");
    };
		
	
    return (_jsxs("div", Object.assign({ id: properties.id, className: "window-container", style: {
            height: effectiveHeight.current,
            width: effectiveWidth.current,
            top,
            left,
            resize: resizable ? "both" : "none",
            transition: windowTransition,
            zIndex: level,
            opacity: visibility,
        }, ref: container, onClick: () => {
            setLevel(nextZIndex());
        } }, { children: [properties.titleBar && (_jsxs("div", Object.assign({ className: "title-bar", "data-parent": properties.id, style: {
                    opacity: visibility,
                } }, { children: [properties.titleBar.icon && (_jsx("span", Object.assign({ className: "icon" }, { children: properties.titleBar.icon }))), _jsx("span", Object.assign({ className: "windowTitle", draggable: true, onDragStart: handleDragStart, onDrag: handleDrag, onDragEnd: handleDragEnd, style: { opacity: Math.floor(visibility) }, ref: windowTitle }, { children: properties.titleBar.title })), properties.titleBar.buttons && (_jsxs("span", Object.assign({ className: "buttonContainer" }, { children: [properties.titleBar.buttons.minimize && (_jsx("span", Object.assign({ className: "windowButton", onClick: minimize }, { children: minimizeIcon }))), properties.titleBar.buttons.maximize && (_jsx("span", Object.assign({ className: "windowButton", onClick: maximize }, { children: maximizeIcon }))), !!properties.titleBar.buttons.close && (_jsx("span", Object.assign({ className: "windowButton", onClick: properties.titleBar.buttons.close }, { children: "\u2A2F" })))] })))] }))), _jsx("div", Object.assign({ className: "content", draggable: "false", style: Object.assign({ height: contentDisplay ? "auto" : 0, opacity: visibility }, properties.style) }, { children: properties.children }))] })));
};
export default Window;
