import React, { Component } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { appelAction } from './utils.js';


class DiagMenageLocalSMSI extends Component {


  constructor(props)
  {
		super(props);
		this.state = {isOpen: true, id:this.props.id, nomM: this.props.nomM, nomC: this.props.nomC };
		
		this.handleCallBack = this.props.callback;
		this.bindedMenageOK = this.handleMenageOK.bind(this);
		this.bindedMenageKO = this.handleMenageKO.bind(this);
		
  }
  
  handleMenageOK()
  {
	  var urlSMSI = "ctrl=ControleurSMSI&methode=changer";
	  var urlSMSIOptions = {method: "POST", body:"menage=true"};
	  appelAction(urlSMSI, urlSMSIOptions, this.handleCallBack);
  }
  
  handleMenageKO()
  {
	  var urlSMSI = "ctrl=ControleurSMSI&methode=changer";
	  var urlSMSIOptions = {method: "POST", body:"menage=false"};
	  this.setState({isOpen : false}, appelAction(urlSMSI, urlSMSIOptions, this.handleCallBack));
  }
  
  componentDidUpdate(prevProps)
  {
	if ( !this.state.isOpen || this.props.id !== prevProps.id)
	{
		this.setState({isOpen : true, nomM: this.props.nomM, nomC: this.props.nomC});
	}
  }

  handleClose = () => {
    this.setState({isOpen : false});
  };
	
	render() 
	{
   		
		return (<Dialog open={this.state.isOpen} onClose={this.props.callback}> 
					<DialogTitle>C'est le rappel du ménage SMSI !</DialogTitle>
					<DialogContent>
						<DialogContentText>
					La mission "{this.state.nomM}" pour "{this.state.nomC}" est terminée depuis bientot 3 mois... Il est temps de faire le ménage <i>(ou de demander au CP de décaler si besoin)</i>
						</DialogContentText>			  
					</DialogContent>
					<DialogActions>
						<Button variant="contained" color="success" onClick={this.bindedMenageOK}>J'ai fait le ménage !</Button>
						<Button variant="contained" color="error" onClick={this.bindedMenageKO}>Je n'ai pas encore fait le ménage ...</Button>
					</DialogActions>
				</Dialog>
		);
	
	};
}
export default DiagMenageLocalSMSI;
