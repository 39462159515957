
import React, { Component } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Window from './Window.js';

import "./CRaudit.css";

class CRaudit extends Component
{
	
	constructor(props)
	{
		super(props);
		
		this.bindedChangeTab = this.handleChangeTab.bind(this); 
	}
	
	handleChangeTab()
	{
		
	}


	render ()
	{
		return <Window
					id="react-window"
					height={800}
					width={1000}
					titleBar={{
						icon: "⚛",
						title: "CR",
						buttons: { minimize: true, maximize: true },
					}}
					>
					<div>
							<Tabs value={0} onChange={this.bindedChangeTab} aria-label="basic tabs example">
							  <Tab label="CR en cours"  />
							  <Tab label="vieux cr 1" />
							  <Tab label="vieux cr 2" />
							</Tabs>
						<div>
						test 1
						</div>
					</div>
				</Window>
	}
}
export default CRaudit