import React, { Component } from "react";
import './BouttonMenu.css';
 
import Menu from "./Menu";

class BouttonMenu extends Component {
	
	constructor(props) {
		super(props);
 		this.state = {};
		this.handleChange = this.handleChange.bind(this);
		this.changeMenu = this.changeMenu.bind(this);
	}
 
	changeMenu() {
		this.props.changeMenuVisible();
	}
		
	handleChange(e) {
		this.changeMenu();
		e.stopPropagation();
	}
	
  render() {
    return (
	<div id="menuToggle">
		<input type="checkbox" checked={this.props.menuVisibility} onChange={this.handleChange}></input>
		<span></span>
		<span></span>
		<span></span>
	</div>
    );
  }
}
 
export default BouttonMenu;