import React, { Component } from "react";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import HotelIcon from '@mui/icons-material/Hotel';
import TodayIcon from '@mui/icons-material/Today';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { yellow, red , green } from '@mui/material/colors';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import classNames from "classnames"


import Image1 from './Image1.png';
import Image2 from './Image2.png';
import Image3 from './Image3.png';
import { appelAction, DiagError } from './utils.js';

import "./GrillePAPCert.css";

const SEMAINES=[0, 1, 2];
const JOURS=["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche", "SEPARATION"];


class GrillePAPCert extends Component
{

	constructor(props) {
		super(props);
		this.state = {etat:0, mess:"ok", idU: this.props.idU, missions: [], disableButtons: false, loading:true, hno:false, typesAExlure:[]};
		
		
		this.state.missions.push({cle:-1,nom:'', val:['?','?','?','?','?']}); 

		this.bindedChangeHNO = this.handleChangeHNO.bind(this);
		this.bindedClickCaseJour = this.handleClickCaseJour.bind(this)

	};
	
	// appele par le composant errordialog pour ack le message => chgt d'etat
	callbackError = () => 
	{
		this.setState({etat:0, loading:false});
	}
	
	handlePremierAppel = (obj) =>
	{
		if (obj.etat !== 0) // erreur 
			this.setState({etat:obj.etat, mess:obj.mess});
		else
		{
			this.setState({etat:obj.etat, mess:obj.mess, missions: obj.data.missions, lastUpdate: obj.data.lastUpdate});
		}
		this.setState({disableButtons:false, loading:false});
	}	
	
	// fleche droite et gauche pour ajouter ou soustraire une semaine
	handleAddOrDelete = (plusOuMoins) =>
	{
		this.setState({disableButtons:true, loading:true});
		var urlOptionsVoirPAP = "ctrl=ControleurPAPPerso&methode=voir&semaineEnPlus="+plusOuMoins+"&idU="+this.state.idU+"&cert=true";
		var requestOptionsVoirPAP = {method: "GET"};
		appelAction(urlOptionsVoirPAP, requestOptionsVoirPAP, this.handlePremierAppel);
	}
	
	
	// qd on clic sur une case le retour c'est ça :
	handleClickCallback = (obj) =>
	{
		if (obj.etat !== 0) // erreur 
			this.setState({etat:obj.etat, mess:obj.mess});
		else
		{
			var new_obj = this.state.missions.slice(); 
			new_obj[obj.data.cle+1].val[obj.data.jour].valMF= obj.data.valMF;
			new_obj[obj.data.cle+1].val[obj.data.jour].valMB= obj.data.valMB;
			new_obj[obj.data.cle+1].val[obj.data.jour].valAF= obj.data.valAF;
			new_obj[obj.data.cle+1].val[obj.data.jour].valAB= obj.data.valAB;
			new_obj[obj.data.cle+1].val[obj.data.jour].valNF= obj.data.valNF;
			new_obj[obj.data.cle+1].val[obj.data.jour].valNB= obj.data.valNB;
			this.setState({missions:new_obj});
		}
	}		
	

	chargementDeLaGrille(idU)
	{
		//alert("hop");
		var urlOptionsVoirPAP = "ctrl=ControleurPAPPerso&methode=voir&idU="+idU+"&cert=true";
		var requestOptionsVoirPAP = {method: "GET"};
		appelAction(urlOptionsVoirPAP, requestOptionsVoirPAP, this.handlePremierAppel);		
	}

	// appel la 1ere fois pour charger les données
	componentDidMount()
	{
		this.chargementDeLaGrille(this.props.idU);
	}
	
	// on passe en HNO ou pas
	handleChangeHNO(e, v)
	{
		this.setState({hno: v});
	}
	
	// on click sur une case
	handleClickCaseJour(e, v)
	{
		var urlOptionsModifPAP = "ctrl=ControleurPAPPerso&methode=modifier&cert=true";
		var requestOptionsModifPAP = {method: "POST", body:""};

	    if (e.currentTarget.dataset.man >= 2)
		{
			alert("Pas la nuit pour l instant !");
			return;
		}
		
		requestOptionsModifPAP.body = "ligne=0&idU="+this.state.idU+"&cle="+e.currentTarget.dataset.cle+"&jour="+e.currentTarget.dataset.jour+"&man="+e.currentTarget.dataset.man;
		//console.log(requestOptionsModifPAP.body);

		appelAction(urlOptionsModifPAP, requestOptionsModifPAP, this.handleClickCallback);
	}
	
	
	componentDidUpdate(prevProps) 
	{
		if (prevProps.idU === this.props.idU)
			return;
			
		this.setState({idU: this.props.idU, loading:true});
		this.chargementDeLaGrille(this.props.idU);
	}	

	render() {
	 
	let i = 0;
	let ligne = 0;
	var classeContainer = "GrillePAPCert_container_"+((this.state.hno)?"hno":"ho");
	var classeContainerSemaine = "GrillePAPCert_container_semaine_"+((this.state.hno)?"hno":"ho");
  	  
    return (
        <div className="GrillePAPCert">
            <Backdrop className="backdrop" open={this.state.loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
                    
                    <div className="GrillePAPCert_bouttons">
                    
                    <FormControlLabel control={<Switch />} label="HNO Off/On" onChange={this.bindedChangeHNO} />
                    
                    <Tooltip title="Revenir 1 semaine avant">
                    <IconButton
                        disabled={this.state.disableButtons}
                        onClick={() => this.handleAddOrDelete(-1)}
                        size="large">
                        <ArrowBackIosRoundedIcon  />
                    </IconButton>
                    </Tooltip>
                    <Tooltip title="Revenir à aujourd'hui">
                    <IconButton
                        disabled={this.state.disableButtons}
                        onClick={() => this.handleAddOrDelete(0)}
                        size="large">
                        <TodayIcon  />  
                    </IconButton>
                    </Tooltip>
                    <Tooltip title="Avancer d'1 semaine">
                    <IconButton
                        disabled={this.state.disableButtons}
                        onClick={() => this.handleAddOrDelete(1)}
                        size="large">
                        <ArrowForwardIosRoundedIcon  />
                    </IconButton>
                    </Tooltip>
                    </div>
                    <DiagError key={"diag"} etat={this.state.etat} mess={this.state.mess} callback={this.callbackError}></DiagError>
                    
                    <div key={"container"} className={classeContainer}>
                    {
                    !this.state.loading && this.state.missions.map( (row) => {
                        var indice=-1;
                        
                        if (row.cle===-1)
                            return <React.Fragment key={"titre_"+row.cle+"_"+indice}>
                                        <div key={"titre_"+row.cle+"_"+indice+"_0"}>&nbsp;</div>
                                        <div key={"titre_"+row.cle+"_"+indice+"_1"} className={classeContainerSemaine}>{this.state.missions[0].val[0].semaine}</div>
                                        <div key={"titre_"+row.cle+"_"+indice+"_2"} >&nbsp;</div>
                                        <div key={"titre_"+row.cle+"_"+indice+"_3"} className={classeContainerSemaine}>{this.state.missions[0].val[1].semaine}</div>
                                        <div key={"titre_"+row.cle+"_"+indice+"_4"} >&nbsp;</div>
                                        <div key={"titre_"+row.cle+"_"+indice+"_5"} className={classeContainerSemaine}>{this.state.missions[0].val[2].semaine}</div>
                                        <div key={"titre_"+row.cle+"_"+indice+"_6"}>&nbsp;</div>
                                        
                                        <div key={"titre_"+row.cle+"_"+indice+"_7"}>&nbsp;</div>
                                        
                                        {
                                        SEMAINES.map( semaine => {
                                            
                                            var j = -1;
                                            var fin=false;
                                            return JOURS.map( (jour) => 
                                             {
                                                j++; i++;
                                                if (fin)
                                                    return "";
                                                
                                                if ( ( !this.state.hno && j>=5) || j===7)
                                                {
                                                    fin = true;
                                                    return <div key={"titre_"+row.cle+"_"+semaine+"_"+j} className="GrillePAPCert_container_titre_jour_fin">&nbsp;</div>;
                                                }
                                                var clsName = classNames({
                                                    "GrillePAPCert_container_titre_jour" : true,
                                                    "GrillePAPCert_container_titre_jour_hno": this.state.hno,
                                                    "GrillePAPCert_container_titre_jour_ho": !this.state.hno,
                                                    "GrillePAPCert_container_titre_jour_now" : (this.state.missions[0].val[semaine].val[j]&2)>0});
                                                return <div key={"titre_"+row.cle+"_"+semaine+"_"+j} className={clsName}>{jour}</div>
                                             })
                                              
                                            })
                                        }
                                        
                                        <div>&nbsp;</div>
                                        {
                                        SEMAINES.map( semaine => {
                                        
                                        var j = -1;
                                        var fin=false;
                                        return JOURS.map( jour => 
                                         {
                                             j++;
                                            if (fin)
                                                return "";
                                                
                                            if ( ( !this.state.hno && j>=5) || j===7)
                                            {
                                                fin = true;
                                                return <div key={"jours_"+row.cle+"_"+semaine+"_"+j} className="GrillePAPCert_container_jour GrillePAPCert_container_jour_M">&nbsp;</div>
                                            }
                                                
                                            return <React.Fragment key={"jourMAN_"+row.cle+"_"+semaine+"_"+j}> 
                                                <div key={"joursM_"+row.cle+"_"+semaine+"_"+j} className="GrillePAPCert_container_jour GrillePAPCert_container_jour_M">	<img width={15}	src={Image1} /></div>
                                                <div key={"joursA_"+row.cle+"_"+semaine+"_"+j} className="GrillePAPCert_container_jour GrillePAPCert_container_jour_A "><img width={15}	src={Image2} /></div>
                                                {this.state.hno&&<div key={"joursN_"+row.cle+"_"+semaine+"_"+j} className="GrillePAPCert_container_jour GrillePAPCert_container_jour_N "><img width={12}	src={Image3} /></div>}
                                                   </React.Fragment>
                                            
                                        });
                                        })
                                        }
                                   </React.Fragment>
                        
                        
                        if (this.state.typesAExlure.includes(row.type))
                            return "";
                    
                        ligne++;
                        indice=-1;
                        return (
                            <React.Fragment key={"mission_"+row.cle+"_"+ligne}>
                                
                                <div key={row.cle+'_div_'+i} className={((ligne%2)===0)?"GrillePAPCert_case_mission GrillePAPCert_case_mission_impair":"GrillePAPCert_case_mission"}>{row.nom}</div>
                                
                                {
                                    
                                    SEMAINES.map( semaine =>
                                    {
                                        indice = semaine*7-1;
                                        var j = -1;
                                        var fin = false;
                                        return JOURS.map( jour => 
                                         {
                                             var i = 0;
                                             j++;
                                             indice++;
                                            
                                            if (fin)
                                                return ; 
                                                                        
                                            if ( ( !this.state.hno && j>=5) || j>=7)
                                            {
                                                fin=true;
                                                return <div key={'j_'+row.cle+'_'+semaine+'_'+indice+'_'+j} className="GrillePAPCert_container_jour GrillePAPCert_container_jour_M">&nbsp;</div>;
                                            }
                                            
                                            // jour ferie
                                            if ( !this.state.hno && (this.state.missions[0].val[semaine].val[j]&4) )
                                            {
                                                return <React.Fragment key={'jf_'+row.cle+'_'+semaine+'_'+indice+'_'+j}>
                                                        <div key={'jf1_'+row.cle+'_'+semaine+'_'+indice+'_'+j} className="GrillePAPCert_container_jour GrillePAPCert_container_jour_M">&nbsp;</div>
                                                        <div key={'jf2_'+row.cle+'_'+semaine+'_'+indice+'_'+j} className="GrillePAPCert_container_jour GrillePAPCert_container_jour_M">&nbsp;</div>
                                                    </React.Fragment>
                                            }
                                             
                                            if (row.val[indice].valMF === -1)
                                            {
                                                return <React.Fragment key={'jf_'+row.cle+'_'+semaine+'_'+indice+'_'+j}>
                                                    <div key={'jM_'+row.cle+'_'+semaine+'_'+indice+'_'+j}  className="GrillePAPCert_container_jour GrillePAPCert_container_jour_M">x</div>
                                                    <div key={'jA_'+row.cle+'_'+semaine+'_'+indice+'_'+j} className="GrillePAPCert_container_jour GrillePAPCert_container_jour_A">x</div>
                                                    {this.state.hno&&<div key={'jN_'+row.cle+'_'+semaine+'_'+indice+'_'+j} className="GrillePAPCert_container_jour GrillePAPCert_container_jour_N">x</div>}
                                                    </React.Fragment>
                                            }
                                            
                                            var caseJM = <CheckBoxOutlineBlankIcon sx={{ fontSize: 10,  my:.5 }} />;
                                            if (row.val[indice].valMB)
                                                caseJM = <LockOpenIcon sx={{ color: yellow[800], fontSize: 15 }}/>
                                            else if (row.val[indice].valMF)
                                                caseJM = <LockIcon sx={{ color: red[500], fontSize: 15 }}/>
                                        
                                            var caseJA = <CheckBoxOutlineBlankIcon sx={{ fontSize: 10, my:.5   }} />;
                                            if (row.val[indice].valAB)
                                                caseJA = <LockOpenIcon sx={{ color: yellow[800], fontSize: 15 }}/>
                                            else if (row.val[indice].valAF)
                                                caseJA = <LockIcon sx={{ color: red[500], fontSize: 15 }}/>
                                            
                                            var caseJN = "";
                                            if (this.state.hno)
                                                caseJN = <CheckBoxOutlineBlankIcon sx={{ fontSize: 10, my:.5  }} />;
                                            
                                        return (
                                            <React.Fragment key={'cf_'+row.cle+'_'+semaine+'_'+indice+'_'+j}>								
                                                    <div key={'cjm_'+row.cle+'_'+semaine+'_'+indice+'_'+j} className="GrillePAPCert_container_jour GrillePAPCert_container_jour_M"><IconButton
                                                        style={{padding: 2, borderRadius: 0}}
                                                        data-cle={row.cle}
                                                        data-jour={indice}
                                                        data-man={0}
                                                        onClick={this.bindedClickCaseJour}
                                                        size="large">{caseJM}</IconButton></div>
                                                    <div key={'cja_'+row.cle+'_'+semaine+'_'+indice+'_'+j} className="GrillePAPCert_container_jour GrillePAPCert_container_jour_A"><IconButton
                                                        style={{padding: 2, borderRadius: 0}}
                                                        data-cle={row.cle}
                                                        data-jour={indice}
                                                        data-man={1}
                                                        onClick={this.bindedClickCaseJour}
                                                        size="large">{caseJA}</IconButton></div>
                                                    {this.state.hno&&
                                                    <div key={'cjn_'+row.cle+'_'+semaine+'_'+indice+'_'+j} className="GrillePAPCert_container_jour GrillePAPCert_container_jour_N"><IconButton
                                                        style={{padding: 2, borderRadius: 0}}
                                                        data-cle={row.cle}
                                                        data-jour={indice}
                                                        data-man={2}
                                                        onClick={this.bindedClickCaseJour}
                                                        size="large">{caseJN}</IconButton></div>}			 
                                                
                                                </React.Fragment>
                                        );
                                            
                                        });
                                        
                                    })
                                }
                                </React.Fragment>
                        );						
                        
                            })
                        }
                
                            </div>
                </div>
    );
  }
}
 
export default GrillePAPCert;
