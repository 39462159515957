import React, { Component } from "react";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import TodayIcon from '@mui/icons-material/Today';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import classNames from "classnames"
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';

import { listeUtilisateurs } from './index.js';
import { appelAction, DiagError } from './utils.js';

import "./GrillePAPAudit.css";


class GrillePAPAudit extends Component
{

	constructor(props) {
		super(props);
		this.state = {etat:0, mess:"ok", idU: this.props.idU, missions: [], disableButtons: false, loading:true, typesAExlure:[]};
		
		this.listeUtilisateurs = listeUtilisateurs;
		this.state.missions.push({cle:-1,nom:'', val:['?','?','?','?','?']}); 

		this.bindedAfficheMasqueFille = this.handleAfficheMasqueFille.bind(this);
		//alert(this.state.missions[3][4].cle);
	};
	

	handleAfficheMasqueFille(e)
	{

		var indice = parseInt(e.currentTarget.dataset.indice)+1;
		var prev = this.state.affichageMamanFilles.get(this.state.missions[indice-1].id);
		var newTab = this.state.affichageMamanFilles;
		newTab.set(this.state.missions[indice-1].id, prev===1?2:1);
		while (indice<this.state.missions.length && this.state.missions[indice].estFille>0 && !this.state.missions[indice].estOrpheline) 
		{
			newTab.set(this.state.missions[indice].id, prev===2?0:1);
			for (var j=0;j<25;j++)
			{
				if (this.state.missions[indice].val[j].val>0)
				{
					newTab.set(this.state.missions[indice].id,1);
					break;
				}
			}
			indice++;
		}
		this.setState({affichageMamanFilles : newTab});
	}
	
	// appele par le composant errordialog pour ack le message => chgt d'etat
	callbackError = () => 
	{
		this.setState({etat:0, loading:false});
	}
	
	handlePremierAppel = (obj) =>
	{
		if (obj.etat !== 0) // erreur 
			this.setState({etat:obj.etat, mess:obj.mess});
		else
		{
			var affichageMamanFilles =  (this.state.affichageMamanFilles)?this.state.affichageMamanFilles:new Map();
			for (var i=1; i<obj.data.missions.length; i++)
			{
				
				// existant donc on garde
				if (affichageMamanFilles.has(obj.data.missions[i].id))
					continue;
								
				affichageMamanFilles.set(obj.data.missions[i].id, 0);
				// c'est la derniere ligne, normalement pas une fille mais on regarde qd meme
				if (i===obj.data.missions.length-1)
					affichageMamanFilles.set(obj.data.missions[i].id,obj.data.missions[i].estFille<0?0:1);
				// si pas la derniere, si c'est pas une fille, on regarde si elle a des filles...
				else if (obj.data.missions[i].estFille<0&&obj.data.missions[i+1].estFille>0)
				{
					affichageMamanFilles.set(obj.data.missions[i].id,1);
				}
				
				for (var j=0;obj.data.missions[i].estFille>0&&j<25;j++)
				{
					if (obj.data.missions[i].val[j].val>0)
					{
						affichageMamanFilles.set(obj.data.missions[i].id,1);
						break;
					}
				}
			}
		
			this.setState({etat:obj.etat, mess:obj.mess, missions: obj.data.missions, affichageMamanFilles: affichageMamanFilles, lastUpdate: obj.data.lastUpdate});
		}
		this.setState({disableButtons:false, loading:false});
	}	
	
	// fleche droite et gauche pour ajouter ou soustraire une semaine
	handleAddOrDelete = (plusOuMoins) =>
	{
		this.setState({disableButtons:true, loading:true});
		var urlOptionsVoirPAP = "ctrl=ControleurPAPPerso&methode=voir&semaineEnPlus="+plusOuMoins+"&idU="+this.state.idU;
		var requestOptionsVoirPAP = {method: "GET"};
		appelAction(urlOptionsVoirPAP, requestOptionsVoirPAP, this.handlePremierAppel);
	}
	
	
	// qd on clic sur une case le retour c'est ça :
	handleClickCallback = (obj) =>
	{
		if (obj.etat !== 0) // erreur 
			this.setState({etat:obj.etat, mess:obj.mess});
		else
		{
			var new_obj = this.state.missions.slice(); 
			new_obj[obj.data.ligne].val[obj.data.jour].val = obj.data.val;
			this.setState({missions:new_obj});
		}
	}		
	
	// clic sur une case
	handleClick(cle, ligne, col)
	{ 
		var urlOptionsModifPAP = "ctrl=ControleurPAPPerso&methode=modifier";
		var requestOptionsModifPAP = {method: "POST", body:""};
		
		requestOptionsModifPAP.body = "idU="+this.state.idU+"&cle="+cle+"&ligne="+ligne+"&jour="+col;
		//alert(requestOptionsModifPAP.body)

		appelAction(urlOptionsModifPAP, requestOptionsModifPAP, this.handleClickCallback);
	};
	

	chargementDeLaGrille(idU)
	{
		//alert("hop");
		var urlOptionsVoirPAP = "ctrl=ControleurPAPPerso&methode=voir&idU="+idU;
		var requestOptionsVoirPAP = {method: "GET"};
		appelAction(urlOptionsVoirPAP, requestOptionsVoirPAP, this.handlePremierAppel);		
	}

	// appel la 1ere fois pour charger les données
	componentDidMount()
	{
		this.chargementDeLaGrille(this.props.idU);
	}
	
	componentDidUpdate(prevProps) 
	{
		
		
		if (prevProps.idU === this.props.idU)
			return;
			
		this.setState({idU: this.props.idU, loading:true, affichageMamanFilles:new Map()});
		this.chargementDeLaGrille(this.props.idU);
	}
	
	render() {
	 
	let i = 0;
	let ligne=0;
	let indice=0;
	
	var tabDispo = Array(25).fill(0);
  	  
    return (
        <div className="GrillePAPAudit">
            <Backdrop className="backdrop" open={this.state.loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
                    <div className="GrillePAPAudit_bouttons">
                    <Tooltip title="Revenir au début du mois (et avant)">
                    <IconButton
                        disabled={this.state.disableButtons}
                        onClick={() => this.handleAddOrDelete(-2)}
                        size="large">
                        <FirstPageIcon  />
                    </IconButton>
                    </Tooltip>
                    <Tooltip title="Revenir 1 semaine avant">
                    <IconButton
                        disabled={this.state.disableButtons}
                        onClick={() => this.handleAddOrDelete(-1)}
                        size="large">
                        <ArrowBackIosRoundedIcon  />
                    </IconButton>
                    </Tooltip>
                    <Tooltip title="Revenir à aujourd'hui">
                    <IconButton
                        disabled={this.state.disableButtons}
                        onClick={() => this.handleAddOrDelete(0)}
                        size="large">
                        <TodayIcon  />  
                    </IconButton>
                    </Tooltip>
                    <Tooltip title="Avancer d'1 semaine">
                    <IconButton
                        disabled={this.state.disableButtons}
                        onClick={() => this.handleAddOrDelete(1)}
                        size="large">
                        <ArrowForwardIosRoundedIcon  />
                    </IconButton>
                    </Tooltip>
                    <Tooltip title="Passer au début du mois d'après">
                    <IconButton
                        disabled={this.state.disableButtons}
                        onClick={() => this.handleAddOrDelete(2)}
                        size="large">
                        <LastPageIcon  />
                    </IconButton>
                    </Tooltip>
                    </div>
                    <DiagError key={"diag"} etat={this.state.etat} mess={this.state.mess} callback={this.callbackError}></DiagError>
                    
                    <div className="GrillePAPAudit_container">
                    {
                    this.state.missions.map( (row) => {
                        
                        if (row.cle===-1)
                            return <React.Fragment key={"titre_"+row.cle+"_"+ligne}>
                                        <div></div><div></div>
                                        <div className="GrillePAPAudit_container_SStitre">{this.state.missions[0].val[0].semaine}</div>
                                        <div className="GrillePAPAudit_container_SStitre">{this.state.missions[0].val[1].semaine}</div>
                                        <div className="GrillePAPAudit_container_SStitre">{this.state.missions[0].val[2].semaine}</div>
                                        <div className="GrillePAPAudit_container_SStitre">{this.state.missions[0].val[3].semaine}</div>
                                        <div className="GrillePAPAudit_container_SStitre">{this.state.missions[0].val[4].semaine}</div>
                                        <div></div><div></div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">Lu</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">Ma</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">Me</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">Je</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">Ve</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">SD</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">Lu</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">Ma</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">Me</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">Je</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">Ve</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">SD</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">Lu</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">Ma</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">Me</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">Je</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">Ve</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">SD</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">Lu</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">Ma</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">Me</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">Je</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">Ve</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">SD</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">Lu</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">Ma</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">Me</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">Je</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">Ve</div>
                                            <div className="GrillePAPAudit_container_SStitre_Jour">SD</div>
                                        </React.Fragment>
                        
                        indice++;
                        
                        if (this.state.typesAExlure.includes(row.type))
                            return "";
                        
                        // si fille (non orpheline) et si on affiche pas les filles de maman... 
                        if ((row.estFille>0&&!row.estOrpheline)&&this.state.affichageMamanFilles.get(row.id)===0)
                            return;
                        
                        var clCase = classNames({
                                'GrillePAPAudit_case_parentalite' : true,
                                'GrillePAPAudit_case_colspan': row.type.includes("AUDIT"),
                                'GrillePAPAudit_case_parentalite_impair' : ((ligne+1)%2)===0
                        });
                        var premiereCase = <div className={clCase} ></div>
                        if (row.estFille>0&&!row.estOrpheline)
                            premiereCase = <div className={clCase} ><EscalatorWarningIcon sx={{fontSize: 11}}/></div>
                        else if (row.estOrpheline)
                            premiereCase=<div className={clCase}  >/-</div>
                        else if (row.estFille<0&&this.state.affichageMamanFilles.get(row.id)>0)
                            premiereCase=<div className={clCase}  onClick={this.bindedAfficheMasqueFille} data-indice={indice} >
                                        {(this.state.affichageMamanFilles.get(row.id)===1)?<VisibilityIcon sx={{fontSize: 12}}/>:<VisibilityOffIcon sx={{fontSize: 12}}/>}
                                        </div>
                        
                        clCase = classNames({
                                'GrillePAPAudit_case_mission' : true,
                                'GrillePAPAudit_case_colspan': row.type.includes("AUDIT"),
                                'GrillePAPAudit_case_mission_impair' : ((ligne+1)%2)===0
                        });
                        
                        ligne++;
                        return <React.Fragment key={"mission_"+row.cle+"_"+ligne}>
                            {premiereCase}
                            <div key={row.cle+'_div_'+i} title={ row.cp && row.cp.map ( cp => 
                                {
                                    var u = this.listeUtilisateurs.find(f => (f.id === cp));
                                    if (u)
                                        return u.prenom + " " + u.nom + " ";
                                    return "";
                                }
                            )} className={clCase}>{row.nom}</div>
                            {row.type.includes("AUDIT") && [...Array(5)].map((e, k) => 
                            {
                            return <div key={'z_' + row.cle+'_div_'+ligne+'_'+k} className={((ligne%2)===0)?"GrillePAPAudit_case_planif GrillePAPAudit_case_planif_impair":"GrillePAPAudit_case_planif"}>
                                <input className={row.planif[k]>0?"GrillePAPAudit_case_planif_input_inside_b":"GrillePAPAudit_case_planif_input_inside"} key={row.cle+"_"+k+"_"+i} type="text" readOnly={true} value={"Planifié : "+row.planif[k]/2.} />
                                
                                </div>
                            })}
                            {
                            row.val.map( (col) => {
                            var week_end = "";
                            if (i++%5===4)
                                week_end = (<div key={"w_"+col.jour+"_"+i} className={((ligne%2)===0)?"GrillePAPAudit_case_week-end GrillePAPAudit_case_week-end_impair":"GrillePAPAudit_case_week-end"}></div>);
                            var clCase = classNames({
                                'GrillePAPAudit_case': true,
                                'GrillePAPAudit_case_now': this.state.missions[0].val[Math.trunc(col.jour/5)].val[col.jour%5]&2,
                                'GrillePAPAudit_case_ferie': this.state.missions[0].val[Math.trunc(col.jour/5)].val[col.jour%5]&4,
                                'GrillePAPAudit_case_1': col.val>0,
                                'GrillePAPAudit_case_impair' : col.val<=0&&(ligne%2)===0,
                                'GrillePAPAudit_case_disable' : col.val===-1});
                            var valeurCase = (col.val/2.);
                            if (col.val<0)
                                valeurCase="x";
                            if ((this.state.missions[0].val[Math.trunc(col.jour/5)].val[col.jour%5]&4)==4)
                                valeurCase="";

                            if (col.val>0)
                                tabDispo[col.jour]+=col.val;
                            else if (this.state.missions[0].val[Math.trunc(col.jour/5)].val[col.jour%5]&4)
                                tabDispo[col.jour]=2;

                            return <React.Fragment key={"k_"+row.cle+"_"+i}> <input key={col.jour+"_"+i} type="text" readOnly={true} className={clCase} value={valeurCase} onClick={() => this.handleClick(row.cle, row.ligne, col.jour)}/> {week_end} </React.Fragment>	
                            })
                            }
                            </React.Fragment>						
                            
                            })
                        }
                        <div className="GrillePAPAudit_container_Ligne_Fin"></div>
                        <div></div><div>Total :</div>
                        {tabDispo.map( (u,i) =>
                            {
                                var clCase = classNames({
                                'GrillePAPAudit_container_Fin' : true,
                                'GrillePAPAudit_container_Fin_Error' : (u!==2)
                                });
                        
                                if (i>0 && i%5===0)
                                    return <React.Fragment key={"f_total_"+i}><div></div><div className={clCase}>{u/2.}</div></React.Fragment>	
                                return <div key={"total_"+i} className={clCase}>{u/2.}</div>
                            })}
                            </div>
                </div>
    );
  }
}
 
export default GrillePAPAudit;
