import React, { Component } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/icons-material/Apartment';
import SyncIcon from '@mui/icons-material/Sync';
import Switch from '@mui/material/Switch';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AdapterDateFns from '@date-io/date-fns';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import {fr} from 'date-fns/locale';

import ApartmentIcon from '@mui/icons-material/Apartment';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import DescriptionIcon from '@mui/icons-material/Description';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

import { appelAction, DiagError } from './utils.js';
import { listeClients } from './index.js';

import "./MissionPerso.css";
 
class MissionsPerso extends Component {
	

  

	constructor(props) {
		super(props);
		
		var date = new Date();
		this.state = {etat:0, mess:"ok", disableButtons: false, 
		accordeonCreation: false, accordeonHorsPool: false, accordeonPool: false,  
		num:"", client: "", mission: "", desc: "", horsPoolProd: true,
		dateMoisDebut: (date.getMonth()+1), dateAnneeDebut: date.getFullYear(), 
		dateMoisFin: (date.getMonth()+1), dateAnneeFin: date.getFullYear() };
		
		this.datesMissions = new Map();
		
		// fonctions
		
		this.bindedTextFieldChange = this.handleTextFieldChange.bind(this);
		this.bindedChangeDateDebut = this.handleChangeDateDebut.bind(this);
		this.bindedChangeDateFin = this.handleChangeDateFin.bind(this);
		this.bindedCreationMission = this.creationMission.bind(this);
		this.bindedUpdateMiss = this.updateMiss.bind(this);
	}
	
  	// appele par le composant errordialog pour ack le message => chgt d'etat
  callbackError = () => 
  {
		this.setState({etat:0});
  }
	
  handleChange(panel)
  {
	this.setState({[panel]: !this.state[panel]});
	
	if (panel === "accordeonHorsPool")
	{
		var urlMissionPerso = "ctrl=ControleurMissionsPerso&methode=voir&horsBT=true";
		var requestMissionPerso = {method: "GET", body:""};
	
		appelAction(urlMissionPerso, requestMissionPerso, this.handleClickCallback);
	}
	
  }

  handleTextFieldChange (e,v)
  {
	
	  var option = "";
	  if(e.target.id.startsWith('client'))
		option = "client"
	  else if(e.target.id.startsWith('wavekeeper'))
	    option = "num"
	  else if(e.target.id.startsWith('desc'))
		option = "desc"
	  else if(e.target.id.startsWith('mission'))
		option = "mission"
	  else 
		return;

	  
	  var val="";
	  if (v == null || isNaN(e.target.value))
	  { val = e.target.value; }
	  else
	  { val = v.nom; }

	this.setState({[option]: val});

  }
  
  handleChangeDateDebut (e) 
  {
	
	  var debut = new Date(e);
	  this.setState({dateMoisDebut: (debut.getMonth()+1), dateAnneeDebut: debut.getFullYear()})
  }
  
  handleChangeDateFin (e) 
  {
	  var fin = new Date(e);
	  this.setState({dateMoisFin: (fin.getMonth()+1), dateAnneeFin: fin.getFullYear()})
  }
  
  /* creation d'une mission perso */
  handleClickCallback = (obj) =>
  {
	  if (obj.etat === 4) // erreur et on garde le truc ouvert
		this.setState({etat:obj.etat, mess:obj.mess});
	  else
	  {
		  var date = new Date();
		  this.setState({etat:obj.etat, mess:obj.mess, accordeonCreation: false, 
				num:0, client: "", mission: "", desc: "", horsPoolProd: true, missions: obj.data?obj.data:this.state.missions,
				dateMoisDebut: (date.getMonth()+1), dateAnneeDebut: date.getFullYear(), 
				dateMoisFin: (date.getMonth()+1), dateAnneeFin: date.getFullYear() });
	  }
	  this.setState({disableButtons:false});
	
  }
  
  updateDateDebutMiss = (id, date) =>
  {
	  var debut = new Date(date);
	  this.datesMissions.get(id).dateMD = (debut.getMonth()+1) ;
	  this.datesMissions.get(id).dateAD = debut.getFullYear();
	  this.datesMissions.get(id).update = true; 
	  this.setState({});
  }
  
    updateDateFinMiss = (id, date) =>
  {
	  var fin = new Date(date);
	  this.datesMissions.get(id).dateMF = (fin.getMonth()+1) ;
	  this.datesMissions.get(id).dateAF = fin.getFullYear();
	  this.datesMissions.get(id).update = true; 
	  this.setState({});
  }
  
  
  
  updateMiss(e)
  {
	 var id = parseInt( e.currentTarget.dataset.idmiss );
	  
	var urlMissionPerso = "ctrl=ControleurMissionsPerso&methode=changer";
	var requestMissionPerso = {method: "POST", body:""};
	
	requestMissionPerso.body = "id="+id;
	requestMissionPerso.body += "&dateMoisDebut="+this.datesMissions.get(id).dateMD;
	requestMissionPerso.body += "&dateAnneeDebut="+this.datesMissions.get(id).dateAD;
	requestMissionPerso.body += "&dateMoisFin="+this.datesMissions.get(id).dateMF;
	requestMissionPerso.body += "&dateAnneeFin="+this.datesMissions.get(id).dateAF;
	
	this.datesMissions.get(id).update = false; 
	appelAction(urlMissionPerso, requestMissionPerso, this.handleClickCallback);
     
  }
  
  creationMission () 
  {
	var urlOptionsCreerMissionPerso = "ctrl=ControleurMissionsPerso&methode=creer";
	var requestOptionsCreerMissionPerso = {method: "POST", body:""};
	this.setState({disableButtons:true});

	requestOptionsCreerMissionPerso.body += "num="+this.state.num;
	requestOptionsCreerMissionPerso.body += "&client="+this.state.client.sansEtCom();
	requestOptionsCreerMissionPerso.body += "&mission="+this.state.mission.sansEtCom();
	requestOptionsCreerMissionPerso.body += "&desc="+this.state.desc.sansEtCom();
	requestOptionsCreerMissionPerso.body += "&horsPoolProd="+this.state.horsPoolProd;
	requestOptionsCreerMissionPerso.body += "&dateMoisDebut="+this.state.dateMoisDebut;
	requestOptionsCreerMissionPerso.body += "&dateAnneeDebut="+this.state.dateAnneeDebut;
	requestOptionsCreerMissionPerso.body += "&dateMoisFin="+this.state.dateMoisFin;
	requestOptionsCreerMissionPerso.body += "&dateAnneeFin="+this.state.dateAnneeFin;
	
	appelAction(urlOptionsCreerMissionPerso, requestOptionsCreerMissionPerso, this.handleClickCallback);
  }

	
  render() {

	var dateNow = new Date();
			
    return (
        <div className="MissionPerso">
          <h2>Mes Missions Perso</h2>
          <DiagError key={"diag"} etat={this.state.etat} mess={this.state.mess} callback={this.callbackError}></DiagError>
          
          <form className="" noValidate>
          
          {/* 1er block : ajout d'une mission */}
          
          <Accordion expanded={this.state.accordeonCreation} onChange={() => this.handleChange('accordeonCreation')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography>{(this.state.accordeonCreation)?'':'Ajouter une mission hors BT Air...'}</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: 'accordeon' }} >
            <div className={"MP_grille"} >
      
              <div className={"inputText_MP_numWavekeeper"}>
                  <TextField fullWidth id="wavekeeper" label="Num. Wavekeeper" value={this.state.num}
                      onChange={this.bindedTextFieldChange}
                          InputProps={{	
                              startAdornment: <InputAdornment position="start"><FormatListNumberedIcon/></InputAdornment>,
                      }} 
                  />
              </div>
              <div className={"inputText_MP_nomClient"} >
                  <Autocomplete
                      id="client"
                      options={listeClients}
                      freeSolo
                      onChange={this.bindedTextFieldChange}
                      getOptionLabel={(option) => option.nom}
                      renderInput={(params) => <TextField {...params}  fullWidth required id="client_area" label="Nom du client" value={this.state.client} onChange={this.bindedTextFieldChange}
                          InputProps={{	
                              ...params.InputProps,
                              startAdornment: <InputAdornment position="start"><ApartmentIcon/></InputAdornment>,
                  }} /> }
                      
                  />
              </div>
              <div className={"inputText_MP_nomMission"} >
                  <TextField  fullWidth required id="mission" label="Nom de la mission" value={this.state.mission}	
                      onChange={this.bindedTextFieldChange}
                  InputProps={{
                      startAdornment: <InputAdornment position="start"><FolderOpenIcon/></InputAdornment>,
                  }}
                  />
              </div>
              <div className={"inputText_MP_dateD"}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                  <DesktopDatePicker
                      id='dateDebut'
                      minDate={new Date().setFullYear(dateNow.getFullYear()-2)}
                      maxDate={new Date().setFullYear(dateNow.getFullYear()+5)}
                      views={['month','year']}
                      label="Début (MM/YYYY)"
                      value = {this.state.dateMoisDebut+"/01/"+this.state.dateAnneeDebut}
                      inputFormat = "MM/yyyy"
                      onChange = {this.bindedChangeDateDebut}
                      renderInput={(params) => <TextField {...params} helperText={null} />}
                      />
                  </LocalizationProvider>
              </div>

              
              <div className={"inputText_MP_Desc"}>
                  <TextField fullWidth id="desc" multiline={true} rows={4} rowsmax={4} label="Description" value={this.state.desc}
                      onChange={this.handleTextFieldChange.bind(this)}
                  InputProps={{
                      startAdornment: <InputAdornment position="start"><DescriptionIcon/></InputAdornment>,
                  }}
                  />
              </div>

              <div className={"inputText_MP_dateF"}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                      <DesktopDatePicker
                      id='dateFin'
                      minDate={new Date().setFullYear(dateNow.getFullYear()-2)}
                      maxDate={new Date().setFullYear(dateNow.getFullYear()+5)}
                      views={['month','year']}
                      label="Fin (MM/YYYY)"
                      value = {this.state.dateMoisFin+"/01/"+this.state.dateAnneeFin}
                      inputFormat = "MM/yyyy"
                      onChange = {this.bindedChangeDateFin}
                      renderInput={(params) => <TextField {...params} helperText={null} />}
                      />
                  </LocalizationProvider>
              </div>
              
              <div className={"inputText_MP_prod"}>
                  <Switch onChange={() => this.handleChange('horsPoolProd')}
                  defaultChecked
                  color="default"
                  />{(this.state.horsPoolProd)?'Mission de prod hors BT Air (client mission CTC, etc.)':'Mission improd hors BT Air ()'}
              </div>
              
              
              <div className={"MP_derniereLigne"}>
                  <Button disabled={this.state.disableButtons} onClick={this.bindedCreationMission} variant="contained">Ajouter</Button>
              </div>
              
              </div>
          </AccordionDetails>
          </Accordion>

          {/* 2eme block : mission hors pool */}
          
          <Accordion expanded={this.state.accordeonHorsPool} onChange={() => this.handleChange('accordeonHorsPool')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography>{(this.state.accordeonHorsPool)?'':'Missions hors BT Air (audit/cert) ...'}</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: 'accordeon' }} >
              <Table>
              <TableBody>
              {this.state.missions && this.state.missions.map( ligne =>
                  {
                      if (!this.datesMissions.has(ligne.mission.idMiss))
                      {
                          this.datesMissions.set(ligne.mission.idMiss,{});
                          this.datesMissions.get(ligne.mission.idMiss).dateMD = ligne.dateMD;
                          this.datesMissions.get(ligne.mission.idMiss).dateMF = ligne.dateMF;
                          this.datesMissions.get(ligne.mission.idMiss).dateAD = ligne.dateAD;
                          this.datesMissions.get(ligne.mission.idMiss).dateAF = ligne.dateAF;
                      }
                      return (
                          <TableRow>
                                  <TableCell>
                                      { ligne.mission.nomC }
                                  </TableCell>
                                  <TableCell>
                                  
                                      { ligne.mission.nomM }
                                  </TableCell>
                                  
                                  <TableCell>
                                  
                                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                                  
                                      <DesktopDatePicker
                                          id={'dateDebut_'+ligne.mission.idMiss}
                                          minDate={new Date().setFullYear(dateNow.getFullYear()-2)}
                                          maxDate={new Date().setFullYear(dateNow.getFullYear()+5)}
                                          views={['month','year']}
                                          label="Fin (MM/YYYY)"
                                          value = {this.datesMissions.get(ligne.mission.idMiss).dateMD+"/01/"+this.datesMissions.get(ligne.mission.idMiss).dateAD}
                                          inputFormat = "MM/yyyy"
                                          onChange = {(date) => this.updateDateDebutMiss(ligne.mission.idMiss, date)}
                                          renderInput={(params) => <TextField {...params} helperText={null} />}
                                          />
                                  </LocalizationProvider>
                                  </TableCell>
                                  
                                  <TableCell>
                                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                                              
                                      <DesktopDatePicker
                                      id={'dateFin_'+ligne.mission.idMiss}
                                      minDate={new Date().setFullYear(dateNow.getFullYear()-2)}
                                      maxDate={new Date().setFullYear(dateNow.getFullYear()+5)}
                                      views={['month','year']}
                                      label="Fin (MM/YYYY)"
                                      value = {this.datesMissions.get(ligne.mission.idMiss).dateMF+"/01/"+this.datesMissions.get(ligne.mission.idMiss).dateAF}
                                      inputFormat = "MM/yyyy"
                                      onChange = {(date) => this.updateDateFinMiss(ligne.mission.idMiss, date) }
                                      renderInput={(params) => <TextField {...params} helperText={null} />}
                                      />
                                  </LocalizationProvider>
                                  </TableCell>
                                  <TableCell>
                                  <IconButton
                                      disabled={!this.datesMissions.get(ligne.mission.idMiss).update}
                                      data-idmiss={ligne.mission.idMiss}
                                      onClick = {this.bindedUpdateMiss}
                                      size="large">
                                      <SyncIcon />
                                  </IconButton>
                                  </TableCell>
                                  </TableRow>
                      );
                      } ) }
          </TableBody>
          </Table>
          </AccordionDetails>
          </Accordion>
          
                  {/* 3eme block : mission pool */}
          
          <Accordion expanded={this.state.accordeonPool} onChange={() => this.handleChange('accordeonPool')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography>{(this.state.accordeonPool)?'':'Missions BT Air (audit/cert) ...'}</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: 'accordeon' }} >
              Incoming !!!!
          </AccordionDetails>
          </Accordion>
          </form>
        </div>
    );
  }
}
 
export default MissionsPerso;