import React, { Component } from "react";
import classNames from "classnames";

import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'

import { appelAction , DiagError , bureauxWavestone, userEnSession} from './utils.js';
import {setListeUtilisateurs} from './index.js';


import "./LesStats.css";


class LesStats extends Component {
	
	

	constructor(props)
	{
		super(props);
		var d = new Date();
		this.state = {etat:0, mess:"ok" , pageEnCours:3};		
		
	}
	
	
	handleCallback = (obj) =>
	{
	
	  if (obj.etat === 4) // erreur 
		this.setState({etat:obj.etat, mess:obj.mess});
	  else
	  {
		this.setState({etat:obj.etat, mess:obj.mess, retour:obj.data});
	  }
	}
	
	chargeStatsMois(m)
	{
		var urlOptionsStats = "ctrl=ControleurStats&methode=voir&mois="+m;
		var requestOptionsStats = {method: "GET", body:""};
	
		appelAction(urlOptionsStats, requestOptionsStats, this.handleCallback);
	}
	
	componentDidMount()
	{
		this.chargeStatsMois(0);
	}

  // appele par le composant errordialog pour ack le message => chgt d'etat
  callbackError = () => 
  {
		this.setState({etat:0});
  }
	
   
  changePanel = (nouveau, ancien) =>
  {
	  this.setState({retour:null, pageEnCours:nouveau},this.chargeStatsMois(nouveau-3));
  }
  
	
	render()
	{
		
		
		
	  
		return <div>
				   <DiagError key={"diag"} etat={this.state.etat} mess={this.state.mess} callback={this.callbackError}></DiagError>
        
					{!this.state.retour&&<Paper className={"paper_stats"} ><h2>...</h2></Paper>}
						
					{this.state.retour&&<Carousel index={this.state.pageEnCours} autoPlay={false} animation={"slide"} navButtonsAlwaysVisible={true} cycleNavigation={false} onChange={this.changePanel} next={this.changePanel} prev={this.changePanel} indicatorContainerProps={{ style: {marginTop: '50px'} }}>
					
					{[...Array(5)].map((e, i) => {
						
						return <Paper className={"paper_stats"} >
									<h2>M{i-3} / {this.state.retour.moisAnnee} / Paris</h2>
									
									Jours ouvrés du mois : {this.state.retour.demiJoursOuvresMois/2.}
									&nbsp;/&nbsp;
									Consultants mobilisés ce mois : {this.state.retour.nbUsers}
									<br/>
									=> Capacité de jours sans compter les congés : {this.state.retour.demiJoursOuvresMois*this.state.retour.nbUsers/2.}
									<br/>
									=> Capacité de jours en comptant les congés : {(this.state.retour.demiJoursOuvresMois*this.state.retour.nbUsers-this.state.retour.totalDemiJoursConges)/2.}
									
									{this.state.retour.totalVentillation.map( (ventil) =>
									{
										if (ventil.type==="CONGES")
											return <p>{ventil.type} - {ventil.demiJours/2.}</p>
										
										return <div>{ventil.type} - {ventil.demiJours/2.} - <b>{Number(ventil.demiJours / (this.state.retour.demiJoursOuvresMois*this.state.retour.nbUsers-this.state.retour.totalDemiJoursConges) * 100).toFixed(2)}%</b><br/></div>
									})};
									
							   </Paper>
					})}
					
					</Carousel>}
					
					{this.state.retour&&<pre>{this.state.retour.csv}</pre>}
					
			   </div>
	};
	
}

export default LesStats;