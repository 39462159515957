import React, { Component } from "react";
import classNames from "classnames";

import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import WorkIcon from '@mui/icons-material/Work';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import FaceRetouchingOffIcon from '@mui/icons-material/FaceRetouchingOff';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import FactoryIcon from '@mui/icons-material/Factory';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FaceIcon from '@mui/icons-material/Face';
import IconButton from '@mui/material/IconButton';
import AdapterDateFns from '@date-io/date-fns';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import {fr} from 'date-fns/locale';

import { appelAction , DiagError , bureauxWavestone, userEnSession} from './utils.js';
import {setListeUtilisateurs} from './index.js';

import "./DreamTeam.css";

class DreamTeam extends Component {
	
	profilsWavestone = ["VISITEUR", "STAGIAIRE", "CONSULTANT", "SMSI", "CADRE", "RESPONSABLE", "CDM", "SAISIE_PDC"];

	constructor(props)
	{
		super(props);
		var d = new Date();
		this.state = {etat:0, mess:"ok", listeAuditeurs:[],  listeAuditeursAffiches:[], titreBox : "", 
					  affichageAjout : false, seulementActif: false, btAudit: true, btCert: true, btPic: true, bureauxVisibles: (userEnSession?userEnSession.bureau:1), 
					  nom : "", prenom : "", login : "", trigramme : "",
					  actif : false, staffable: true, visible: true, tribu: 0, tribuCheck: -1,
					  cdm : 0, bureau : [], profil : [], bt : 0,
					  date : d.getFullYear()+"/"+(d.getMonth()+1) };
		
		
		this.bindedFiltreChange = this.handleFiltreChange.bind(this);
		this.bindedTextFieldChange = this.handleTextFieldChange.bind(this);
		this.bindedProfilsChange = this.handleProfilsChange.bind(this);
		this.bindedCheckboxChange = this.handleCheckboxChange.bind(this);
		this.bindedChangeDate = this.handleChangeDate.bind(this);
		this.bindedTribuChange = this.handleTribuChange.bind(this);
		
		
	}
	
	componentDidMount()
	{
		this.listePoolets();
	}

  // appele par le composant errordialog pour ack le message => chgt d'etat
  callbackError = () => 
  {
		this.setState({etat:0});
  }
	
  /* affichage des auditeurs */
  handleClickCallback = (obj) =>
  {
	
	if (obj.etat === 4) // erreur 
		this.setState({etat:obj.etat, mess:obj.mess});
	  else
	  {
		setListeUtilisateurs(obj.data.slice());
		this.setState({etat:obj.etat, mess:obj.mess, listeAuditeurs:obj.data}, this.filtrePoolets);
	}
  }
  
  	listePoolets() 
	{
	var urlOptionslistePoolets = "ctrl=ControleurUtilisateurs&methode=voir&actif=false";
	var requestOptionslistePooletse = {method: "GET", body:""};
	
	appelAction(urlOptionslistePoolets, requestOptionslistePooletse, this.handleClickCallback);
	}

  filtrePoolets()
  {
	var tab = [];
	for (var i=0; i<this.state.listeAuditeurs.length; i++)
	{
		if (this.state.seulementActif && this.state.listeAuditeurs[i].actif==="false")
			continue;
		
		if (!this.state.btAudit && (this.state.listeAuditeurs[i].profilBT&1)>0)
			continue;
		if (!this.state.btCert && (this.state.listeAuditeurs[i].profilBT&2)>0)
			continue;
		if (!this.state.btPic && (this.state.listeAuditeurs[i].profilBT&4)>0)
			continue;
		
		if (this.state.tribuCheck!=-1 && this.state.listeAuditeurs[i].tribu != this.state.tribuCheck)
			continue;
		
		var trouve=false;
		for (var b=0;b<bureauxWavestone.length;b++)
		{
			var bureau = bureauxWavestone[b];

			if ((this.state.bureauxVisibles&bureau.id)>0
				&& (this.state.listeAuditeurs[i].bureau&bureau.id)>0 )
				{
					trouve = true;
					break;
				}
		}
		if (trouve)
			tab.push(this.state.listeAuditeurs[i]);
	}
	
	this.setState({listeAuditeursAffiches : tab}); 
  }
	
  handleFiltreChange(e, v)
  {
	  if (e.target.id.indexOf("bureaux")>=0)
	  {
		var i = e.target.id.substring(e.target.id.indexOf("_")+1);
		this.setState({bureauxVisibles: (this.state.bureauxVisibles^i)}, this.filtrePoolets);
	  }
	  else 
		this.setState({[e.target.id] : v}, this.filtrePoolets); 
  }
  
  handleTribuChange(e, v)
  {
	  var i = e.target.id.substring(e.target.id.indexOf("_")+1);
	  if (!v)
		  i=-1;
	  this.setState({tribuCheck : i}, this.filtrePoolets); 
  }
  
  /* ajout d'un auditeur */
  handleAddCallback = (obj) =>
  {
	
	if (obj.etat === 4) // erreur 
		this.setState({etat:obj.etat, mess:obj.mess});
	  else
	  {
		this.setState({etat:obj.etat, mess:obj.mess, affichageAjout : false});
		this.listePoolets();
	  }
  }
  
  
  handleAfficheAjout = () =>
  {
	  var d = new Date();
	  this.setState({affichageAjout : true, titreBox : "Ajouter",
						nom : "", prenom : "", login : "", trigramme : "",
						actif : true, staffable: true, visible: true, tribu: 0,
						cdm : 0, bureau : [], profil : [],
						date : d.getFullYear()+"/"+(d.getMonth()+1) }); 
  }
  
  handleEdit = (auditeur) =>
  {
	  
	  this.setState({ affichageAjout : true, titreBox : "Modifier",  id : auditeur.id,
					  nom : auditeur.nom, prenom : auditeur.prenom, login : auditeur.login, trigramme : auditeur.trigramme,
					  actif : auditeur.actif==="true", staffable: auditeur.staffable==="true", visible: auditeur.visible==="true", tribu: auditeur.tribu,
					  cdm : auditeur.cdm, bureau : bureauxWavestone.filter(b => (b.id&auditeur.bureau)>0), profil : auditeur.profil.split("|").map(s => s.trim()), bt:auditeur.profilBT, 
					  date : auditeur.date });
  }
  
  handleCloseAjout = () =>
  {
	  this.setState({affichageAjout : false});
  }
  handleAddAjout = () =>
  {
	var urlOptionsAuditeurs = "";
	if (this.state.titreBox === "Ajouter")
		urlOptionsAuditeurs = "ctrl=ControleurUtilisateurs&methode=creer";
	else
		urlOptionsAuditeurs = "ctrl=ControleurUtilisateurs&methode=modifier";
	
	
	var requestOptionsAuditeurs = {method: "POST", body:""};

	requestOptionsAuditeurs.body += "id="+this.state.id;
	requestOptionsAuditeurs.body += "&nom="+this.state.nom;
	requestOptionsAuditeurs.body += "&prenom="+this.state.prenom;
	requestOptionsAuditeurs.body += "&login="+this.state.login;
	requestOptionsAuditeurs.body += "&trigramme="+this.state.trigramme;
	requestOptionsAuditeurs.body += "&actif="+this.state.actif;
	requestOptionsAuditeurs.body += "&staffable="+this.state.staffable;
	requestOptionsAuditeurs.body += "&visible="+this.state.visible;
	requestOptionsAuditeurs.body += "&cdm="+this.state.cdm;
	var b = 0;
	for (var j=0;j<this.state.bureau.length;j++)
		b = b|this.state.bureau[j].id;
	requestOptionsAuditeurs.body += "&bureau="+b;
	requestOptionsAuditeurs.body += "&profil="+this.state.profil;
	requestOptionsAuditeurs.body += "&bt="+this.state.bt;
	requestOptionsAuditeurs.body += "&tribu="+this.state.tribu;
	requestOptionsAuditeurs.body += "&date="+this.state.date;

	appelAction(urlOptionsAuditeurs, requestOptionsAuditeurs, this.handleAddCallback);
	  
  }
  
  handleChangeDate = (e) =>
  {
	  var d = new Date(e);
	  this.setState({ date : d.getFullYear()+"/"+(d.getMonth()>=9?(d.getMonth()+1):"0"+(d.getMonth()+1)) });
  }
  
  handleProfilsChange (e,v)
  {	  
	this.setState({profil:v});
  }
  
  handleTextFieldChange (e,v)
  {
	
	var option = "";
	var nomOption = "";
	if (e.target.id)
			nomOption = e.target.id;
		else // si bouton "clear"
		{
			nomOption = e.target.parentElement.parentElement.parentElement.parentElement.firstChild;
			nomOption = (nomOption.id)?nomOption.id:nomOption.firstChild.id;
		}
  
	  if(nomOption.startsWith('nom'))
		  option = "nom";
	  else if(nomOption.startsWith('prenom'))
		  option = "prenom";
	  else if(nomOption.startsWith('trigramme'))
		option = "trigramme"
	  else if(nomOption.startsWith('login'))
		option = "login"
	  else if(nomOption.startsWith('bureau'))
		option = "bureau"
	  else if(nomOption.startsWith('cdm'))
		option = "cdm"
	  else if(nomOption.startsWith('tribu'))
		option = "tribu"
	  else 
		return;
	  	  
	  var val="";
	  if (v == null || isNaN(e.target.value))
	  { val = e.target.value || []; }
	  else if ( option === "cdm" )
	  { val = v.id; }
	  else
	  { val = v; }
  
		
	this.setState({[option]: val});
	
	if (option === "nom" )
		this.setState({login: (this.state.prenom +"."+ val).toLowerCase()+ "@wavestone.com", trigramme : this.state.prenom.length>0&&val.length>1?this.state.prenom.substring(0, 1).sansAccent().toLocaleUpperCase()+val.substring(0, 2).sansAccent().toLocaleUpperCase():""});
	if (option === "prenom")
		this.setState({login: (val +"."+ this.state.nom).toLowerCase() + "@wavestone.com", trigramme : this.state.nom.length>1&&val.length>0?val.substring(0, 1).sansAccent().toLocaleUpperCase()+this.state.nom.substring(0, 2).sansAccent().toLocaleUpperCase():""});
  }
  
    handleCheckboxChange (e)
  {
	  
 
	  var option = "";
	  if(e.target.id.startsWith('staffable'))
		option = "staffable"
	  else if(e.target.id.startsWith('actif'))
		option = "actif"
      else if(e.target.id.startsWith('visible'))
		option = "visible"
	  
	if (option!=="")  
	{
		this.setState({[option]: e.target.checked});
		return;
	}
	
	var bt = this.state.bt;
	
	if(e.target.id.startsWith('cert'))
		bt^=2;
	else if(e.target.id.startsWith('audit'))
		bt^=1;
	else if(e.target.id.startsWith('pic'))
		bt^=4;
	else  
		return;
		
	this.setState({bt: bt});
	
 }
  
  
	
	render()
	{
	  
	var ligne=0;
	var cdm = this.state.listeAuditeurs.find(u => u.id === this.state.cdm);
	var date = new Date(this.state.date+"/01");
	var objectMaxTribu = this.state.listeAuditeurs&&this.state.listeAuditeurs.length>0?
		this.state.listeAuditeurs.reduce(function (prev, curr) {return (prev.tribu>curr.prev)?prev:curr}):null;
	var maxTribu = objectMaxTribu?objectMaxTribu.tribu:0;

	return (
        <div className={"DreamTeam_container"}>
          
            <Fab color="primary" className={"DreamTeam_buttonAdd"} onClick={this.handleAfficheAjout} aria-label="add">
                <AddIcon />
            </Fab>
              
            <h2>La BT AIR :</h2>
            <DiagError key={"diag"} etat={this.state.etat} mess={this.state.mess} callback={this.callbackError}></DiagError>
        
             <Dialog open={this.state.affichageAjout} onClose={this.handleCloseAjout}>
            <DialogTitle>{this.state.titreBox} un(e) bitairien(ne)</DialogTitle>
            <DialogContent>
              <DialogContentText>
              </DialogContentText>
              <div className={"DreamTeam_grille_popup"}>
              <TextField required
                autoFocus margin="dense" id="prenom" className={"DreamTeam_prenom"} label="Prénom" 
                value={this.state.prenom} onChange={this.bindedTextFieldChange}
              />
              <TextField required
                margin="dense" id="nom" className={"DreamTeam_nom"} label="Nom" 
                value={this.state.nom} onChange={this.bindedTextFieldChange}
              />
              <TextField required
                margin="dense" id="trigramme" className={"DreamTeam_trigramme"} label="Trigramme" 
                value={this.state.trigramme}  onChange={this.bindedTextFieldChange}
              />
              <TextField required
                margin="dense" id="login" className={"DreamTeam_login"} label="Login" 
                value={this.state.login} onChange={this.bindedTextFieldChange}
              />
              <div className={"DreamTeam_staff"} >
                <Checkbox onChange={this.bindedCheckboxChange} id="visible" checked={this.state.visible} icon={<VisibilityOffIcon />} checkedIcon={<VisibilityIcon />} />
                Visible en PDC ?
              </div>
              <div className={"DreamTeam_visible"}>
                <Checkbox onChange={this.bindedCheckboxChange} id="staffable" checked={this.state.staffable} icon={<WorkOffIcon />} checkedIcon={<WorkIcon />} />
                Peut imputer ?
              </div>
              <div className={"DreamTeam_actif"}>
                <Checkbox onChange={this.bindedCheckboxChange} id="actif" checked={this.state.actif} icon={<FaceRetouchingOffIcon />} checkedIcon={<FaceIcon />} />
                Est actif ?
              </div>
              
              <div className={"DreamTeam_date"}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                    <DesktopDatePicker
                        id='date'
                        views={['year', 'month']}
                        label="Entrée (MM/YYYY)"
                        value = {date}
                        minDate={new Date().setFullYear(2008)}
                        maxDate={new Date().setFullYear(new Date().getFullYear()+1)}
                        inputFormat = "MM/yyyy"
                        onChange = {this.bindedChangeDate}
                        renderInput={(params) => <TextField {...params} required helperText={null} />}
                />
            </LocalizationProvider> 
              </div>
                      
              <div className={"DreamTeam_profil"}>
                    <Autocomplete
                        id="profil"
                        multiple
                        autoSelect={true}
                        options={this.profilsWavestone}
                        onChange={this.bindedProfilsChange}
                        getOptionLabel={ (option) => option }
                        value={this.state.profil}
                        renderInput={(params) => <TextField {...params} fullWidth required id="profil_area" label="Profil" /> }
                        
                    />
                
              </div>
              
              <div className={"DreamTeam_bt"}>
                    <FormLabel component="legend">Domaine d'intervention :</FormLabel>
                    Audit ? <Checkbox label="Audit" checked={(this.state.bt&1)>0?true:false} onChange={this.bindedCheckboxChange} id="audit" />
                    Cert ?  <Checkbox checked={(this.state.bt&2)>0?true:false} onChange={this.bindedCheckboxChange} id="cert" />	
                    PIC ?  <Checkbox checked={(this.state.bt&4)>0?true:false} onChange={this.bindedCheckboxChange} id="pic" />	
              </div>
              <div  className={"DreamTeam_tribu"}>
                    <TextField required 
                            margin="dense" id="tribu" label="Tribu" 
                            value={this.state.tribu}  onChange={this.bindedTextFieldChange}
              />
              </div>
                      
              <div className={"DreamTeam_cdm"}>
                    <Autocomplete
                        id="cdm"
                        autoSelect={true}
                        options={this.state.listeAuditeurs.filter(u => u.estcdm===true) }
                        onChange={this.bindedTextFieldChange}
                        getOptionLabel={ (option) => option.prenom + " " + option.nom + " ("+option.trigramme+")"}
                        value={cdm||null}
                        renderInput={(params) => <TextField {...params} fullWidth id="cdm_area" label="CDM" /> }
                        
                    />
                
              </div>
              
              <div className={"DreamTeam_bureau"}>
                    <Autocomplete
                        id="bureau"
                        autoSelect={true}
                        multiple
                        options={bureauxWavestone}
                        onChange={this.bindedTextFieldChange}
                        getOptionLabel={ (option) => option.nom }
                        value={this.state.bureau || null}
                        renderInput={(params) => <TextField {...params} fullWidth required id="bureau_area" label="Bureau" /> }
                        
                    />
                
              </div>

              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseAjout}>Annuler</Button>
              <Button onClick={this.handleAddAjout}>{this.state.titreBox}</Button>
            </DialogActions>
          </Dialog>
          
          <div>
          <FormControlLabel sx = {{ pl:20, pt:-15, pr:5}}
                        control={<Switch sx={{ m: 1}} id={"seulementActif"} onChange={this.bindedFiltreChange} />}
                        label={(this.state.seulementActif)?"Actifs":"Tous"}
                    />
            Audit <Checkbox key={"check_audit"} checked={this.state.btAudit} onChange={this.bindedFiltreChange} id="btAudit" />
            Cert <Checkbox  key={"check_cert"} checked={this.state.btCert} onChange={this.bindedFiltreChange} id="btCert" />	
            PIC <Checkbox sx = {{ pr:10}} key={"check_pic"} checked={this.state.btPic}  onChange={this.bindedFiltreChange} id="btPic" />
			{ bureauxWavestone.map (b => 
                {
                 return <React.Fragment key={"f_check_"+b.id}>{b.nom}<Checkbox key={"check_"+b.id} checked={(this.state.bureauxVisibles&b.id)>0} onChange={this.bindedFiltreChange} id={"bureaux_"+b.id} /> </React.Fragment>
                }
             )}
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
			{
			 [...Array(maxTribu+1)].map((e, i) => 
				{
					
					if (i===0)
						return <React.Fragment key={"t_check_0"}>
									Sans tribu<Checkbox sx={{ pr: 3}} key={"check_ss_tribu"} checked={this.state.tribuCheck==i} onChange={this.bindedTribuChange} id={"tribuCheck_0"} />
								</React.Fragment>
					return <React.Fragment key={"t_check_"+i}>
									Tribu {i}<Checkbox sx={{ pr: 3}} key={"check_tribu"+i} checked={this.state.tribuCheck==i} onChange={this.bindedTribuChange} id={"tribuCheck_"+i }/> 
								</React.Fragment>
					
				})			
			}
				
			
				
				
         </div>
        
            <div className={"DreamTeam_grille"}>
            {
            !this.state.affichageAjout&&this.state.listeAuditeursAffiches.map( (row) => {
                        ligne++;
                        var clLigne = classNames({
                                'DreamTeam_grille_ligne': true,
                                'DreamTeam_grille_ligne_impair': ligne%2===0
                                });
                        var rowCDM = this.state.listeAuditeurs.find(u => u && u.id === row.cdm)
                        return (
                            <React.Fragment key={"frag_"+ligne}>
                                        <div className={clLigne} style={{display:"flex", alignItems:"center"}}>{((row.profilBT)&1)>0&&<AssessmentIcon fontSize="small"/>}{((row.profilBT)&2)>0&&<LocalFireDepartmentIcon fontSize="small" />}{((row.profilBT)&4)>0&&<FactoryIcon fontSize="small" />}</div>
                                        <div className={clLigne}>{row.prenom}</div>
                                        <div className={clLigne}>{row.nom}</div>
                                        <div className={clLigne}>{row.trigramme}</div>
                                        <div className={clLigne}>{row.date}</div>
                                        <div className={clLigne}> <Checkbox checked={row.visible==="true"?true:false} icon={<VisibilityOffIcon />} checkedIcon={<VisibilityIcon />} /> </div>
                                        <div className={clLigne}> <Checkbox checked={row.staffable==="true"?true:false} icon={<WorkOffIcon />} checkedIcon={<WorkIcon />} /> </div>
                                        <div className={clLigne}> <Checkbox checked={row.actif==="true"?true:false} icon={<FaceRetouchingOffIcon />} checkedIcon={<FaceIcon />} /> </div>
                                        
                                        <div className={clLigne}>{(rowCDM)?rowCDM.prenom + " " + rowCDM.nom:" - "}</div>
                                        <div className={clLigne}>{row.tribu}</div>
                                        <div className={clLigne} style={{"fontSize":".6em"}}>{row.profil}</div>
                                        <div className={clLigne}>{bureauxWavestone.filter( b => (b&row.bureau>0) )}</div>
                                        <IconButton onClick={() => this.handleEdit(row)} size="large">
                                            <EditIcon />
                                        </IconButton>
                                    </React.Fragment>
                        );
            })
            }
            </div>
        
          </div>
    );
	};
	
}

export default DreamTeam;